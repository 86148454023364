import { schema } from 'normalizr'

import Model from './model'

class Loan extends Model({
  id: undefined,
  apr: undefined,
  amount: undefined,
  bankAccount: undefined,
  card: undefined,
  category: undefined,
  disbursementDate: undefined,
  fee: undefined,
  financeCharge: undefined,
  gracePeriod: undefined,
  lastPaymentAmount: undefined,
  lastPaymentDate: undefined,
  numberOfPayments: undefined,
  rate: undefined,
  signatureDate: undefined,
  status: undefined,
  months: undefined,
  payment: undefined,
  documents: undefined,
  refinanceDocuments: undefined,
  cardFormUrl: undefined,
  allotment: undefined,
  loans: undefined,
  loanDraft: undefined,
  createdAt: undefined,
  newApplicationAfter: undefined,
  reapplyUntil: undefined,
  remainingPaymentsForRefin: undefined,
  totalPaymentsForRefinance: undefined,
  pendingRefinanceStatus: undefined,
  eligibleForRefinance: [],
  cashout: undefined,
  pendingRefinanceId: undefined,
  isSalaryAdvanceEligible: undefined,
  salaryAdvanceEnabled: undefined,
  paymentHistory: undefined,
  customerHistory: undefined,
  estimatedPayoffAmount: undefined,
  hasSalaryAccount: undefined,
  amountPaidOnMyAccount: undefined,
  bypassSalaryAccount: undefined,
  isRefinanceLoan: undefined,
  encryptedAllotment: undefined,
  calendarType: undefined,
  daysExpired: undefined,
  onePayment: undefined,
  payoffAmount: undefined,
  paymentsDue: undefined,
  autoPayInfo: undefined,
  payRecurring: undefined,
  isBankrupt: undefined,
  achPayment: undefined,
  isNewApplicationAvailable: undefined,
  isReapplyAvailable: undefined,
  isRenegotiation: undefined,
  rennewDocument: undefined,
  dateFirstPayment: undefined,
  protectionShieldEnabledAt: undefined,
  daysPastDue: undefined,
  autoPayAuth: undefined,
  enabledChangeCardForDisbursement: undefined,
  collectionCampaign: undefined,
  needUpdateContactInfo: undefined,
  shouldUpdateFallbackAccount: undefined,
  isForbearanceAvailable: undefined,
  code: undefined,
  interval: undefined,
  phones: undefined,
  needPhoneVerification: undefined,
  approvalDate: undefined,
  balancePastDue: undefined,
}) {}

export default Loan

const loanNewSchema = new schema.Entity(
  'result',
  {},
  {
    processStrategy: entity => new Loan(entity),
  }
)

export const loanSchema = [loanNewSchema]
