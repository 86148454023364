import * as getCardStackService from '_services/card-stack'
import { defineAction } from '_utils/redux'

export const GET_DASHBOARD = defineAction('GET_DASHBOARD')

export const getDashboard = () => (dispatch, getState) =>
  dispatch({
    type: GET_DASHBOARD.ACTION,
    payload: getCardStackService.getDashboard(getState().authentication.get('accessToken')),
  })
