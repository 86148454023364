import { get, post } from './requests'

export const getPaymentInfo = (key, loanId) =>
  get(['payments', loanId, 'options'], { key, transformPayload: true })

export const createAutopay = (key, loanId, payload) =>
  post(['autopay', loanId, 'recurring'], { key, transformPayload: true }, payload)

export const getCurrentAutopay = key => get(['autopay', 'current'], { key, transformPayload: true })

export const getRenegAutopayInfo = (key, loanId) =>
  get(['autopay', loanId, 'reneg-autopay-info'], { key, transformPayload: true })
