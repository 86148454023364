import { defineAction } from '_utils/redux'
import * as forbearanceService from '_services/forbearance'

export const GET_PAYMENT_RANGE = defineAction('GET_PAYMENT_RANGE')
export const ENCRYPT_CUSTOM_ALLOTMENT = defineAction('ENCRYPT_CUSTOM_ALLOTMENT')
export const START_CONNECTION_PAYROLL = defineAction('START_CONNECTION_PAYROLL')
export const CONNECTION_PAYROLL_SUCCESS = defineAction('CONNECTION_PAYROLL_SUCCESS')
export const UPLOAD_ALLOTMENT_CONFIRMATION = defineAction('UPLOAD_ALLOTMENT_CONFIRMATION')
export const AUTO_PAY_SELECTED = defineAction('AUTO_PAY_SELECTED')
export const AUTO_PAY_SELECTED_CONFIRM = defineAction('AUTO_PAY_SELECTED_CONFIRM')
export const UPDATE_FORBEARANCE = defineAction('UPDATE_FORBEARANCE')

export const getPaymentRange = params => (dispatch, getState) =>
  dispatch({
    type: GET_PAYMENT_RANGE.ACTION,
    payload: forbearanceService.getPaymentRange(
      getState().authentication.get('accessToken'),
      params
    ),
  })

export const encryptCustomAllotment = payload => (dispatch, getState) =>
  dispatch({
    type: ENCRYPT_CUSTOM_ALLOTMENT.ACTION,
    payload: forbearanceService.encryptCustomAllotment(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const startConnectionPayroll = payload => (dispatch, getState) =>
  dispatch({
    type: START_CONNECTION_PAYROLL.ACTION,
    payload: forbearanceService.startConnectionPayroll(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const connectionPayrollSuccess = payload => (dispatch, getState) =>
  dispatch({
    type: CONNECTION_PAYROLL_SUCCESS.ACTION,
    payload: forbearanceService.connectionPayrollSuccess(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const uploadAllotmentConfirmation = payload => (dispatch, getState) =>
  dispatch({
    type: UPLOAD_ALLOTMENT_CONFIRMATION.ACTION,
    payload: forbearanceService.uploadAllotmentConfirmation(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const autoPaySelected = payload => (dispatch, getState) =>
  dispatch({
    type: AUTO_PAY_SELECTED.ACTION,
    payload: forbearanceService.autoPaySelected(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const autoPaySelectedConfirm = payload => (dispatch, getState) =>
  dispatch({
    type: AUTO_PAY_SELECTED_CONFIRM.ACTION,
    payload: forbearanceService.autoPaySelectedConfirm(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
export const updateForbearance = payload => dispatch =>
  dispatch({
    type: UPDATE_FORBEARANCE,
    payload,
  })
