import { schema } from 'normalizr'
import Humps from 'humps'

import { DOCUMENT_STATUS, DENY_REASONS, DOCUMENT_CATEGORIES_LABEL } from '_utils/constants'

import Model from './model'

class Document extends Model({
  id: undefined,
  category: undefined,
  comment: undefined,
  denyReason: undefined,
  file: undefined,
  fileBack: undefined,
  status: undefined,
  loading: undefined,
  label: undefined,
  isArgyleSearchable: undefined,
  hasArgyleConnection: undefined,
  hasPayDistribution: undefined,
}) {}

export default Document
const mergeCategoryStrategy = (entityA, entityB) =>
  new Document({
    id: Math.max(entityA.id, entityB.id),
    category: entityA.category,
    comment: entityA.comment || entityB.comment,
    denyReason: entityA.denyReason === DENY_REASONS.OTHER ? entityB.denyReason : entityA.denyReason,
    file: entityA.status === DOCUMENT_STATUS.DENIED ? entityB.file : entityA.file,
    fileBack: entityA.status === DOCUMENT_STATUS.DENIED ? entityB.fileBack : entityA.fileBack,
    status: entityA.status === DOCUMENT_STATUS.EMPTY ? entityB.status : entityA.status,
    label: entityA.label,
    isArgyleSearchable: entityA.isArgyleSearchable,
    hasArgyleConnection: entityA.hasArgyleConnection,
    hasPayDistribution: entityA.hasPayDistribution,
  })

const documentNewSchema = new schema.Entity(
  'result',
  {},
  {
    mergeStrategy: mergeCategoryStrategy,
    processStrategy: entity =>
      new Document({
        ...entity,
        label: DOCUMENT_CATEGORIES_LABEL[Humps.camelize(entity.category)],
      }),
    idAttribute: entity => Humps.camelize(entity.category),
  }
)

export const documentSchema = [documentNewSchema]
