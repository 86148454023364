import { defineAction } from '_utils/redux'
import * as achPullService from '_services/ach-pull'

export const SET_SELECTED_CARD = defineAction('SET_SELECTED_CARD')
export const SET_PULL_AMOUNT = defineAction('SET_PULL_AMOUNT')
export const ACH_PULL_TRANSACTION = defineAction('ACH_PULL_TRANSACTION')
export const CLEAR_ACH_PULL_TRANSFER = defineAction('CLEAR_ACH_PULL_TRANSFER')

export const setSelectedCard = payload => dispatch =>
  dispatch({
    type: SET_SELECTED_CARD.ACTION,
    payload,
  })

export const setPullAmount = payload => dispatch =>
  dispatch({
    type: SET_PULL_AMOUNT.ACTION,
    payload,
  })

export const achPullTransaction = payload => (dispatch, getState) =>
  dispatch({
    type: ACH_PULL_TRANSACTION.ACTION,
    payload: achPullService.achPullTransaction(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const clearAchPullTransaction = payload => dispatch =>
  dispatch({
    type: CLEAR_ACH_PULL_TRANSFER.ACTION,
    payload,
  })
