import { defineAction as reduxDefine } from 'redux-define'
import { Cookies } from 'react-cookie'

import { ROOT_DOMAIN } from '_config/environment'

const REQUEST = ['PENDING', 'FULFILLED', 'REJECTED', 'COUNT']

const cookies = new Cookies()

export const defineAction = type => reduxDefine(type, REQUEST)

export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) => {
    const reduceFn = handlers[action.type]
    if (action.type === 'LOGOUT') {
      cookies.remove('authentication', { domain: ROOT_DOMAIN })
      cookies.remove('blinc-session-id', { domain: ROOT_DOMAIN })
      cookies.remove('authentication')
      return initialState
    }
    return reduceFn ? reduceFn(state, action) : state
  }

export const getActionName = name =>
  name.toString().replace(/_PENDING$|_REJECTED$|_FULFILLED$|_COUNT$/, '')
