import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from '@react-spring/web'

// import { Container } from './styles';

function AnimatedOpacity({ children }) {
  const animation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },

    delay: 300,
  })

  return (
    <animated.div key={children} style={animation}>
      {children}
    </animated.div>
  )
}

AnimatedOpacity.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AnimatedOpacity
