import CashbackStore from '_models/cashback'
import { createReducer } from '_utils/redux'

import {
  GET_CASHBACK_STORES,
  SET_LEAD_TOKEN,
  GET_CASHBACK_USER,
  ENTER_WAITLIST,
  REDEEM_CASHBACK,
  GET_LEAD_CARD_FORM_URL,
  GET_LEAD_USER_CARDS,
} from './actions'

const INITIAL_STATE = new CashbackStore()

export default createReducer(INITIAL_STATE, {
  [GET_CASHBACK_STORES.FULFILLED]: (state, { payload }) => {
    return state.set('cashbackStores', payload)
  },
  [SET_LEAD_TOKEN.ACTION]: (state, { payload }) => {
    return state.set('leadToken', payload)
  },
  [GET_CASHBACK_USER.FULFILLED]: (state, { payload }) => {
    return state.set('cashbackUser', payload)
  },
  [ENTER_WAITLIST.FULFILLED]: (state, { payload }) => {
    return state.set('enterWaitlist', payload)
  },
  [REDEEM_CASHBACK.FULFILLED]: (state, { payload }) => {
    return state.set('redeemCashback', payload)
  },
  [GET_LEAD_CARD_FORM_URL.FULFILLED]: (state, { payload }) => {
    return state.set('leadCardFormUrl', payload.url)
  },
  [GET_LEAD_USER_CARDS.FULFILLED]: (state, { payload }) => {
    return state.set('leadUserCards', payload)
  },
})
