import React, { useContext, useState, useEffect } from 'react'
import { useLocation, Redirect } from '@reach/router'
import PropTypes from 'prop-types'

import { OriginalPathContext } from '_config/app-context'

// import { Container } from './styles';

export const useProtectedRoutes = (children, conditional, redirectTo) => {
  const { setOriginalPathState } = useContext(OriginalPathContext)
  const { pathname } = useLocation()
  // const isProtected = routes.some(url => pathname.split('/').includes(url), false)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    if (!conditional) {
      setShouldRedirect(true)
      setOriginalPathState(pathname)
      return
    }
    setShouldRedirect(false)
  }, [children, conditional, pathname, setOriginalPathState])

  if (shouldRedirect) {
    return <Redirect to={redirectTo || '/'} noThrow />
  }
  return children
}

useProtectedRoutes.propTypes = {
  children: PropTypes.node.isRequired,
  conditional: PropTypes.bool,
  redirectTo: PropTypes.string,
}

useProtectedRoutes.defaultProps = {
  conditional: true,
  redirectTo: '/',
}
