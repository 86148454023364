import { Map } from 'immutable'
import { createSelector } from 'reselect'
import { format } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import { LOAN_STATUS } from '_utils/constants'
import { valueToCurrency, valueToPercentage } from '_utils/helpers'

import {
  CREATE_LOAN,
  GET_LOANS,
  GET_LOAN_DOCUMENTS,
  ACCEPT_LOAN,
  UPLOAD_DOCUMENT,
  GET_CARD_FORM,
  REAPPLY_PREVIEW,
  REAPPLY_LOAN,
  ENCRYPT_ALLOTMENT,
  GET_ALLOTMENT,
  GET_LOAN_ONE_PAYMENT,
  GET_AUTO_PAY_INFO,
  ESIGN_NEW_AUTOPAY_TERMS,
  SET_PROTECT_SHIELD,
  NEED_UPDATE_CONTACT_INFO,
} from './actions'

const DATE_FORMAT = 'MM/dd/yyyy'

export const isCreateLoanLoadingSelector = state => state.loading.get(CREATE_LOAN.ACTION)
export const getCreateLoanErrorSelector = state => state.error.get(CREATE_LOAN.ACTION, Map())

export const getLoanSelector = state => state.loan
export const getFormattedLoanSelector = createSelector(getLoanSelector, loan => {
  const formattedLoan = Map({
    amount: valueToCurrency(loan.get('amount')),
    payment: valueToCurrency(loan.get('payment')),
    fee: valueToCurrency(loan.get('fee')),
    apr: valueToPercentage(loan.get('apr')),
    months: loan.get('months'),
    numberOfPayments: loan.get('numberOfPayments'),
    financeCharge: valueToCurrency(loan.get('financeCharge')),
    total: valueToCurrency(loan.get('financeCharge') + loan.get('amount')),
    gracePeriod: loan.get('gracePeriod'),
    id: loan.get('id'),
    status: loan.get('status'),
    category: loan.get('category'),
    daysLeftToNewApplication:
      differenceInCalendarDays(parseISO(loan.get('newApplicationAfter')), new Date()) || 0,
    daysUntilReapply: differenceInCalendarDays(parseISO(loan.get('reapplyUntil')), new Date()) || 0,
    salaryAdvanceEnabled: loan.get('isSalaryAdvanceEligible'),
    cashout: valueToCurrency(loan.get('cashout')),
    amountPaidOnMyAccount: valueToCurrency(loan.get('amountPaidOnMyAccount')),
    calendarType: loan.get('calendarType'),
    daysExpired: loan.get('daysExpired'),
    approvalDate: loan.get('approvalDate'),
    isRefinanceLoan: loan.get('isRefinanceLoan'),
    daysPastDue: loan.get('daysPastDue'),
    balancePastDue: loan.get('balancePastDue'),
  })
  return formattedLoan
})
export const isLoanApprovedSelector = state => {
  const loanStatus = state.loan.get('status')
  const isLoanApproved = [
    LOAN_STATUS.APPROVED,
    LOAN_STATUS.RELEASED,
    LOAN_STATUS.SETTLED,
    LOAN_STATUS.REFINANCE_DISBURSEMENT_PENDING,
    LOAN_STATUS.REFINANCE_DOCUMENT_PENDING,
  ].includes(loanStatus)
  return isLoanApproved
}

export const isLoanDeniedSelector = state => {
  const loanStatus = state.loan.get('status')
  const isLoanDenied = [LOAN_STATUS.DENIED, LOAN_STATUS.INAPT].includes(loanStatus)
  return isLoanDenied
}

export const isLoanSoldSelector = state => state.loan.get('status') === LOAN_STATUS.SOLD

export const getLoanDocumentsSelector = state => state.loan.get('documents')
export const getRefinanceDocumentsSelector = state => state.loan.get('refinanceDocuments')
export const getNumberOfDocumentsSelector = createSelector(
  getLoanDocumentsSelector,
  documents =>
    documents?.filter(document => document.status === 'approved' || document.status === 'pending')
      .size
)
export const getHasSalaryAccountSelector = state => state.loan.get('hasSalaryAccount')
export const getLoanIdSelector = state => state.loan.get('id')

export const isGetLoansLoadingSelector = state => state.loading.get(GET_LOANS.ACTION)
export const getLoansErrorSelector = state => state.error.get(GET_LOANS.ACTION, Map())

export const isAcceptLoanLoadingSelector = state => state.loading.get(ACCEPT_LOAN.ACTION)
export const getAcceptLoanErrorSelector = state => state.error.get(ACCEPT_LOAN.ACTION, Map())

export const isUploadDocumentLoadingSelector = state => state.loading.get(UPLOAD_DOCUMENT.ACTION)
export const uploadDocumentErrorSelector = state => state.error.get(UPLOAD_DOCUMENT.ACTION, Map())

export const isGetLoanDocumentsLoadingSelector = state =>
  state.loading.get(GET_LOAN_DOCUMENTS.ACTION)

export const isGetCardFormUrlLoadingSelector = state => state.loading.get(GET_CARD_FORM.ACTION)
export const getCardFormUrlErrorSelector = state => state.error.get(GET_CARD_FORM.ACTION, Map())
export const getCardFormUrl = state => state.loan.get('cardFormUrl')

export const getAllotmentSelector = state => state.loan.get('allotment')
export const isAllotmentLoadingSelector = state => state.loading.get(GET_ALLOTMENT.ACTION)
export const allotmentErrorSelector = state => state.error.get(GET_ALLOTMENT.ACTION, Map())

export const getFormattedAllotmentSelector = createSelector(getAllotmentSelector, allotment => {
  const formattedAllotment = allotment?.set(
    'allotmentAmount',
    valueToCurrency(allotment?.get('allotmentAmount'))
  )
  return formattedAllotment
})

export const getNumberOfLoansSelector = state => state.loan.get('loans')?.get('results')?.size || 0

export const isReapplyLoanLoadingSelector = state => !!state.loading.get(REAPPLY_LOAN.ACTION)
export const getReapplyLoanErrorSelector = state => state.error.get(REAPPLY_LOAN.ACTION, Map())

export const isReapplyPreviewLoadingSelector = state => !!state.loading.get(REAPPLY_PREVIEW.ACTION)
export const getReapplyPreviewErrorSelector = state =>
  state.error.get(REAPPLY_PREVIEW.ACTION, Map())

export const getPaymentHistorySelector = state => state.loan.get('paymentHistory')
export const getPaymentHistoryRows = createSelector(getPaymentHistorySelector, paymentHistory => {
  const newList = paymentHistory?.map(history =>
    Map({
      id: history?.get('id'),
      amount: valueToCurrency(history?.get('amount')),
      depositDate: format(parseISO(history?.get('depositDate')), DATE_FORMAT),
      type: history?.get('type'),
      achType: history?.get('achType'),
    })
  )
  return newList
})

export const getAchPaymentsSelector = state => state.loan.get('achPayment')
export const getAchPaymentsRows = createSelector(getAchPaymentsSelector, achPayments => {
  const newList = achPayments?.map(payment =>
    Map({
      startDate: format(parseISO(payment?.get('startDate')), DATE_FORMAT),
      amount: valueToCurrency(payment?.get('amount')),
      status: payment.get('status'),
      type: payment?.get('type'),
    })
  )
  return newList
})

export const getCustomerHistory = state => state.loan.get('customerHistory')

export const getEncryptedAllotmentSelector = state => state.loan.get('encryptedAllotment')
export const isEncryptAllotmentLoadingSelector = state =>
  state.loading.get(ENCRYPT_ALLOTMENT.ACTION)
export const encryptAllotmentErrorSelector = state =>
  state.error.get(ENCRYPT_ALLOTMENT.ACTION, Map())

export const getLoanOnePaymentSelector = state => state.loan.get('onePayment')

export const getLoanPaymentAchKeysSelector = state =>
  Map({
    oneTime: state.loan.get('onePayment')?.get('ach')?.get('onetime'),
    oneTimeDate: state.loan.get('onePayment')?.get('ach').get('oneTimeDate'),
    oneTimeAmount: state.loan.get('onePayment')?.get('ach').get('oneTimeAmount'),
    recurring: state.loan.get('onePayment')?.get('ach').get('recurring'),
    recurringDate: state.loan.get('onePayment')?.get('ach').get('recurringDate'),
    recurringAmount: state.loan.get('onePayment')?.get('ach').get('recurringAmount'),
    billing: state.loan.get('onePayment')?.get('ach').get('billing'),
    billingPeriod: state.loan.get('onePayment')?.get('ach').get('billingPeriod'),
    billingExpireDate: state.loan.get('onePayment')?.get('ach').get('billingExpireDate'),
  }).toJS()

export const isGetLoanOnePaymentLoadingSelector = state =>
  state.loading.get(GET_LOAN_ONE_PAYMENT.ACTION)

export const getLoanOnePaymentErrorSelector = state =>
  state.error.get(GET_LOAN_ONE_PAYMENT.ACTION, Map())

export const getLoanPaymentListSelector = state => state.loan.get('paymentsDue')

export const getLoanCurrentPaymentDueSelector = createSelector(getLoanPaymentListSelector, list => {
  const filtered = list
    ?.filter(
      payment =>
        !payment.paid && differenceInCalendarDays(parseISO(payment.get('date')), new Date()) >= 0
    )
    .sort((t1, t2) => parseISO(t2.date).getTime() - parseISO(t1.date).getTime())

  return filtered ? [...filtered][0] : undefined
})

export const getLoanPaymentsDueSelector = createSelector(getLoanPaymentListSelector, list => {
  const filtered = list
    ?.sort((t1, t2) => parseISO(t2.date).getTime() - parseISO(t1.date).getTime())
    .filter(
      payment =>
        !payment.paid && differenceInCalendarDays(parseISO(payment.get('date')), new Date()) < 0
    )

  return filtered
})

export const isAutoPayActiveSelector = state => state.loan.get('payRecurring')

export const getAutoPayInfoSelector = state => state.loan.get('autoPayInfo')

export const autoPayErrorSelector = state => state.error.get(GET_AUTO_PAY_INFO.ACTION, Map())

export const isSalaryAdvanceEligibleSelector = state => state.loan.get('isSalaryAdvanceEligible')

export const isNewApplicationAvailableSelector = state =>
  state.loan.get('isNewApplicationAvailable')

export const isReapplyAvailableSelector = state => state.loan.get('isReapplyAvailable')

export const isEsignNewAutopayTermsLoadingSelector = state =>
  state.loading.get(ESIGN_NEW_AUTOPAY_TERMS.ACTION)
export const esignNewAutopayTermsErrorSelector = state =>
  state.error.get(ESIGN_NEW_AUTOPAY_TERMS.ACTION, Map())

export const isSetProtectShieldLoadingSelector = state =>
  !!state.loading.get(SET_PROTECT_SHIELD.ACTION)

export const setProtectShieldErrorSelector = state =>
  state.error.get(SET_PROTECT_SHIELD.ACTION, Map())

export const loanCollectionCampaignSelector = state =>
  state.loan?.get('collectionCampaign') && Object.keys(state.loan.get('collectionCampaign')) === 0
    ? null
    : state.loan.get('collectionCampaign')

export const getLoanDraftSelector = state => state.loan.get('loanDraft')
export const getFormattedLoanDraftSelector = createSelector(getLoanDraftSelector, loan => {
  if (loan) {
    return Map({
      amount: valueToCurrency(loan.get('amount')),
      payment: valueToCurrency(loan.get('payment')),
      fee: valueToCurrency(loan.get('fee')),
      apr: valueToPercentage(loan.get('apr')),
      months: loan.get('months'),
      numberOfPayments: loan.get('numberOfPayments'),
      financeCharge: valueToCurrency(loan.get('financeCharge')),
      total: valueToCurrency(loan.get('financeCharge') + loan.get('amount')),
      gracePeriod: loan.get('gracePeriod'),
      id: loan.get('id'),
      status: loan.get('status'),
      category: loan.get('category'),
      daysLeftToNewApplication:
        differenceInCalendarDays(parseISO(loan.get('newApplicationAfter')), new Date()) || 0,
      daysUntilReapply:
        differenceInCalendarDays(parseISO(loan.get('reapplyUntil')), new Date()) || 0,
      salaryAdvanceEnabled: loan.get('isSalaryAdvanceEligible'),
      cashout: valueToCurrency(loan.get('cashout')),
      amountPaidOnMyAccount: valueToCurrency(loan.get('amountPaidOnMyAccount')),
      calendarType: loan.get('calendarType'),
      daysExpired: loan.get('daysExpired'),
    })
  }
  return null
})

export const isNeedContactInfoUpdateLoadingSelector = state =>
  state.loading.get(NEED_UPDATE_CONTACT_INFO.ACTION)
export const needContactInfoUpdateLoadingSelector = state => state.loan.get('needUpdateContactInfo')
