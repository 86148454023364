import { decamelizeKeys } from 'humps'

import { createFormData } from '_utils/helpers'

import { post, get, patch, put } from './requests'

export const getCheckingAccount = key =>
  get(['checking-account', 'account'], {
    key,
    transformPayload: true,
  })

export const createCheckingAccount = key =>
  post(['checking-account', 'create-account'], { key, transformPayload: true })

export const createCardPin = (key, payload) =>
  post(['checking-account', 'create-card-pin'], { key, transformPayload: true }, payload)

export const agreeTerms = (key, payload) =>
  post(['checking-account', 'agree-terms'], { key, transformPayload: true }, payload)

export const agreeBeta = (key, payload) =>
  post(['checking-account', 'agree-beta'], { key, transformPayload: true }, payload)

export const createShipment = key =>
  post(['checking-account', 'create-shipment'], { key, transformPayload: true })

export const authPin = (key, payload) =>
  post(['checking-account', 'auth-pin'], { key, transformPayload: true }, payload)

export const unlockCard = (key, payload) =>
  post(['checking-account', 'activate-card'], { key, transformPayload: true }, payload)

export const getTransactions = (key, payload) =>
  get(['checking-account', 'transactions'], { key, params: decamelizeKeys(payload) })

export const getTransactionDetails = (key, payload) =>
  get(['checking-account', 'transaction-details'], {
    key,
    transformPayload: true,
    params: decamelizeKeys(payload),
  })

export const cancelTransaction = (key, payload) =>
  post(
    ['checking-account', 'transaction', 'cancel'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getAccountLimits = key =>
  get(['checking-account', 'account', 'limits'], { key, transformPayload: true })

export const viewPin = (key, payload) =>
  post(['checking-account', 'view-pin'], { key, transformPayload: true }, payload)

export const getKYCStatus = key =>
  get(['checking-account', 'kyc', 'status'], { key, transformPayload: true })

export const startKYC = key =>
  post(['checking-account', 'kyc', 'start'], { key, transformPayload: true })

export const updateKYCDriversLicense = (key, payload) =>
  post(['checking-account', 'kyc', 'drivers-license'], { key }, createFormData(payload, false))

export const updateKYCAddress = (key, payload) =>
  patch(['checking-account', 'kyc', 'address'], { key, transformPayload: true }, payload)

export const getKYCAddress = key =>
  get(['checking-account', 'kyc', 'address'], { key, transformPayload: true })

export const getVirtualCard = (key, payload) =>
  post(['checking-account', 'virtual-card'], { key, transformPayload: true }, payload)

export const updateCardStatus = (key, payload) =>
  patch(['checking-account', 'card-status'], { key, transformPayload: true }, payload)

export const updateCardPin = (key, payload) =>
  put(['checking-account', 'update-pin'], { key, transformPayload: true }, payload)

export const getStatements = (key, payload) =>
  get(['checking-account', 'statements'], {
    key,
    ...(payload && { params: decamelizeKeys(payload) }),
  })

export const setPrimaryAddressAsMailingAddress = (key, payload) =>
  post(
    ['checking-account', 'mailing_address_same_as_primary'],
    { key, transformPayload: true },
    payload
  )

export const getShipmentStatus = key =>
  get(['checking-account', 'view-shipment-status'], { key, transformPayload: true })

export const validateMailingAddress = (key, payload) =>
  post(['checking-account', 'validate-mailing-address'], { key, transformPayload: true }, payload)
