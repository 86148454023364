import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import AchTransfer, { achTransferSchema } from '_models/ach-transfer'
import { createReducer } from '_utils/redux'

import {
  CLEAR_ACH_TRANSFER,
  VALIDATE_ROUTING_NUMBER,
  GET_BOOKMARKED_ACCOUNTS,
  SET_ACH_TRANSFER_AMOUNT,
  SET_SELECTED_ACH_ACCOUNT,
  UPDATE_ACH_TRANSFER_INFO,
  TRANSFER_ACH_TO_MYSELF,
  SET_ACH_SCHEDULED_DATE,
  CLEAR_ACH_SHCEDULED_DATE,
} from './actions'

const INITIAL_STATE = Map({
  achTransfer: new AchTransfer(),
  isNewAccount: true,
  transferredAccountId: null,
  isTransferredAccountBookmarked: false,
  validatedRoutingNumber: false,
  bookmarkedAccounts: Map({ results: Map(), order: List() }),
})

export default createReducer(INITIAL_STATE, {
  [UPDATE_ACH_TRANSFER_INFO]: (state, { payload }) => {
    return state
      .mergeIn(['achTransfer'], payload)
      .setIn(['achTransfer', 'id'], undefined)
      .set('isNewAccount', true)
  },
  [SET_SELECTED_ACH_ACCOUNT]: (state, { payload }) => {
    return state
      .set('achTransfer', payload)
      .setIn(['achTransfer', 'amount'], state.get('achTransfer').amount)
      .set('isNewAccount', false)
  },
  [SET_ACH_TRANSFER_AMOUNT]: (state, { payload }) => {
    return state.setIn(['achTransfer', 'amount'], payload)
  },
  [VALIDATE_ROUTING_NUMBER.FULFILLED]: (state, { payload }) => {
    return state
      .set('validatedRoutingNumber', true)
      .setIn(
        ['achTransfer', 'bankName'],
        payload.bankName?.toUpperCase() || payload.name?.toUpperCase()
      )
  },
  [GET_BOOKMARKED_ACCOUNTS.FULFILLED]: (state, { payload }) => {
    const normalizedState = normalize(payload, achTransferSchema)
    return state.set(
      'bookmarkedAccounts',
      Map({
        results: Map(normalizedState.entities.results),
        order: List(normalizedState.result),
      })
    )
  },
  [SET_ACH_SCHEDULED_DATE]: (state, { payload }) => {
    return state.setIn(['achTransfer', 'date'], payload)
  },
  [CLEAR_ACH_SHCEDULED_DATE]: state => {
    return state.setIn(['achTransfer', 'date'], null)
  },

  [TRANSFER_ACH_TO_MYSELF.FULFILLED]: (state, { payload }) => {
    return state
      .set('transferredAccountId', payload.node.id)
      .set('isTransferredAccountBookmarked', payload.node.bookmarked)
  },
  [CLEAR_ACH_TRANSFER]: () => {
    return INITIAL_STATE
  },
})
