import { defineAction } from '_utils/redux'
import * as autopayService from '_services/autopay'

export const GET_PAYMENT_INFO = defineAction('GET_PAYMENT_INFO')
export const CREATE_AUTOPAY = defineAction('CREATE_AUTOPAY')
export const GET_CURRENT_AUTOPAY = defineAction('GET_CURRENT_AUTOPAY')
export const GET_RENEG_AUTOPAY_INFO = defineAction('GET_RENEG_AUTOPAY_INFO')

export const getPaymentInfo = loanId => (dispatch, getState) =>
  dispatch({
    type: GET_PAYMENT_INFO.ACTION,
    payload: autopayService.getPaymentInfo(getState().authentication.get('accessToken'), loanId),
  })

export const createAutopay = (loanId, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_AUTOPAY.ACTION,
    payload: autopayService.createAutopay(
      getState().authentication.get('accessToken'),
      loanId,
      payload
    ),
  })

export const getCurrentAutopay = () => (dispatch, getState) =>
  dispatch({
    type: GET_CURRENT_AUTOPAY.ACTION,
    payload: autopayService.getCurrentAutopay(getState().authentication.get('accessToken')),
  })
export const getRenegAutopayInfo = loanId => (dispatch, getState) =>
  dispatch({
    type: GET_RENEG_AUTOPAY_INFO.ACTION,
    payload: autopayService.getRenegAutopayInfo(
      getState().authentication.get('accessToken'),
      loanId
    ),
  })
