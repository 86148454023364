import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useProtectedRoutes } from '_hooks/use-protected-routes'
import { userHasCheckingAccountSelector } from '_modules/checking-account/selectors'

export const PROTECTED_TRANSACTION_ROUTES = [
  'move-money',
  'interchange',
  'transfer-funds-to-yourself',
  'direct-deposit',
  'pay-bills',
  'mail-check',
]

const MoveMoneyProtectedRoutes = ({ children }) => {
  const userHasCheckingAccount = useSelector(userHasCheckingAccountSelector)
  return useProtectedRoutes(children, userHasCheckingAccount)
}

MoveMoneyProtectedRoutes.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MoveMoneyProtectedRoutes
