import { defineAction } from '_utils/redux'
import * as cardService from '_services/card-payment'

export const SET_CARD_PAYMENT_AMOUNT = defineAction('SET_CARD_PAYMENT_AMOUNT')
export const SET_CARD_PAYMENT_KEYS = defineAction('SET_CARD_PAYMENT_KEYS')
export const SET_CARD_PAYMENT_DATA = defineAction('SET_CARD_PAYMENT_DATA')
export const IS_SUCCESS_CONCLUDED_PAYMENT_DEBIT_CARD = defineAction(
  'IS_SUCCESS_CONCLUDED_PAYMENT_DEBIT_CARD'
)
export const CLEAR_CARD_PAYMENT_DATA = defineAction('CLEAR_CARD_PAYMENT_DATA')

export const setCardPaymentAmount = payload => (dispatch, getState) =>
  dispatch({
    type: SET_CARD_PAYMENT_AMOUNT.ACTION,
    payload,
  })

export const setCardPaymentKeys = payload => (dispatch, getState) =>
  dispatch({
    type: SET_CARD_PAYMENT_KEYS.ACTION,
    payload,
  })

export const setCardPaymentData = payload => (dispatch, getState) =>
  dispatch({
    type: SET_CARD_PAYMENT_DATA.ACTION,
    payload: cardService.sendCardPaymentData(getState().authentication.get('accessToken'), payload),
  })

export const isSuccessConcludedPaymentDebitCard = payload => (dispatch, getState) =>
  dispatch({
    type: IS_SUCCESS_CONCLUDED_PAYMENT_DEBIT_CARD.ACTION,
    payload,
  })

export const clearCardData = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_CARD_PAYMENT_DATA.ACTION,
  })
