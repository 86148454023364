import { createFormData } from '_utils/helpers'

import { post, get, patch, put } from './requests'

export const login = payload => post(['login'], { transformPayload: true }, payload)

export const socialLogin = payload => get(['social', 'check'], { transformPayload: true }, payload)

export const onboarding = (key, payload) =>
  post(['onboarding'], { key, transformPayload: true }, payload)

export const getUser = key => get(['user'], { key, transformPayload: true })

export const updateUser = (key, payload) =>
  patch(['user'], { key, transformPayload: true }, payload)

export const verifyPhone = key => post(['verify-phone'], { key })

export const verifyCode = (key, payload) => post(['verify-phone', 'confirm'], { key }, payload)

export const subscribeNewsletterInterest = payload =>
  post(['newsletter-interest'], { transformPayload: true }, payload)

export const subscribeNewsletterTriton = payload =>
  post(['newsletter'], { transformPayload: true }, payload)

export const uploadDriverLicense = (key, payload) =>
  post(['client', 'driver-licenses'], { key }, createFormData(payload, false))

export const additionalInformation = (key, payload) =>
  patch(['client', 'additional-information'], { key, transformPayload: true }, payload)

export const confirmInformation = (key, payload) =>
  patch(['client', 'confirm-information'], { key, transformPayload: true }, payload)

export const saveArgyleUser = (key, payload) =>
  post(
    ['argyle-link'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const updateArgyleToken = key =>
  post(['argyle-link', 'update-token'], {
    key,
    transformPayload: true,
  })

export const saveArgyleEvents = (key, payload) =>
  post(['link-analytics'], { key, transformPayload: true }, payload)

export const createPartnerAccount = (key, id, payload) =>
  post(
    ['argyle-link', id, 'accounts'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const acceptBiweeklyAllotments = (key, userId) =>
  post(['client', userId, 'accept-biweekly-allotments'], {
    key,
    transformPayload: true,
  })

export const getMailingAddress = key =>
  get(['client', 'confirm-mailing-address'], { key, transformPayload: true })

export const confirmMailingAddress = (key, payload) =>
  post(['client', 'confirm-mailing-address'], { key, transformPayload: true }, payload)

export const editMailingAddress = (key, payload) =>
  put(['client', 'confirm-mailing-address'], { key, transformPayload: true }, payload)

export const confirmPassword = (key, payload) =>
  post(['users', 'confirm-password'], { key, transformPayload: true }, payload)

export const getContactInfo = key => get(['contact-info'], { key, transformPayload: true })

export const updateContactInfo = (key, payload) =>
  post(['contact-info'], { key, transformPayload: true }, payload)

export const getReferences = key => get(['reference-contact'], { key, transformPayload: true })
export const getReferencesRelationList = key =>
  get(['reference-contact', 'relation-list'], { key, transformPayload: true })

export const updateReferences = (key, payload) =>
  post(['reference-contact'], { key, transformPayload: true }, payload)
