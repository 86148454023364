import { defineAction } from '_utils/redux'
import * as checkingAccountService from '_services/checking-account'

export const GET_CHECKING_ACCOUNT = defineAction('GET_CHECKING_ACCOUNT')
export const SAVE_BALANCE_HIDDEN = defineAction('SAVE_BALANCE_HIDDEN')
export const SAVE_BANNER_HIDDEN = defineAction('SAVE_BANNER_HIDDEN')
export const SAVE_ACCOUNT_DETAILS_HIDDEN = defineAction('SAVE_ACCOUNT_DETAILS_HIDDEN')
export const CREATE_CHECKING_ACCOUNT = defineAction('CREATE_CHECKING_ACCOUNT')
export const CREATE_CARD_PIN = defineAction('CREATE_CARD_PIN')
export const AGREE_TERMS = defineAction('AGREE_TERMS')
export const AGREE_BETA = defineAction('AGREE_BETA')
export const CREATE_SHIPMENT = defineAction('CREATE_SHIPMENT')
export const GET_ACCOUNT_LIMITS = defineAction('GET_ACCOUNT_LIMITS')
export const GET_PIN_CODE = defineAction('GET_PIN_CODE')
export const GET_KYC_STATUS = defineAction('GET_KYC_STATUS')
export const START_KYC = defineAction('START_KYC')
export const GET_KYC_ADDRESS = defineAction('GET_KYC_ADDRESS')
export const UPDATE_KYC_DRIVERS_LICENCE = defineAction('UPDATE_KYC_DRIVERS_LICENCE')
export const UPDATE_KYC_ADDRESS = defineAction('UPDATE_KYC_ADDRESS')
export const GET_VIRTUAL_CARD = defineAction('GET_VIRTUAL_CARD')
export const UPDATE_CARD_STATUS = defineAction('UPDATE_CARD_STATUS')
export const UPDATE_CARD_PIN = defineAction('UPDATE_CARD_PIN')
export const SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS = defineAction(
  'SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS'
)
export const GET_SHIPMENT_STATUS = defineAction('GET_SHIPMENT_STATUS')
export const VALIDATE_MAILING_ADDRESS = defineAction('VALIDATE_MAILING_ADDRESS')

export const getCheckingAccount = () => (dispatch, getState) =>
  dispatch({
    type: GET_CHECKING_ACCOUNT.ACTION,
    payload: checkingAccountService.getCheckingAccount(
      getState().authentication.get('accessToken')
    ),
  })

export const createCheckingAccount = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_CHECKING_ACCOUNT.ACTION,
    payload: checkingAccountService.createCheckingAccount(
      getState().authentication.get('accessToken')
    ),
  })

export const saveBalanceHidden = payload => dispatch =>
  dispatch({
    type: SAVE_BALANCE_HIDDEN,
    payload,
  })

export const saveBannerHidden = payload => dispatch =>
  dispatch({
    type: SAVE_BANNER_HIDDEN,
    payload,
  })

export const saveAccountDetailsHidden = payload => dispatch =>
  dispatch({
    type: SAVE_ACCOUNT_DETAILS_HIDDEN,
    payload,
  })

export const createCardPin = payload => (dispatch, getState) => {
  dispatch({
    type: CREATE_CARD_PIN.ACTION,
    payload: checkingAccountService.createCardPin(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const agreeTerms = payload => (dispatch, getState) => {
  dispatch({
    type: AGREE_TERMS.ACTION,
    payload: checkingAccountService.agreeTerms(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const agreeBeta = payload => (dispatch, getState) => {
  dispatch({
    type: AGREE_BETA.ACTION,
    payload: checkingAccountService.agreeBeta(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const createShipment = () => (dispatch, getState) => {
  dispatch({
    type: CREATE_SHIPMENT.ACTION,
    payload: checkingAccountService.createShipment(getState().authentication.get('accessToken')),
  })
}

export const getAccountLimits = () => (dispatch, getState) => {
  dispatch({
    type: GET_ACCOUNT_LIMITS.ACTION,
    payload: checkingAccountService.getAccountLimits(getState().authentication.get('accessToken')),
  })
}

export const getKYCStatus = () => (dispatch, getState) => {
  dispatch({
    type: GET_KYC_STATUS.ACTION,
    payload: checkingAccountService.getKYCStatus(getState().authentication.get('accessToken')),
  })
}

export const startKYC = () => (dispatch, getState) => {
  dispatch({
    type: START_KYC.ACTION,
    payload: checkingAccountService.startKYC(getState().authentication.get('accessToken')),
  })
}

export const getKYCAddress = () => (dispatch, getState) => {
  dispatch({
    type: GET_KYC_ADDRESS.ACTION,
    payload: checkingAccountService.getKYCAddress(getState().authentication.get('accessToken')),
  })
}

export const updateKYCDriversLicense = payload => (dispatch, getState) => {
  dispatch({
    type: UPDATE_KYC_DRIVERS_LICENCE.ACTION,
    payload: checkingAccountService.updateKYCDriversLicense(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const updateKYCAddress = payload => (dispatch, getState) => {
  dispatch({
    type: UPDATE_KYC_ADDRESS.ACTION,
    payload: checkingAccountService.updateKYCAddress(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const getVirtualCard = payload => (dispatch, getState) => {
  dispatch({
    type: GET_VIRTUAL_CARD.ACTION,
    payload: checkingAccountService.getVirtualCard(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const updateCardStatus = payload => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CARD_STATUS.ACTION,
    payload: checkingAccountService.updateCardStatus(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const updateCardPin = payload => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CARD_PIN.ACTION,
    payload: checkingAccountService.updateCardPin(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}
export const setPrimaryAddressAsMailingAddress = payload => (dispatch, getState) => {
  dispatch({
    type: SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS.ACTION,
    payload: checkingAccountService.setPrimaryAddressAsMailingAddress(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const getShipmentStatus = () => (dispatch, getState) => {
  dispatch({
    type: GET_SHIPMENT_STATUS.ACTION,
    payload: checkingAccountService.getShipmentStatus(getState().authentication.get('accessToken')),
  })
}

export const validateMailingAddress = payload => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_MAILING_ADDRESS.ACTION,
    payload: checkingAccountService.validateMailingAddress(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
