import { post, get } from './requests'

export const createSalaryAdvance = (key, payload) =>
  post(['salary-advance'], { key, transformPayload: true }, payload)

export const esignSalaryAdvance = (key, id, payload) =>
  post(['salary-advance', id, 'accept'], { key, transformPayload: true }, payload)

export const getSalaryAdvance = key => get(['salary-advance'], { key, transformPayload: true })

export const payNowSalaryAdvance = (key, id, payload) =>
  post(['salary-advance', id, 'pay-now'], { key, transformPayload: true }, payload)

export const getTransactions = key =>
  get(['plaid-metrics', 'possible-salaries'], { key, transformPayload: true })

export const pointSalary = (key, id, payload) =>
  post(['plaid-metrics', id, 'point-salary'], { key, transformPayload: true }, payload)

export const updateCardSalaryAdvance = (key, id, payload) =>
  post(['salary-advance', id, 'update-card'], { key, transformPayload: true }, payload)

export const getUrlPromissoryNoteSalaryAdvance = (key, id) =>
  get(['salary-advance', id, 'documents-promissory-note'], { key, transformPayload: true })

export const getUrlTermsDocuments = (key, id) =>
  get(['salary-advance', id, 'oneblinc-terms'], { key, transformPayload: true })

export const getUrlPrivacyPolicyDocuments = (key, id) =>
  get(['salary-advance', id, 'privacy-policy'], { key, transformPayload: true })
