import { post } from './requests'

export const verify = (key, payload) =>
  post(
    ['credit-score', 'verify'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const history = (key, payload) => {
  return post(
    ['credit-score', 'score-history'],
    {
      key,
      transformPayload: true,
    },
    payload
  )
}
