import { schema } from 'normalizr'

import Model from './model'

class State extends Model({
  code: undefined,
  name: undefined,
}) {}

export default State

const stateNewSchema = new schema.Entity(
  'result',
  {},
  {
    processStrategy: entity => new State(entity),
    idAttribute: entity => entity.code,
  }
)

export const stateSchema = [stateNewSchema]
