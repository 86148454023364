import { Map } from 'immutable'

import { createReducer } from '_utils/redux'

import { GET_DASHBOARD } from './actions'

const INITIAL_STATE = Map({ dashboard: undefined })

export default createReducer(INITIAL_STATE, {
  [GET_DASHBOARD.FULFILLED]: (state, { payload }) => {
    return state.set('dashboard', payload)
  },
  [GET_DASHBOARD.REJECTED]: state => {
    return state.set('dashboard', 404)
  },
})
