import GrowthStore from '_models/growth'
import { createReducer } from '_utils/redux'

import { GET_QUIZ, SEND_QUESTION_RESPONSE, FINISH_QUIZ } from './actions'

const INITIAL_STATE = new GrowthStore()

export default createReducer(INITIAL_STATE, {
  [GET_QUIZ.FULFILLED]: (state, { payload }) => {
    return state.set('quiz', payload)
  },
  [SEND_QUESTION_RESPONSE.FULFILLED]: (state, { payload }) => {
    return state.set('questionResponse', payload)
  },
  [FINISH_QUIZ.FULFILLED]: (state, { payload }) => {
    return state.set('finishQuizResult', payload)
  },
})
