import { defineAction } from '_utils/redux'
import * as atmServices from '_services/atm'

export const GET_ATM = defineAction('GET_ATM')

export const getAtm = payload => (dispatch, getState) =>
  dispatch({
    type: GET_ATM.ACTION,
    payload: atmServices.getAtm(getState().authentication.get('accessToken'), payload),
  })
