import { defineAction } from '_utils/redux'

export const SET_BREADCRUMBS = defineAction('SET_BREADCRUMBS')
export const CLEAR_BREADCRUMBS = defineAction('CLEAR_BREADCRUMBS')

export const setBreadcrumbs = payload => dispatch =>
  dispatch({
    type: SET_BREADCRUMBS.ACTION,
    payload: [{ name: 'Dashboard', path: '/' }, ...payload],
  })

export const clearBreadcrumbs = () => dispatch =>
  dispatch({
    type: CLEAR_BREADCRUMBS.ACTION,
  })
