import { fromJS } from 'immutable'
import { Cookies } from 'react-cookie'

import CheckingAccount from '_models/checking-account'
import { CONFIRM_MAILING_ADDRESS } from '_modules/user/actions'
import { createReducer } from '_utils/redux'

import {
  AGREE_BETA,
  AGREE_TERMS,
  CREATE_CARD_PIN,
  CREATE_CHECKING_ACCOUNT,
  GET_CHECKING_ACCOUNT,
  SAVE_BALANCE_HIDDEN,
  SAVE_BANNER_HIDDEN,
  SAVE_ACCOUNT_DETAILS_HIDDEN,
  GET_ACCOUNT_LIMITS,
  GET_KYC_STATUS,
  START_KYC,
  GET_VIRTUAL_CARD,
  UPDATE_CARD_STATUS,
  GET_SHIPMENT_STATUS,
} from './actions'

const cookies = new Cookies()

const INITIAL_STATE = new CheckingAccount()

export default createReducer(INITIAL_STATE, {
  [GET_CHECKING_ACCOUNT.FULFILLED]: (state, { payload }) => {
    const isBalanceHidden = cookies.get('balanceHidden', { path: '/' }) !== 'false'
    const isAccountDetailsHidden = cookies.get('accountDetailsHidden', { path: '/' }) !== 'false'
    return state.merge(fromJS({ ...payload, isBalanceHidden, isAccountDetailsHidden }))
  },

  [SAVE_BALANCE_HIDDEN]: (state, { payload }) => {
    cookies.set('balanceHidden', payload, { path: '/' })
    return state.set('isBalanceHidden', payload)
  },

  [SAVE_BANNER_HIDDEN]: (state, { payload }) => {
    cookies.set('bannerHidden', payload, { path: '/' })
    return state.set('isBannerHidden', payload)
  },

  [SAVE_ACCOUNT_DETAILS_HIDDEN]: (state, { payload }) => {
    cookies.set('accountDetailsHidden', payload, { path: '/' })
    return state.set('isAccountDetailsHidden', payload)
  },

  [CREATE_CHECKING_ACCOUNT.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },
  [AGREE_TERMS.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },
  [AGREE_BETA.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },
  [CONFIRM_MAILING_ADDRESS.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },
  [CREATE_CARD_PIN.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },
  [GET_ACCOUNT_LIMITS.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS(payload))
  },
  [GET_KYC_STATUS.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS({ kycStatus: payload.status }))
  },
  [START_KYC.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS(payload))
  },
  [GET_VIRTUAL_CARD.FULFILLED]: (state, { payload }) => {
    return state.set('virtualCardSubnet', fromJS(payload))
  },
  [UPDATE_CARD_STATUS.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS(payload))
  },
  [GET_SHIPMENT_STATUS.FULFILLED]: (state, { payload }) => {
    if (!payload) {
      return state.setIn(['shipmentStatus'], null)
    }
    return state.setIn(
      ['shipmentStatus'],
      fromJS({
        status: payload.status.toLowerCase(),
        courier: payload.raw.deliveryCarrier,
        trackingNumber: payload.tracking,
      })
    )
  },
})
