import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from '@react-spring/web'

// import { Container } from './styles';

function AnimatedScale({ children, className, elastic }) {
  const animation = useSpring({
    from: {
      opacity: 0,
      transform: 'scale(0)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
    delay: 300,
    config: elastic ? { mass: 1, tension: 300, friction: 20 } : null,
  })

  return (
    <animated.div style={animation} className={className} key={className}>
      {children}
    </animated.div>
  )
}

AnimatedScale.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  elastic: PropTypes.bool,
}

AnimatedScale.defaultProps = {
  className: '',
  elastic: false,
}

export default AnimatedScale
