import { Map } from 'immutable'
import { createSelector } from 'reselect'

import {
  GET_CASHBACK_STORES,
  GET_CASHBACK_USER,
  ENTER_WAITLIST,
  REDEEM_CASHBACK,
  GET_LEAD_CARD_FORM_URL,
  GET_LEAD_USER_CARDS,
} from './actions'

export const getCashbackStoresSelector = state => state.cashback.get('cashbackStores')
export const isGetCashbackStoresLoadingSelector = state =>
  state.loading.get(GET_CASHBACK_STORES.ACTION)
export const getCashbackStoresErrorSelector = state =>
  state.error.get(GET_CASHBACK_STORES.ACTION, Map())

export const getLeadTokenSelector = state => state.cashback.get('leadToken')

export const getCashbackUserSelector = state => state.cashback.get('cashbackUser')
export const isGetCashbackUserLoadingSelector = state => state.loading.get(GET_CASHBACK_USER.ACTION)
export const getCashbackUserErrorSelector = state =>
  state.error.get(GET_CASHBACK_USER.ACTION, Map())

export const redeemedNetflixFirstMonthSelector = createSelector(
  getCashbackUserSelector,
  cashbackUserInfo => {
    const paidPurchases = cashbackUserInfo?.paid?.data || []
    return paidPurchases.some(paidPurchase => paidPurchase.programName === 'OneBlinc NETFLIX')
  }
)

export const enterWaitlistSelector = state => state.cashback.get('enterWaitlist')
export const isEnterWaitlistLoadingSelector = state => state.loading.get(ENTER_WAITLIST.ACTION)
export const enterWaitlistErrorSelector = state => state.error.get(ENTER_WAITLIST.ACTION, Map())

export const redeemCashbackSelector = state => state.cashback.get('redeemCashback')
export const isRedeemCashbackLoadingSelector = state => state.loading.get(REDEEM_CASHBACK.ACTION)
export const redeemCashbackErrorSelector = state => state.error.get(REDEEM_CASHBACK.ACTION, Map())

export const leadCardFormUrlSelector = state => state.cashback.get('leadCardFormUrl')
export const isGetLeadCardFormUrlLoadingSelector = state =>
  state.loading.get(GET_LEAD_CARD_FORM_URL.ACTION)
export const getLeadCardFormUrlErrorSelector = state =>
  state.error.get(GET_LEAD_CARD_FORM_URL.ACTION, Map())

export const leadUserCardsSelector = state => state.cashback.get('leadUserCards')
export const isGetLeadUserCardsLoadingSelector = state =>
  state.loading.get(GET_LEAD_USER_CARDS.ACTION)
export const getLeadUserCardsErrorSelector = state =>
  state.error.get(GET_LEAD_USER_CARDS.ACTION, Map())

export const hasNetflixCashbackSelector = state =>
  state.cashback
    .get('cashbackUser')
    .approved.data.some(activePurchase => activePurchase.programName === 'OneBlinc NETFLIX')

export const isBirthdayCashbackPaidSelector = state =>
  state.cashback
    .get('cashbackUser')
    ?.paid?.data?.some(activePurchase => activePurchase.programName === 'OneClub - Happy Birthday')

export const doesCashbackExistsSelector = programName => state =>
  state.cashback
    .get('cashbackUser')
    .approved.data.some(activePurchase => activePurchase.programName === programName) ||
  state.cashback
    .get('cashbackUser')
    .redeem.data.some(activePurchase => activePurchase.programName === programName) ||
  state.cashback
    .get('cashbackUser')
    .pending.data.some(activePurchase => activePurchase.programName === programName) ||
  state.cashback
    .get('cashbackUser')
    .paid.data.some(activePurchase => activePurchase.programName === programName)
