import { decamelizeKeys } from 'humps'

import { get } from './requests'

export const getAppSettings = (key, payload) =>
  get(
    ['settings', 'all'],
    {
      key,
    },
    payload
  )

export const getPublicSettings = payload =>
  get(['settings', 'public'], {
    params: decamelizeKeys(payload),
  })
