import { get, post } from './requests'

export const getRenegAmounts = key =>
  get(['loans', 'reneg_amounts'], { key, transformPayload: true })

export const recalculateReneg = (key, payload) =>
  post(['loans', 'calculate_reneg'], { key, transformPayload: true }, payload)

export const createReneg = (key, payload) =>
  post(['loans', 'reneg'], { key, transformPayload: true }, payload)

export const acceptReneg = (key, loanId) =>
  get(['loans', loanId, 'accept_reneg'], { key, transformPayload: true })
