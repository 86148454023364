import { EVENT_TYPE_CARDS } from '_utils/constants'

import { GET_PUBLIC_APP_SETTINGS, GET_APP_SETTINGS } from './actions'

export const isGetAppSettingsLoadingSelector = state => !!state.loading.get(GET_APP_SETTINGS.ACTION)

export const getAchPushEnabledSelector = state => state.appSettings.get('isAchPushEnabled')
export const getAchPullEnabledSelector = state => state.appSettings.get('isAchPullEnabled')

export const getCashbackCardEnabledSelector = state =>
  state.appSettings.get('isCashbackCardEnabled')

export const getIsSalaryAdvanceEnabledForOneblincAccount = state =>
  state.appSettings.get('isSalaryAdvanceEnabledForOneblincAccount')

export const getCashbackMinRedeemAmountSelector = state =>
  state.appSettings.get('cashbackMinRedeemAmount')

export const getMailCheckEnabledSelector = state => state.appSettings.get('isMailCheckEnabled')
export const getActiveEventCardSelector = state => state.appSettings.get('activeEventCard')

// Selectors for validating the active event type
export const isActiveEventNetflixRedeemSelector = state =>
  state.appSettings.get('activeEventCard') === EVENT_TYPE_CARDS.NETFLIX_REDEEM
export const isActiveEventCashbackSelector = state =>
  state.appSettings.get('activeEventCard') === EVENT_TYPE_CARDS.CASHBACK

// Selectors for public settings
export const isCheckNewSignUpAvailableLoadingSelector = state =>
  !!state.loading.get(GET_PUBLIC_APP_SETTINGS.ACTION)
export const isNewSignUpAvailableSelector = state => state.appSettings.get('isNewSignupAvailable')

export const getRenegDiscountDeadlineSelector = state =>
  state.appSettings.get('renegDiscountDeadline')

export const getIsUserBlockedSelector = state => state.appSettings.get('isUserBlocked')

export const isRenegotiationActiveSelector = state => state.appSettings.get('isRenegotiationActive')

export const isProtectShieldActiveSelector = state => state.appSettings.get('isProtectShieldActive')

export const salaryAdvanceAmountOneClubSelector = state =>
  state.appSettings.get('salaryAdvanceAmountOneClub')

export const maintenanceAlertSelector = state => state.appSettings.get('maintenanceAlert')
export const isBlinkyBotEnabledSelector = state => state.appSettings.get('isBlinkyBotEnabled')
export const getOneClubCashbackAmountSelector = state =>
  state.appSettings.get('oneClubCashbackAmount')
export const isCreditScoreEnabledSelector = state => state.appSettings.get('isCreditScoreActive')

export const protectionShieldResumeDateSelector = state =>
  state.appSettings.get('protectionShieldResumeDate')

export const isInsuranceCardEnabledSelector = state =>
  state.appSettings.get('isInsuranceCardEnabled')

export const hasCashbackOnSalaryCardValidationSelector = state =>
  state.appSettings.get('hasCashbackOnSalaryCardValidation')

export const getChatbotSelector = state => state.appSettings.get('thirdPartyChatbot')
export const getAvailableDepositOptionsSelector = state =>
  state.appSettings.get('availableDepositOptions')

export const getPaymentBrokerProvidersSelector = state =>
  state.appSettings.get('paymentBrokerProviders')
