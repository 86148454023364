import React, { useState, useMemo, useCallback } from 'react'
import { Router as ReachRouter } from '@reach/router'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/browser'
import { Cookies } from 'react-cookie'

import ErrorView from '_views/error'
import { useRefreshToken } from '_hooks/use-refresh-token'
import { useZendeskIdentity } from '_hooks/use-zendesk-identity'
import ProtectedRoutes from '_components/protected-routes'
import UpdateAutopayTermsProtectedRoutes from '_components/protected-routes/update-autopay-terms-protected-routes'
import ScrollToTop from '_components/scroll-to-top'
import { OriginalPathContext, ORIGINAL_PATH } from '_config/app-context'
import MaintenanceModal from '_components/modal/maintenance-modal'
import { loadableComponent } from '_utils/helpers'
import { useScreenHeightOnResize } from '_hooks/use-screen-height-on-resize'
import { getOpenReplayTracker } from '_utils/open-replay'
import ProgressBarLoader from '_components/progress-bar-loader'
import MoveMoneyProtectedRoutes from '_components/protected-routes/move-money'
import CashbackApp from '_views/cashback-app'
import SalaryAccountCardValidation from '_components/salary-account-card-validation'
import LoanDocuments from '_views/loan-documents'
import ChatbotHOC from '_components/chatbot-hoc'

const Repay = loadableComponent(() => import(/* webpackChunkName: "Repay" */ '_views/modals/repay'))

const AddBankManually = loadableComponent(() =>
  import(/* webpackChunkName: "AddBankManually" */ '_views/modals/add-bank-manually')
)

const Login = loadableComponent(() => import(/* webpackChunkName: "Login" */ '_views/login'))
const TermsAndConditions = loadableComponent(() =>
  import(/* webpackChunkName: "TermsAndConditions" */ '_views/modals/terms-and-conditions')
)

const Terms = loadableComponent(() => import(/* webpackChunkName: "Terms" */ '_views/modals/terms'))

const AuthorizeContact = loadableComponent(() =>
  import(/* webpackChunkName: "AuthorizeContact" */ '_views/modals/authorize-contact')
)

const NotFoundPage = loadableComponent(() =>
  import(/* webpackChunkName: "NotFoundPage" */ '_views/not-found')
)

const NewApplication = loadableComponent(() =>
  import(/* webpackChunkName: "LoanTerms" */ '_views/dashboard-app/new-application')
)

const DashboardApp = loadableComponent(
  () => import(/* webpackChunkName: "DashboardApp" */ '_views/dashboard-app'),
  <ProgressBarLoader />
)

const Dashboard = loadableComponent(() =>
  import(/* webpackChunkName: "Dashboard" */ '_views/dashboard-app/dashboard')
)

const PersonalInformation = loadableComponent(() =>
  import(/* webpackChunkName: "PersonalInformation" */ '_views/dashboard-app/personal-info')
)

const CreditScore = loadableComponent(() =>
  import(/* webpackChunkName: "CreditScore" */ '_views/dashboard-app/credit-score')
)

const Referrals = loadableComponent(() =>
  import(/* webpackChunkName: "Referrals" */ '_views/dashboard-app/referrals')
)

const MyProducts = loadableComponent(() =>
  import(/* webpackChunkName: "MyProducts" */ '_views/dashboard-app/my-products')
)

const MakePayment = loadableComponent(() =>
  import(/* webpackChunkName: "MakePayment" */ '_views/modals/make-payment')
)

const SsnVerification = loadableComponent(() =>
  import(/* webpackChunkName: "SsnVerification" */ '_views/modals/ssn-verification')
)

const Transactions = loadableComponent(() =>
  import(/* webpackChunkName: "Transactions" */ '_views/dashboard-app/transactions')
)

const SalaryAdvance = loadableComponent(() =>
  import(/* webpackChunkName: "SalaryAdvance" */ '_views/dashboard-app/salary-advance')
)

const UploadDocuments = loadableComponent(() =>
  import(/* webpackChunkName: "UploadDocuments" */ '_views/modals/upload-documents')
)

const SalaryAdvanceDocuments = loadableComponent(() =>
  import(/* webpackChunkName: "SalaryAdvanceDocuments" */ '_views/salary-advance-documents')
)

const Refinance = loadableComponent(() =>
  import(/* webpackChunkName: "Refinance" */ '_views/dashboard-app/refinance')
)

const PayrollTermsAndConditions = loadableComponent(() =>
  import(
    /* webpackChunkName: "PayrollTermsAndConditions" */ '_views/modals/payroll-terms-and-conditions'
  )
)

const UnlockSalaryAdvance = loadableComponent(() =>
  import(/* webpackChunkName: "UnlockSalaryAdvance" */ '_views/dashboard-app/unlock-salary-advance')
)

const ToolboxDashboard = loadableComponent(() =>
  import(/* webpackChunkName: "ToolboxDashboard" */ '_views/dashboard-app/toolbox-dashboard')
)

const ActivateCard = loadableComponent(() =>
  import(/* webpackChunkName: "ActivateCard" */ '_views/dashboard-app/activate-card/activate-form')
)

const ActivateCardPin = loadableComponent(() =>
  import(/* webpackChunkName: "ActivateCard" */ '_views/dashboard-app/activate-card/activate-pin')
)

const ActivateCardResult = loadableComponent(() =>
  import(
    /* webpackChunkName: "ActivateCard" */ '_views/dashboard-app/activate-card/activate-result'
  )
)

const CheckingAccount = loadableComponent(() =>
  import(/* webpackChunkName: "CheckingAccount" */ '_views/dashboard-app/checking-account')
)

const CardRequest = loadableComponent(() =>
  import(/* webpackChunkName: "CardRequest" */ '_views/dashboard-app/card-request')
)

const CardPasscode = loadableComponent(() =>
  import(/* webpackChunkName: "CardPasscode" */ '_views/dashboard-app/card-request/card-passcode')
)
const CardCompletedRequest = loadableComponent(() =>
  import(
    /* webpackChunkName: "CardCompletedRequest" */ '_views/dashboard-app/card-request/card-completed-request'
  )
)

const FindATM = loadableComponent(() =>
  import(/* webpackChunkName: "FindATM" */ '_views/dashboard-app/find-atm')
)

const MoveMoney = loadableComponent(() =>
  import(/* webpackChunkName: "MoveMoney" */ '_views/dashboard-app/move-money')
)

const TransferFundsToYourself = loadableComponent(() =>
  import(
    /* webpackChunkName: "TransferFundsToYourself" */ '_views/dashboard-app/transfer-funds-to-yourself'
  )
)

const DirectDeposit = loadableComponent(() =>
  import(/* webpackChunkName: "DirectDeposit" */ '_views/dashboard-app/direct-deposit')
)

const PayBills = loadableComponent(() =>
  import(/* webpackChunkName: "PayBills" */ '_views/dashboard-app/pay-bills')
)
const BankAccountSettings = loadableComponent(() =>
  import(/* webpackChunkName: "BankAccountSettings" */ '_views/dashboard-app/bank-account-settings')
)

const CashbackDashboard = loadableComponent(() =>
  import(/* webpackChunkName: "Cashback" */ '_views/cashback-app/cashback-dashboard')
)

const UpdateDriversLicence = loadableComponent(() =>
  import(
    /* webpackChunkName: "UpdateDriversLicence" */ '_views/dashboard-app/update-drivers-licence'
  )
)

const UpdateAddress = loadableComponent(() =>
  import(/* webpackChunkName: "UpdateAddress" */ '_views/dashboard-app/update-address')
)

const TransferFromOtherBank = loadableComponent(() =>
  import(
    /* webpackChunkName: "TransferFromOtherBank" */ '_views/dashboard-app/transfer-from-other-bank'
  )
)

const MailCheck = loadableComponent(() =>
  import(/* webpackChunkName: "MailCheck" */ '_views/dashboard-app/mail-check')
)

const Quiz = loadableComponent(() =>
  import(/* webpackChunkName: "Quiz" */ '_views/dashboard-app/quiz')
)

const LoanPayment = loadableComponent(() =>
  import(/* webpackChunkName: "LoanPayment" */ '_views/dashboard-app/loan-payment')
)

const MethodAch = loadableComponent(() =>
  import(/* webpackChunkName: "MethodAch" */ '_views/dashboard-app/loan-payment/method-ach')
)

const OneTimePayment = loadableComponent(() =>
  import(
    /* webpackChunkName: "OneTimePayment" */ '_views/dashboard-app/loan-payment/method-ach/one-time-payment'
  )
)

const AutoPay = loadableComponent(() =>
  import(
    /* webpackChunkName: "LoanPayment" */ '_views/dashboard-app/loan-payment/method-ach/auto-pay'
  )
)
const ManagePayments = loadableComponent(() =>
  import(/* webpackChunkName: "MethodDebit" */ '_views/dashboard-app/manage-payments')
)

const MethodDebit = loadableComponent(() =>
  import(/* webpackChunkName: "MethodDebit" */ '_views/dashboard-app/loan-payment/method-debit')
)

const Reneg = loadableComponent(() =>
  import(/* webpackChunkName: "Reneg" */ '_views/dashboard-app/reneg')
)

const ProtectShield = loadableComponent(() =>
  import(/* webpackChunkName: "Reneg" */ '_views/dashboard-app/protect-shield')
)

const ChangeFallbackAccount = loadableComponent(() =>
  import(
    /* webpackChunkName: "ChangeFallbackAccount" */ '_views/dashboard-app/change-fallback-account'
  )
)

const PlaidOauth = loadableComponent(() =>
  import(/* webpackChunkName: "PlaidOauth" */ '_views/plaid-oauth')
)

const PendingLoan = loadableComponent(() =>
  import(/* webpackChunkName: "PendingLoan" */ '_views/pending-loan')
)

const BlockedUser = loadableComponent(() =>
  import(/* webpackChunkName: "BlockedUser" */ '_views/blocked-user')
)

const UpdateAutopayTerms = loadableComponent(() =>
  import(/* webpackChunkName: "UpdateAutopayTerms" */ '_views/update-autopay-terms')
)

const UpdateInstant = loadableComponent(() =>
  import(/* webpackChunkName: "UpdateInstant" */ '_views/dashboard-app/update-instant')
)

const SalaryAdvanceOneclub = loadableComponent(() =>
  import(
    /* webpackChunkName: "SalaryAdvanceOneclub" */ '_views/dashboard-app/salary-advance-oneclub'
  )
)

const SkipPaymentOneclub = loadableComponent(() =>
  import(/* webpackChunkName: "SkipPaymentOneclub" */ '_views/dashboard-app/skip-payment-oneclub')
)

const BirthdayGiftOneclub = loadableComponent(() =>
  import(/* webpackChunkName: "BirthdayGiftOneclub" */ '_views/dashboard-app/birthday-gift-oneclub')
)
const BlinkyBot = loadableComponent(() =>
  import(/* webpackChunkName: "BlinkyBot" */ '_views/dashboard-app/blinky-bot')
)

const AllotmentReconnect = loadableComponent(() =>
  import(/* webpackChunkName: "AllotmentReconnect" */ '_views/dashboard-app/allotment-reconnect')
)
const Subscription = loadableComponent(() =>
  import(/* webpackChunkName: "Subscription" */ '_views/dashboard-app/subscription')
)
const ForbearanceFlow = loadableComponent(() =>
  import(/* webpackChunkName: "UpdateInstant" */ '_views/forbearance-flow')
)

const ValidateSalaryAccountCard = loadableComponent(() =>
  import(/* webpackChunkName: "UpdateInstant" */ '_views/validate-salary-account-card')
)

const VerifyPhone = loadableComponent(() =>
  import(/* webpackChunkName: "VerifyPhone" */ '_views/dashboard-app/verify-phone')
)

const cookies = new Cookies()

const Router = props => {
  const [originalPathState, setOriginalPathState] = useState(ORIGINAL_PATH)
  const [isMaintenanceModalOpen, setMaintenanceModal] = useState(false)
  const MemoizedOriginalPathContext = useMemo(
    () => ({ originalPathState, setOriginalPathState }),
    [originalPathState]
  )
  useRefreshToken()
  useZendeskIdentity()
  useScreenHeightOnResize()

  const handleCloseMaintenanceModal = useCallback(() => {
    setMaintenanceModal(false)
  }, [])

  const onError = useCallback(error => {
    const exception = new Error(`ERROR SCREEN: ${error.message}`)
    Sentry.captureException(exception)
    getOpenReplayTracker().handleError(exception)

    const errorCount = cookies.get('errorCount', { path: '/' })
      ? Number(cookies.get('errorCount', { path: '/' }))
      : 0

    if (error.message?.includes('Loading') && errorCount < 3) {
      window.location.reload()
      cookies.set('errorCount', String(errorCount + 1), { path: '/', maxAge: 3600 })
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorView} onError={onError}>
      <OriginalPathContext.Provider value={MemoizedOriginalPathContext}>
        {isMaintenanceModalOpen && <MaintenanceModal onCloseModal={handleCloseMaintenanceModal} />}
        <ReachRouter {...props}>
          <ChatbotHOC path="/">
            <ScrollToTop path="/">
              <UpdateAutopayTermsProtectedRoutes path="/">
                <ProtectedRoutes path="/">
                  <SalaryAccountCardValidation path="/">
                    <DashboardApp path="/">
                      <Dashboard path="/" />
                      <MyProducts path="/my-products" />
                      <LoanPayment path="/loan-payment" />
                      <MethodAch path="/loan-payment/method-ach" />
                      <OneTimePayment path="/loan-payment/method-ach/one-time" />
                      <AutoPay path="/loan-payment/method-ach/auto-pay" />
                      <MethodDebit path="/loan-payment/method-debit" />
                      <MethodDebit path="/pay/debit" />
                      <PersonalInformation path="/personal-information" />
                      <Referrals path="/referrals" />
                      <NewApplication path="/new-application" />
                      <NewApplication path="/reapply" />
                      <Refinance path="/refinance" />
                      <Transactions path="/transactions" />
                      <SalaryAdvance path="/salary-advance" />
                      <ManagePayments path="/manage-payments" />
                      <UnlockSalaryAdvance path="/unlock-salary-advance" />
                      <SalaryAdvance path="/my-products/salary-advance" />
                      <ToolboxDashboard path="/toolbox" />
                      <CheckingAccount path="/checking-account" />
                      <CardRequest path="/confirm-address" />
                      <CardPasscode path="/pin-request" />
                      <CardCompletedRequest path="/card-shipped" />
                      <ActivateCard path="/activate-card" />
                      <ActivateCardPin path="/activate" />
                      <ActivateCardResult path="/activate-card-success" />
                      <FindATM path="/find-atm" />
                      <UpdateDriversLicence path="/update-drivers-licence" />
                      <UpdateAddress path="/update-address" />
                      <BankAccountSettings path="/bank-account-settings" />
                      <MoveMoneyProtectedRoutes path="/move-money">
                        <MoveMoney path="/" />
                        <TransferFundsToYourself path="/transfer-funds-to-yourself" />
                        <DirectDeposit path="/direct-deposit" />
                        <PayBills path="/pay-bills" />
                        <TransferFromOtherBank path="/transfer-from-other-bank" />
                        <MailCheck path="/mail-check" />
                      </MoveMoneyProtectedRoutes>
                      <Quiz path="/rewards" />
                      <Reneg path="/reneg" />
                      <ChangeFallbackAccount path="/change-fallback-account" />
                      <UpdateInstant path="/update-instant" />
                      <BirthdayGiftOneclub path="/birthday-gift-oneclub" />
                      <SkipPaymentOneclub path="/skip-payment" />
                      <SalaryAdvanceOneclub path="/salary-advance-oneclub" />
                      <BlinkyBot path="/assistant" />
                      <AllotmentReconnect path="/allotment-reconnect" />
                      <CreditScore path="/credit-score" />

                      <ValidateSalaryAccountCard path="validate-card" />
                      <VerifyPhone path="verify-phone" />

                      {/* <SubscribedRoutes path="/" /> */}
                    </DashboardApp>
                  </SalaryAccountCardValidation>
                  <CashbackApp path="/cashback-dashboard">
                    <CashbackDashboard path="/" />
                    <ToolboxDashboard path="/toolbox" />
                  </CashbackApp>

                  <Subscription path="/subscribe" />

                  {/* Pages outside dashboard */}
                  <Login path="/login" />
                  <LoanDocuments path="/loan-documents" />
                  <SalaryAdvanceDocuments path="/salary-advance-documents" />
                  <PlaidOauth path="/plaid-oauth" />
                  <PendingLoan path="/pending-loan" />
                  {/* --- */}

                  <NotFoundPage default />
                  <TermsAndConditions path="/terms-and-conditions" />
                  <PayrollTermsAndConditions path="/payroll-terms-and-conditions" />
                  <Terms path="/terms" />
                  <AuthorizeContact path="/authorize-contact" />
                  <AddBankManually path="/add-bank-manually" />
                  <MakePayment path="/make-payment" />
                  <SsnVerification path="/ssn-verification" />
                  <Repay path="/repay" />
                  <UploadDocuments path="/upload-documents" />
                  <ForbearanceFlow path="/forbearance" />
                  <ProtectShield path="/protect-shield" />
                </ProtectedRoutes>
              </UpdateAutopayTermsProtectedRoutes>
              <BlockedUser path="/blocked-user" />
              <UpdateAutopayTerms path="/update-terms" />
            </ScrollToTop>
          </ChatbotHOC>
        </ReachRouter>
      </OriginalPathContext.Provider>
    </ErrorBoundary>
  )
}

export default Router
