import { Map } from 'immutable'

import { createReducer } from '_utils/redux'
import { GET_USER } from '_modules/user/actions'

import {
  CREATE_PARTNER_ACCOUNT,
  SAVE_ARGYLE_TOKEN,
  UPDATE_ARGYLE_TOKEN,
  CHECK_ARGYLE_DATA,
  CHECK_ARGYLE_CONNECTION,
  GET_ARGYLE_FLOW_ID,
} from './actions'

const INITIAL_STATE = Map({
  argyleToken: null,
  argyleCustomizationId: null,
  linkItemId: null,
  checkArgyleData: null,
  checkArgyleConnection: null,
  flowId: null,
})

export default createReducer(INITIAL_STATE, {
  [SAVE_ARGYLE_TOKEN.FULFILLED]: (state, { payload }) => {
    return state.set('argyleToken', payload.token).set('linkItemId', payload.linkItemId)
  },

  [UPDATE_ARGYLE_TOKEN.FULFILLED]: (state, { payload }) => {
    return state
      .set('argyleToken', payload.token)
      .set('argyleCustomizationId', payload.customizationId)
  },

  [UPDATE_ARGYLE_TOKEN.REJECTED]: state => {
    return state.set('argyleToken', null)
  },

  [SAVE_ARGYLE_TOKEN.FULFILLED]: (state, { payload }) => {
    return state.set('argyleToken', payload.token).set('linkItemId', payload.linkItemId)
  },

  [CREATE_PARTNER_ACCOUNT.FULFILLED]: (state, { payload }) => {
    return state.set('linkItemId', payload.linkItemId)
  },
  [GET_USER.FULFILLED]: (state, { payload }) => {
    return state.set('linkItemId', payload.linkItemId)
  },
  [CHECK_ARGYLE_DATA.FULFILLED]: (state, { payload }) => {
    return state.set('checkArgyleData', payload)
  },
  [CHECK_ARGYLE_CONNECTION.FULFILLED]: (state, { payload }) => {
    return state.set('checkArgyleConnection', payload)
  },
  [GET_ARGYLE_FLOW_ID.FULFILLED]: (state, { payload }) => {
    return state.set('flowId', payload.flowId)
  },
})
