import { normalize } from 'normalizr'
import { Map, fromJS } from 'immutable'

import { createReducer } from '_utils/redux'
import { Loan, documentSchema, Document, loanSchema } from '_models'
import { DOCUMENT_CATEGORIES_LABEL, LOAN_STATUS, DONT_UPDATE_LOAN } from '_utils/constants'

import {
  CREATE_LOAN,
  GET_LOANS,
  GET_LOAN_DOCUMENTS,
  UPLOAD_DOCUMENT,
  GET_CARD_FORM,
  GET_ALLOTMENT,
  REAPPLY_PREVIEW,
  GET_REFINANCE_DOCUMENTS,
  PAYMENT_HISTORY,
  CUSTOMER_HISTORY,
  ENCRYPT_ALLOTMENT,
  GET_LOAN_ONE_PAYMENT,
  GET_LOAN_PAYMENTS_DUE,
  GET_ACH_PAYMENTS,
  GET_AUTO_PAY_INFO,
  GET_RENEG_LOAN_ONE_PAYMENT,
  ENCRYPT_RECONNECTION_ALLOTMENT,
  CLEAR_LOAN_DRAFT,
  NEED_UPDATE_CONTACT_INFO,
} from './actions'

const INITIAL_STATE = new Loan()

export default createReducer(INITIAL_STATE, {
  [CREATE_LOAN.FULFILLED]: (state, { payload }) => {
    const doNotUpdateLoan = !!state.loans?.get(DONT_UPDATE_LOAN)

    if (doNotUpdateLoan) {
      return state.mergeDeep({
        loan: {
          [doNotUpdateLoan]: false,
        },
      })
    }

    return state.set('loanDraft', fromJS(payload))
  },

  [GET_LOANS.FULFILLED]: (state, { payload, meta }) => {
    const normalizedLoans = normalize(payload.results, loanSchema)
    const lastLoan = payload.results?.[0]
    const { loadingState } = meta

    // If last loan is refinance denied/expired => Get the most recent released/settled
    // Otherwise use the most recent loan

    let currentLoan = lastLoan
    // TODO: Refactor pelamordedeus
    if (
      payload.results?.find(elem =>
        [LOAN_STATUS.APPROVED, LOAN_STATUS.REFINANCE_DISBURSEMENT_PENDING].includes(elem.status)
      )
    ) {
      currentLoan = payload.results?.find(elem =>
        [LOAN_STATUS.APPROVED, LOAN_STATUS.REFINANCE_DISBURSEMENT_PENDING].includes(elem.status)
      )
    } else if (
      payload.results?.find(elem =>
        [LOAN_STATUS.PENDING_DOCUMENTS, LOAN_STATUS.REFINANCE_DOCUMENT_PENDING].includes(
          elem.status
        )
      )
    ) {
      currentLoan = payload.results?.find(elem =>
        [LOAN_STATUS.PENDING_DOCUMENTS, LOAN_STATUS.REFINANCE_DOCUMENT_PENDING].includes(
          elem.status
        )
      )
    } else if (payload.results?.find(elem => [LOAN_STATUS.RELEASED].includes(elem.status))) {
      currentLoan = payload.results?.find(elem => [LOAN_STATUS.RELEASED].includes(elem.status))
    }

    if (
      lastLoan?.isRefinanceLoan &&
      [LOAN_STATUS.DENIED, LOAN_STATUS.EXPIRED].includes(lastLoan?.status)
    ) {
      currentLoan = payload.results?.find(elem =>
        [LOAN_STATUS.RELEASED, LOAN_STATUS.SETTLED].includes(elem.status)
      )
    }

    if (currentLoan === undefined) {
      currentLoan = lastLoan
    }
    const loan = Map(currentLoan)
    return state.merge(loan).set(
      'loans',
      fromJS({
        results: normalizedLoans.entities.result,
        order: normalizedLoans.result,
        [DONT_UPDATE_LOAN]: !!loadingState?.get(CREATE_LOAN.ACTION),
      })
    )
  },

  [GET_LOAN_DOCUMENTS.FULFILLED]: (state, { payload }) => {
    const normalizedDocuments = normalize(payload, documentSchema)
    return state.set('documents', Map(normalizedDocuments.entities.result))
  },

  [GET_REFINANCE_DOCUMENTS.FULFILLED]: (state, { payload }) => {
    const normalizedDocuments = normalize(payload, documentSchema)
    return state.set('refinanceDocuments', Map(normalizedDocuments.entities.result))
  },

  [UPLOAD_DOCUMENT.PENDING]: (state, { meta: { category, field } }) => {
    return state.setIn([field, category, 'loading'], true)
  },
  [UPLOAD_DOCUMENT.REJECTED]: (state, { meta: { category, field } }) => {
    return state.setIn([field, category, 'loading'], false)
  },
  [UPLOAD_DOCUMENT.FULFILLED]: (state, { payload, meta: { category, field } }) => {
    return state.setIn(
      [field, category],
      new Document({ ...payload, loading: false, label: DOCUMENT_CATEGORIES_LABEL[category] })
    )
  },
  [GET_CARD_FORM.FULFILLED]: (state, { payload: { url } }) => {
    return state.set('cardFormUrl', url)
  },
  [GET_ALLOTMENT.FULFILLED]: (state, { payload }) => {
    return state.set('allotment', Map(payload))
  },
  [ENCRYPT_ALLOTMENT.FULFILLED]: (state, { payload }) => {
    return state.set('encryptedAllotment', payload.encryptedConfig)
  },
  [ENCRYPT_RECONNECTION_ALLOTMENT.FULFILLED]: (state, { payload }) => {
    return state.set('encryptedAllotment', payload.encryptedConfig)
  },
  [REAPPLY_PREVIEW.FULFILLED]: (state, { payload }) => {
    return state.set('loanDraft', Map({ ...payload, status: LOAN_STATUS.STARTED }))
  },
  [PAYMENT_HISTORY.FULFILLED]: (state, { payload }) => {
    return state.set('paymentHistory', fromJS(payload))
  },
  [CUSTOMER_HISTORY.FULFILLED]: (state, { payload }) => {
    return state.set('customerHistory', fromJS(payload))
  },

  [GET_LOAN_ONE_PAYMENT.FULFILLED]: (state, { payload }) => {
    return state.set('onePayment', fromJS(payload))
  },

  [GET_RENEG_LOAN_ONE_PAYMENT.FULFILLED]: (state, { payload }) => {
    return state.set('onePayment', fromJS(payload))
  },

  [GET_LOAN_PAYMENTS_DUE.FULFILLED]: (state, { payload }) => {
    return state.set('paymentsDue', fromJS(payload))
  },
  [GET_ACH_PAYMENTS.FULFILLED]: (state, { payload }) => {
    return state.set('achPayment', fromJS(payload))
  },
  [GET_AUTO_PAY_INFO.FULFILLED]: (state, { payload }) => {
    return state.set('autoPayInfo', fromJS(payload))
  },
  [CLEAR_LOAN_DRAFT]: state => {
    return state.set('loanDraft', null)
  },

  [NEED_UPDATE_CONTACT_INFO.FULFILLED]: (state, { payload }) => {
    return state.set('needUpdateContactInfo', payload.needUpdate)
  },
})
