import { fromJS } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { SalaryAdvance, salaryAdvanceSchema } from '_models'

import {
  CREATE_SALARY_ADVANCE,
  ESIGN_SALARY_ADVANCE,
  GET_SALARY_ADVANCE,
  PAY_NOW_SALARY_ADVANCE,
  GET_SALARY_ADVANCE_TRANSACTIONS,
  UPDATE_CARD_SALARY_ADVANCE,
  GET_PROMISSORY_NOTE_URL_SA,
  GET_TERMS_URL_SA,
  GET_PRIVACY_POLICY_URL_SA,
} from './actions'

const INITIAL_STATE = new SalaryAdvance()

export default createReducer(INITIAL_STATE, {
  [CREATE_SALARY_ADVANCE.FULFILLED]: (state, { payload }) => {
    const salaryAdvance = fromJS(payload)
    return state.merge(salaryAdvance)
  },
  [ESIGN_SALARY_ADVANCE.FULFILLED]: (state, { payload }) => {
    const salaryAdvance = fromJS(payload)
    return state.merge(salaryAdvance)
  },
  [GET_SALARY_ADVANCE.FULFILLED]: (state, { payload }) => {
    const normalizedSalaryAdvance = normalize(payload.results, salaryAdvanceSchema)

    const lastSalaryAdvance = fromJS(
      [...payload.results]
        .sort()
        .reverse()
        .find(value => value.status === 'overdue' || value.status === 'problem_on_card') ||
        payload.results[0]
    )

    return state.merge(lastSalaryAdvance || new SalaryAdvance()).set(
      'salaryAdvances',
      fromJS({
        results: normalizedSalaryAdvance.entities.result,
        order: normalizedSalaryAdvance.result,
      })
    )
  },
  [PAY_NOW_SALARY_ADVANCE.REJECTED]: state => {
    return state.setIn(['card', 'valid'], false)
  },
  [PAY_NOW_SALARY_ADVANCE.FULFILLED]: (state, { payload }) => {
    const updateSalaryAdvance = fromJS(payload)

    const salaryAdvance =
      state
        .get('salaryAdvances')
        .get('results')
        .sort()
        .reverse()
        .find(
          value =>
            (value.status === 'overdue' || value.status === 'problem_on_card') &&
            value.id !== payload.id
        ) || fromJS(payload)

    const salaryAdvances = {
      results: state
        .setIn(
          ['salaryAdvances', 'results', String(updateSalaryAdvance.get('id'))],
          updateSalaryAdvance
        )
        .get('salaryAdvances')
        .get('results'),
      order: state.get('salaryAdvances').get('order'),
    }
    return state.merge(salaryAdvance).set('salaryAdvances', fromJS(salaryAdvances))
  },
  [GET_SALARY_ADVANCE_TRANSACTIONS.FULFILLED]: (state, { payload }) => {
    const normalizedTransactions = payload.reduce(
      (acc, curr) =>
        acc[curr.date]
          ? { ...acc, [curr.date]: [...acc[curr.date], curr] }
          : { ...acc, [curr.date]: [curr] },
      {}
    )
    const transactions = fromJS(normalizedTransactions)
    return state.set('transactions', transactions)
  },
  [UPDATE_CARD_SALARY_ADVANCE.FULFILLED]: (state, { meta }) => {
    return state.set('card', meta)
  },
  [GET_PROMISSORY_NOTE_URL_SA.FULFILLED]: (state, { payload }) => {
    return state.set('documentsPromissoryNote', payload)
  },
  [GET_TERMS_URL_SA.FULFILLED]: (state, { payload }) => {
    return state.set('documentsTermsUrl', payload)
  },
  [GET_PRIVACY_POLICY_URL_SA.FULFILLED]: (state, { payload }) => {
    return state.set('documentsPrivacyPolicyUrl', payload)
  },
})
