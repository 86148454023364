import { useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * Enable cookie bot on the website
 * @param {{domainGroupId: string, language?: string}} props - An object containing the
 * domain group ID and language for the Cookie Bot
 * @returns {void}
 * @constructor
 */
export const useCookieBot = ({ domainGroupId, language }) => {
  useEffect(() => {
    /* istanbul ignore next */
    if (typeof window === 'undefined') return
    if (!domainGroupId || !document) return
    if (document.getElementById('CookieBot')) return
    if (domainGroupId.length !== 36) {
      console.warn(
        `The cookie bot domain group id is ${domainGroupId.length} characters,
      instead it should be 36 characters!`
      ) // eslint-disable-line no-console
    }
    const script = document.createElement('script')
    script.setAttribute('id', 'CookieBot')
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
    script.setAttribute('data-cbid', domainGroupId)
    script.setAttribute('data-blockingmode', 'auto')
    script.setAttribute('type', 'text/javascript')
    if (language) {
      script.setAttribute('data-culture', language)
    }
    const head = document.querySelector('html > head')
    head.insertBefore(script, head.firstChild)
  }, [domainGroupId, language])
}
useCookieBot.defaultProps = {
  domainGroupId: undefined,
  language: undefined,
}

useCookieBot.propTypes = {
  /** Cookie bot domain group id */
  domainGroupId: PropTypes.string,
  /** Cookie bot language */
  language: PropTypes.string,
}
