import { defineAction } from '_utils/redux'
import * as loanDocuments from '_services/presigned-documents'

export const GET_PRESIGNED_DOCUMENTS = defineAction('GET_PRESIGNED_DOCUMENTS')

export const getPresignedDocuments = id => (dispatch, getState) =>
  dispatch({
    type: GET_PRESIGNED_DOCUMENTS.ACTION,
    payload: loanDocuments.getPresignedDocuments(getState().authentication.get('accessToken'))(id),
  })

export const getSAPresignedDocuments = id => (dispatch, getState) =>
  dispatch({
    type: GET_PRESIGNED_DOCUMENTS.ACTION,
    payload: loanDocuments.getSAPresignedDocuments(getState().authentication.get('accessToken'))(
      id
    ),
  })
