import { List, Map } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { statementsSchema } from '_models/statements'

import { GET_STATEMENTS } from './actions'

const INITIAL_STATE = Map({ results: Map(), order: List(), hasLoadedAll: false })

export default createReducer(INITIAL_STATE, {
  [GET_STATEMENTS.FULFILLED]: (state, { payload }) => {
    const normalizedState = normalize(payload.statements, statementsSchema)
    return state
      .mergeIn(['results'], normalizedState.entities.results)
      .mergeIn(['order'], normalizedState.result)
      .setIn(['hasLoadedAll'], payload.next === null)
  },
})
