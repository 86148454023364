import { fromJS } from 'immutable'

import { createReducer } from '_utils/redux'
import { Autopay } from '_models'

import { GET_CURRENT_AUTOPAY, GET_PAYMENT_INFO } from './actions'

const INITIAL_STATE = new Autopay()

export default createReducer(INITIAL_STATE, {
  [GET_PAYMENT_INFO.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS(payload))
  },
  [GET_CURRENT_AUTOPAY.FULFILLED]: (state, { payload }) => {
    return state.setIn(['currentAutopay'], fromJS(payload))
  },
})
