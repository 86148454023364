import React, { Fragment, useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, navigate } from '@reach/router'

import { getPresignedDocuments } from '_modules/presigned-documents/actions'
import { LOAN_DOCUMENTS_ID } from '_utils/constants'
import {
  getPresignedDocumentsSelector,
  isGetPresignedDocumentsLoadingSelector,
  getPresignedDocumentsErrorSelector,
} from '_modules/presigned-documents/selectors'
import PageLoader from '_components/page-loader'
import { getLoanSelector } from '_modules/loan/selectors'
import { useGetCron } from '_hooks/use-get-cron'
import { usePrevious } from '_hooks/use-previous'
import { getUserSelector } from '_modules/user/selectors'
import { getUser } from '_modules/user/actions'
import { possibleLoanIdSelector } from '_modules/cookie-handler/selectors'
import Button from '_components/button'
import DocumentPDF from '_components/document-pdf'

import styles from './styles.module.css'
import { ELETRONIC_HTML } from './documents'

// The presigned url expires in 15 minutes,
// so every 14 minutes get a new url
const INTERVAL = 840000

const LoanDocuments = () => {
  const dispatch = useDispatch()
  const presignedDocuments = useSelector(getPresignedDocumentsSelector)
  const loan = useSelector(getLoanSelector)
  const { state } = useLocation()
  const [loading, setLoading] = useState(true)
  const isGetPresignedDocumentsLoading = useSelector(isGetPresignedDocumentsLoadingSelector)
  const wasGetPresignedDocumentsLoading = usePrevious(isGetPresignedDocumentsLoading)
  const getPresignedDocumentsError = useSelector(getPresignedDocumentsErrorSelector)
  const possibleLoanId = useSelector(possibleLoanIdSelector)
  const user = useSelector(getUserSelector)

  const loanId = useMemo(() => {
    if (possibleLoanId) {
      return possibleLoanId
    }
    return state?.loanId || loan.id
  }, [loan.id, possibleLoanId, state?.loanId])

  useGetCron(getPresignedDocuments, INTERVAL, true, loanId)

  const onReturnClick = useCallback(() => {
    navigate(-1)
  }, [])

  useEffect(() => {
    dispatch(getUser())
  }, [dispatch])

  useEffect(() => {
    if (loanId) {
      dispatch(getPresignedDocuments(loanId))
    }
  }, [dispatch, loanId])

  useEffect(() => {
    if (
      wasGetPresignedDocumentsLoading &&
      !isGetPresignedDocumentsLoading &&
      (user.get('id') || !getPresignedDocumentsError.size)
    ) {
      setLoading(false)
    }
  }, [
    getPresignedDocumentsError.size,
    isGetPresignedDocumentsLoading,
    wasGetPresignedDocumentsLoading,
    user,
    dispatch,
  ])

  const documents = useMemo(
    () => [
      {
        label: 'Electronic disclosure, electronic signature and electronic statements agreement',
        html: ELETRONIC_HTML,
      },
      ...presignedDocuments.map(({ title, url }) => ({
        label: title,
        link: url,
      })),
    ],
    [presignedDocuments]
  )

  if (loading) {
    return <PageLoader />
  }

  return (
    <div className={styles.container}>
      <h1 className="visually-hidden">Loan terms documents</h1>
      <div className={styles.content} id={LOAN_DOCUMENTS_ID}>
        {documents.map(
          file =>
            (file?.link || file?.html || file?.externalLink) && (
              <Fragment key={file.label}>
                <h2 className="visually-hidden">{`Document: ${file.label}`}</h2>
                <DocumentPDF
                  label={file.label}
                  link={file.link}
                  htmlDocument={file.html}
                  className={styles.card}
                  externalLink={file.externalLink}
                />
              </Fragment>
            )
        )}
      </div>
      <Button className={styles.button} onClick={onReturnClick}>
        Return
      </Button>
    </div>
  )
}

export default LoanDocuments
