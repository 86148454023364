import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { cardSchema } from '_models'
import { PAY_NOW_SALARY_ADVANCE } from '_modules/salary-advance/actions'
import { INSUFFICIENT_FUNDS_ERROR } from '_utils/constants'

import { GET_CARDS, CHANGE_STATUS_ACTIVE_CARD, CREATE_CARD } from './actions'

const INITIAL_STATE = Map({ results: Map(), order: List() })

export default createReducer(INITIAL_STATE, {
  [GET_CARDS.FULFILLED]: (state, { payload }) => {
    const normalizedCards = normalize(payload, cardSchema)
    return Map({
      results: Map(normalizedCards.entities.result),
      order: List(normalizedCards.result),
    })
  },
  [PAY_NOW_SALARY_ADVANCE.REJECTED]: (state, { meta: { cardId }, payload: { error } }) => {
    return error !== INSUFFICIENT_FUNDS_ERROR
      ? state.setIn(['results', cardId, 'valid'], false)
      : state
  },
  [CHANGE_STATUS_ACTIVE_CARD.FULFILLED]: (state, { payload }) => {
    const normalizedCards = normalize(payload, cardSchema)
    return Map({
      results: Map(normalizedCards.entities.result),
      order: List(normalizedCards.result),
    })
  },
  // [CREATE_CARD.FULFILLED]: (state, { payload }) => {
  //   return state
  // },
})
