import { defineAction } from '_utils/redux'
import * as interchangeServices from '_services/interchange'

export const UPDATE_INTERCHANGE_INFO = defineAction('UPDATE_INTERCHANGE_INFO')
export const CLEAR_INTERCHANGE = defineAction('CLEAR_INTERCHANGE')
export const INTERCHANGE_PULL = defineAction('INTERCHANGE_PULL')
export const INTERCHANGE_PUSH = defineAction('INTERCHANGE_PUSH')
export const GET_BOOKMARKED_CARDS = defineAction('GET_BOOKMARKED_CARDS')
export const BOOKMARK_CARD = defineAction('BOOKMARK_CARD')
export const REMOVE_BOOKMARKED_CARD = defineAction('REMOVE_BOOKMARKED_CARD')

export const updateInterchangeInfo = payload => dispatch =>
  dispatch({
    type: UPDATE_INTERCHANGE_INFO,
    payload,
  })

export const clearInterchange = () => dispatch => dispatch({ type: CLEAR_INTERCHANGE })

export const interchangePull = payload => (dispatch, getState) =>
  dispatch({
    type: INTERCHANGE_PULL.ACTION,
    payload: interchangeServices.interchangePull(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const interchangePush = payload => (dispatch, getState) =>
  dispatch({
    type: INTERCHANGE_PUSH.ACTION,
    payload: interchangeServices.interchangePush(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const getBookmarkedCards = params => (dispatch, getState) =>
  dispatch({
    type: GET_BOOKMARKED_CARDS.ACTION,
    payload: interchangeServices.getBookmarkedCards(
      getState().authentication.get('accessToken'),
      params
    ),
  })

export const bookmarkCard = payload => (dispatch, getState) =>
  dispatch({
    type: BOOKMARK_CARD.ACTION,
    payload: interchangeServices.bookmarkCard(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const removeBookmarkedCard = payload => (dispatch, getState) =>
  dispatch({
    type: REMOVE_BOOKMARKED_CARD.ACTION,
    payload: interchangeServices.removeBookmarkedCard(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
