import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUserSelector } from '_modules/user/selectors'
import { getPublicAppSettings } from '_modules/app-settings/actions'
import { getChatbotSelector } from '_modules/app-settings/selectors'

import { intercomSetup, zendeskSetup } from './setups'

const SETTINGS_KEY = 'THIRD_PARTY_CHATBOT'

const ChatbotHOC = ({ children }) => {
  const user = useSelector(getUserSelector)
  const chatbot = useSelector(getChatbotSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPublicAppSettings(SETTINGS_KEY))
  }, [dispatch])

  useEffect(() => {
    if (chatbot) {
      switch (chatbot) {
        case 'zendesk':
          zendeskSetup()

          break
        case 'intercom':
          intercomSetup(user)
          break

        default:
          intercomSetup(user)
          break
      }
    }
  }, [chatbot, user])

  return children
}

export default ChatbotHOC
