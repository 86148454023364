import React from 'react'
import humps from 'humps'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import loadable from '@loadable/component'
import { timeout } from 'promise-timeout'

import PageLoader from '_components/page-loader'
import { CHUNK_TIMEOUT_INTERVAL } from '_utils/constants'
import AnimatedOpacity from '_styles/animations/AnimatedOpacity'

export const createFormData = (data, isCamelized = false) => {
  const formData = new FormData()

  Object.keys(data).forEach(field => {
    const fieldValue = data[field]
    const formDataValue = (() => {
      if (!fieldValue) {
        return ''
      }

      if (fieldValue instanceof Blob || typeof fieldValue !== 'object') {
        return fieldValue
      }

      return JSON.stringify(fieldValue)
    })()

    formData.append(isCamelized ? field : humps.decamelize(field), formDataValue)
  })
  return formData
}

export const deepClone = value => {
  return JSON.parse(JSON.stringify(value))
}

export const onMouseDown = event => event.preventDefault()

const DATE_FORMAT = 'MM/dd/yyyy'

export const parseFormatDate = value => format(parse(value, DATE_FORMAT, new Date()), 'yyyy-MM-dd')
export const parseISOFormatDate = value => format(new Date(parseISO(value)), DATE_FORMAT)
export const parseFormatBEDate = value =>
  value ? format(parse(value, 'yyyy-MM-dd', new Date()), DATE_FORMAT) : ''
export const parseFormatDateToWeekDay = value =>
  format(parse(value, 'yyyy-MM-dd', new Date()), 'EEE, MMM dd')

export const getKeyFromValue = (object, value) => {
  return Object.keys(object).filter(key => object[key] === value)[0]
}

export const snakeCaseToNormal = string => string?.replace('_', ' ')

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const valueToCurrency = value => currencyFormatter.format(value)

export const capitalizeWords = string =>
  string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

export const capitalize = string =>
  string && string[0].toUpperCase() + string.slice(1).toLowerCase()

export const convertToTitleCase = (string, separator) =>
  string
    .split(separator)
    .map(word => capitalize(word))
    .join(' ')

export const currencyToDecimal = currencyValue => {
  const addDecimalSeparator = number => {
    const decimalDigits = number.length === 1 ? `0${number}` : number.slice(-2)
    return `${number.slice(0, -2)}.${decimalDigits}`
  }

  let decimalValue = currencyValue.toString().replace('$', '')
  decimalValue = decimalValue.replace('.', '')
  decimalValue = decimalValue.replace(/,/g, '')
  decimalValue = addDecimalSeparator(decimalValue)
  decimalValue = parseFloat(decimalValue).toFixed(2)
  return decimalValue
}

export const valueToPercentage = value => `${(value * 100)?.toFixed(2)}%`

export const percentOf = (value, percent) => Math.round((value * percent) / 100)

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const loadableComponent = (dynamicImport, fallback = null) => {
  return loadable(() => timeout(dynamicImport(), CHUNK_TIMEOUT_INTERVAL), {
    fallback: fallback || (
      <AnimatedOpacity>
        <div style={{ height: '100vh' }}>
          <PageLoader />
        </div>
      </AnimatedOpacity>
    ),
  })
}

export const disableScroll = () => {
  document.body.style.overflow = 'hidden'
}

export const enableScroll = () => {
  document.body.style.overflow = 'visible'
}

export const hideChars = value => {
  const { length } = value
  const last4Chars = value.substring(length - 4, length)
  const charsToHide = value.substring(0, length - 4)
  const hiddenChars = charsToHide.replace(/\d/g, '*')
  return `${hiddenChars}${last4Chars}`
}
