import { Map } from 'immutable'

import { createReducer } from '_utils/redux'
import { CardPayment } from '_models'

import {
  SET_CARD_PAYMENT_AMOUNT,
  SET_CARD_PAYMENT_KEYS,
  CLEAR_CARD_PAYMENT_DATA,
  IS_SUCCESS_CONCLUDED_PAYMENT_DEBIT_CARD,
} from './actions'

const INITIAL_STATE = Map({
  cardPayment: new CardPayment(),
  isSuccessConcludedPayment: {
    active: false,
  },
})

export default createReducer(INITIAL_STATE, {
  [SET_CARD_PAYMENT_AMOUNT]: (state, { payload }) => {
    return state.mergeIn(['cardPayment'], payload)
  },
  [SET_CARD_PAYMENT_KEYS]: (state, { payload }) => {
    return state.mergeIn(['cardPayment'], payload)
  },
  [IS_SUCCESS_CONCLUDED_PAYMENT_DEBIT_CARD]: (state, { payload }) => {
    return state.mergeIn(['isSuccessConcludedPayment'], payload)
  },
  [CLEAR_CARD_PAYMENT_DATA]: () => {
    return INITIAL_STATE
  },
})
