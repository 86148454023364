import { Cookies } from 'react-cookie'
import format from 'date-fns/format'
import { fromJS } from 'immutable'

import { createReducer } from '_utils/redux'
import { User } from '_models'
import { POINT_SALARY, RESET_HAS_POSSIBLE_SALARY } from '_modules/salary-advance/actions'
import {
  GET_KYC_ADDRESS,
  SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS,
} from '_modules/checking-account/actions'

import {
  LOGIN,
  SOCIAL_LOGIN,
  GET_USER,
  UPDATE_USER,
  ONBOARDING,
  UPLOAD_DRIVER_LICENSE,
  ADDITIONAL_INFORMATION,
  CONFIRM_INFORMATION,
  SAVE_ORIGIN_URL,
  VERIFY_PHONE,
  ACCEPT_BIWEEKLY_ALLOTMENTS,
  GET_MAILING_ADDRESS,
  EDIT_MAILING_ADDRESS,
  GET_CONTACT_INFO,
  GET_REFERENCES,
  GET_REFERENCES_RELATION_LIST,
  SAVE_ALLOTMENT_HIDDEN,
} from './actions'

const cookies = new Cookies()

const INITIAL_STATE = new User()

export default createReducer(INITIAL_STATE, {
  [LOGIN.FULFILLED]: (_, { payload }) => {
    return new User(payload.user)
  },

  [SOCIAL_LOGIN.FULFILLED]: (state, { payload }) => {
    if (payload.user) {
      return new User(payload.user)
    }
    return state.set('nonClientSocialLoginResult', payload)
  },

  [GET_USER.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },

  [UPDATE_USER.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },

  [ONBOARDING.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },

  [UPLOAD_DRIVER_LICENSE.FULFILLED]: state => {
    return state.setIn(['hasDriverLicense'], true)
  },

  [ADDITIONAL_INFORMATION.FULFILLED]: (state, { payload }) => {
    return state.mergeDeep(payload)
  },

  [CONFIRM_INFORMATION.FULFILLED]: (state, { payload }) => {
    return state.mergeDeep(payload)
  },

  [SAVE_ORIGIN_URL]: (state, { payload }) => {
    cookies.set('originUrl', payload, { path: '/' })

    return state.setIn(['originUrl'], payload)
  },

  [VERIFY_PHONE.FULFILLED]: (state, { payload }) => {
    return state.set('pinCode', payload?.code).set('pinCodeRequestTimestamp', new Date().getTime())
  },

  [ACCEPT_BIWEEKLY_ALLOTMENTS.FULFILLED]: (state, { payload }) => {
    return state.set('acceptanceDate', format(new Date(), 'yyyy-MM-dd'))
  },
  [GET_MAILING_ADDRESS.FULFILLED]: (state, { payload }) => {
    return state.set('mailingAddress', payload?.mailingAddress)
  },
  [EDIT_MAILING_ADDRESS.FULFILLED]: (state, { payload }) => {
    return state.set('mailingAddress', payload?.mailingAddress)
  },
  [POINT_SALARY.FULFILLED]: state => {
    return state.set('pointedSalaryStatus', 'pending')
  },
  [RESET_HAS_POSSIBLE_SALARY]: state => {
    return state.set('hasPossibleSalary', false)
  },
  [GET_KYC_ADDRESS.FULFILLED]: (state, { payload }) => {
    return state.merge({
      address: payload?.address,
      useAsMailingAddress: payload?.useAsMailingAddress,
    })
  },
  [SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS.FULFILLED]: (state, { payload }) => {
    return state.set('useAsMailingAddress', payload.useAsMailingAddress)
  },
  [GET_CONTACT_INFO.FULFILLED]: (state, { payload }) => {
    if (payload) {
      return state.set('contactInfo', fromJS(payload))
    }
    return state.set(
      'contactInfo',
      fromJS({
        streetLine1: null,
        streetLine2: null,
        city: null,
        zipCode: null,
        stateCode: null,
        countryCode: null,
        email: null,
        mobileCountryCode: null,
        mobilePhoneNumber: null,
      })
    )
  },
  [GET_REFERENCES.FULFILLED]: (state, { payload }) => {
    return state.set('contactReferences', fromJS(payload))
  },
  [GET_REFERENCES_RELATION_LIST.FULFILLED]: (state, { payload }) => {
    return state.set('contactReferencesRelationList', fromJS(payload))
  },
  [SAVE_ALLOTMENT_HIDDEN]: (state, { payload }) => {
    cookies.set('isAllotmentInfoHidden', payload, { path: '/' })
    return state.set('isAllotmentInfoHidden', payload)
  },
})
