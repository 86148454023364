import { post } from './requests'

export const register = payload => post(['register'], { transformPayload: true }, payload)

export const refreshAccessToken = payload =>
  post(['token', 'refresh'], { transformPayload: true }, payload)

export const resetPassword = payload => post(['password', 'reset'], {}, payload)

export const changePassword = payload =>
  post(['password', 'reset', 'confirm'], { transformPayload: true }, payload)

export const getMaskedEmail = payload =>
  post(['email-from-tel'], { transformPayload: true }, payload)

export const sendEmailToSms = payload => post(['email-by-sms'], { transformPayload: true }, payload)
