import { defineAction } from '_utils/redux'
import * as checkingAccountService from '_services/checking-account'

export const GET_STATEMENTS = defineAction('GET_STATEMENTS')

export const getStatements = payload => (dispatch, getState) =>
  dispatch({
    type: GET_STATEMENTS.ACTION,
    payload: checkingAccountService.getStatements(getState().authentication.get('accessToken'), {
      ...payload,
    }),
  })
