import { List, Map } from 'immutable'

import {
  REGISTER,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  GET_MASKED_EMAIL,
  SEND_EMAIL_TO_SMS,
} from './actions'

export const authenticationAccessTokenSelector = state => state.authentication.get('accessToken')
export const authenticationRefreshTokenSelector = state => state.authentication.get('refreshToken')
export const isRegisterLoadingSelector = state => !!state.loading.get(REGISTER.ACTION)
export const getRegisterErrorSelector = state => state.error.get(REGISTER.ACTION, List())

export const isResetPasswordLoadingSelector = state => state.loading.get(RESET_PASSWORD.ACTION)
export const getResetPasswordErrorSelector = state => state.error.get(RESET_PASSWORD.ACTION, Map())

export const isChangePasswordLoadingSelector = state => state.loading.get(CHANGE_PASSWORD.ACTION)
export const getChangePasswordErrorSelector = state =>
  state.error.get(CHANGE_PASSWORD.ACTION, Map())

export const getMaskedEmailSelector = state => state.authentication.get('maskedEmail')
export const isGetMaskedEmailLoadingSelector = state => state.loading.get(GET_MASKED_EMAIL.ACTION)
export const getMaskedEmailErrorSelector = state => state.error.get(GET_MASKED_EMAIL.ACTION, Map())

export const isSendEmailToSmsLoadingSelector = state => state.loading.get(SEND_EMAIL_TO_SMS.ACTION)
export const sendEmailToSmsErrorSelector = state => state.error.get(SEND_EMAIL_TO_SMS.ACTION, Map())
