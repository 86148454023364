import { schema } from 'normalizr'

import Model from './model'

class SalaryAdvance extends Model({
  id: undefined,
  card: undefined,
  amount: undefined,
  paybackAmount: undefined,
  fee: undefined,
  paid: undefined,
  paymentSate: undefined,
  originalCollectionDate: undefined,
  signatureDate: undefined,
  fundingDate: undefined,
  status: undefined,
  paymentDate: undefined,
  salaryAdvances: undefined,
  salaryAccountInstitutionName: undefined,
  overdueSalaryAdvance: undefined,
  transactions: undefined,
  documentsTermsUrl: undefined,
  documentsPrivacyPolicyUrl: undefined,
  documentsPromissoryNote: undefined,
  collectFromCheckingAccount: undefined,
  collectionTransaction: undefined,
}) {}

export default SalaryAdvance

const salaryAdvanceNewSchema = new schema.Entity(
  'result',
  {},
  {
    processStrategy: entity => new SalaryAdvance(entity),
  }
)

export const salaryAdvanceSchema = [salaryAdvanceNewSchema]
