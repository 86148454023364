import { schema } from 'normalizr'

import Model from './model'

class Statements extends Model({
  type: undefined,
  startDate: undefined,
  endDate: undefined,
  statementLink: undefined,
  month: undefined,
  year: undefined,
}) {}

export default Statements

const statementsNewSchema = new schema.Entity(
  'results',
  {},
  {
    idAttribute: entity => `${entity.month}-${entity.year}`,
    processStrategy: entity => new Statements(entity),
  }
)

export const statementsSchema = [statementsNewSchema]
