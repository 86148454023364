import { createReducer } from '_utils/redux'
import { Referral } from '_models'

import { SAVE_REFERRAL_CODE, MY_REFERRALS } from './actions'

const INITIAL_STATE = new Referral()

const referral = createReducer(INITIAL_STATE, {
  [SAVE_REFERRAL_CODE]: (state, { payload }) => state.merge({ referralCode: payload }),
  [MY_REFERRALS.FULFILLED]: (state, { payload }) => {
    return state.merge(new Referral(payload))
  },
})
export default referral
