import { defineAction } from '_utils/redux'
import * as checkingAccountService from '_services/checking-account'

export const VALIDATED_PIN = defineAction('VALIDATED_PIN')
export const UNLOCK_CARD = defineAction('UNLOCK_CARD')

export const setValidatedPin = payload => dispatch =>
  dispatch({
    type: VALIDATED_PIN.ACTION,
    payload,
  })

export const sendUnlockCard = payload => (dispatch, getState) =>
  dispatch({
    type: UNLOCK_CARD.ACTION,
    payload: checkingAccountService.unlockCard(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
