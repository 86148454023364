import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { stateSchema } from '_models'

import { GET_STATES, GET_STATES_ALL } from './actions'

const INITIAL_STATE = Map({
  state: Map({ results: Map(), order: List() }),
  stateAll: Map({ results: Map(), order: List() }),
})

export default createReducer(INITIAL_STATE, {
  [GET_STATES.FULFILLED]: (state, { payload }) => {
    const normalizedState = normalize(payload, stateSchema)
    return state.set(
      'state',
      Map({
        results: Map(normalizedState.entities.result),
        order: List(normalizedState.result),
      })
    )
  },
  [GET_STATES_ALL.FULFILLED]: (state, { payload }) => {
    const normalizedStatesAll = normalize(payload, stateSchema)
    return state.set(
      'stateAll',
      Map({
        results: Map(normalizedStatesAll.entities.result),
        order: List(normalizedStatesAll.result),
      })
    )
  },
})
