import Breadcrumbs from '_models/breadcrumbs'
import { createReducer } from '_utils/redux'

import { SET_BREADCRUMBS, CLEAR_BREADCRUMBS } from './actions'

const INITIAL_STATE = new Breadcrumbs()

export default createReducer(INITIAL_STATE, {
  [SET_BREADCRUMBS.ACTION]: (state, { payload }) => {
    return state.set('breadcrumbs', payload)
  },

  [CLEAR_BREADCRUMBS.ACTION]: () => {
    return INITIAL_STATE
  },
})
