import { schema } from 'normalizr'

import Model from './model'

class Cards extends Model({
  id: undefined,
  brand: undefined,
  expiration: undefined,
  last4: undefined,
  isValid: undefined,
  lastUsed: undefined,
  isActive: undefined,
  createdAt: undefined,
}) {}

export default Cards

const cardNewSchema = new schema.Entity(
  'result',
  {},
  {
    processStrategy: entity => new Cards(entity),
  }
)

export const cardSchema = [cardNewSchema]
