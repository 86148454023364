import * as microdepositsService from '_services/microdeposits'
import { defineAction } from '_utils/redux'

export const CHECK_MICRODEPOSIT = defineAction('CHECK_MICRODEPOSIT')
export const SEND_MICRODEPOSIT = defineAction('SEND_MICRODEPOSIT')

export const checkMicrodeposit = () => (dispatch, getState) =>
  dispatch({
    type: CHECK_MICRODEPOSIT.ACTION,
    payload: microdepositsService.checkMicrodeposit(getState().authentication.get('accessToken')),
  })

export const sendMicrodeposit = payload => (dispatch, getState) =>
  dispatch({
    type: SEND_MICRODEPOSIT.ACTION,
    payload: microdepositsService.sendMicrodeposit(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
