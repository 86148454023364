import Model from './model'

class User extends Model({
  id: undefined,
  email: undefined,
  mobileCountryCode: undefined,
  mobilePhoneNumber: undefined,
  mailingAddress: undefined,
  verifiedPhone: undefined,
  twoFactorAuthentication: undefined,
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
  ssn: undefined,
  dateOfBirth: undefined,
  address: undefined,
  employer: undefined,
  eligibleCheckingAccount: false,
  state: undefined,
  bankAccountsStatus: undefined,
  blockDescription: undefined,
  loanBlock: undefined,
  driverLicense: undefined,
  hasDriverLicense: undefined,
  originUrl: undefined,
  hasConfirmedData: false,
  argyleToken: undefined,
  pinCode: undefined,
  pinCodeRequestTimestamp: undefined,
  acceptanceDate: undefined,
  isUsingSynapseFlow: undefined,
  hasOnboardingDone: undefined,
  hasSalaryAccount: undefined,
  hadSalaryOnLast14Days: undefined,
  lastTransactionsProcessed: undefined,
  linkItemId: undefined,
  needArgyleConnection: undefined,
  visibleArgyleEmployer: undefined,
  needArgyleScratch: undefined,
  pointedSalaryStatus: undefined,
  hasPossibleSalary: undefined,
  primaryAddressAsMailingAddress: false,
  nonClientSocialLoginResult: undefined,
  renegEnabled: false,
  isOnboardingFlow: undefined,
  accountNumberAllotment: undefined,
  routingNumberAllotment: undefined,
  blockedUser: undefined,
  oneclub: undefined,
  oneclubBithdayGiftCardEnabled: undefined,
  contactInfo: undefined,
  contactReferences: undefined,
  contactReferencesRelationList: undefined,
  hasOneblincSubscription: undefined,
  userConfig: undefined,
  isAllotmentInfoHidden: true,
}) {}

export default User
