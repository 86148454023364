import { decamelizeKeys } from 'humps'

import { get, post } from './requests'

export const saveArgyleUser = (key, payload) =>
  post(
    ['argyle-link'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const updateArgyleToken = key =>
  post(['argyle-link', 'update-token'], {
    key,
    transformPayload: true,
  })

export const saveArgyleEvents = (key, payload) =>
  post(['link-analytics'], { key, transformPayload: true }, payload)

export const createPartnerAccount = (key, id, payload) =>
  post(
    ['argyle-link', id, 'accounts'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const checkArgyleData = key =>
  get(['manage-argyle', 'check-data'], {
    key,
    transformPayload: true,
  })

export const checkArgyleConnection = key =>
  get(['manage-argyle', 'check-connection'], {
    key,
    transformPayload: true,
  })

export const getArgyleFlowId = (key, params) =>
  get(['argyle', 'get-flow-id'], {
    key,
    transformPayload: true,
    params,
  })
