import { Map } from 'immutable'

import { createReducer } from '_utils/redux'

import { CLEAR_PIN_VALIDATION, VALIDATE_PIN, RECOVER_PIN, CLEAR_PIN_RECOVER } from './actions'

const INITIAL_STATE = Map({
  validatedPin: false,
  error: false,
  recoveredPin: null,
})

export default createReducer(INITIAL_STATE, {
  [VALIDATE_PIN.PENDING]: state => {
    return state.set('error', false)
  },
  [VALIDATE_PIN.FULFILLED]: state => {
    return state.set('validatedPin', true).set('error', false)
  },
  [VALIDATE_PIN.REJECTED]: state => {
    return state.set('validatedPin', false).set('error', true)
  },
  [RECOVER_PIN.FULFILLED]: (state, { payload }) => {
    return state.set('recoveredPin', payload.pin)
  },
  [CLEAR_PIN_RECOVER]: state => {
    return state.set('recoveredPin', null)
  },
  [CLEAR_PIN_VALIDATION]: () => {
    return INITIAL_STATE
  },
})
