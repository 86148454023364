import { defineAction } from '_utils/redux'
import * as subscriptionService from '_services/subscription'

export const GET_PLANS = defineAction('GET_PLANS')
export const CREATE_SUBSCRIPTION = defineAction('CREATE_SUBSCRIPTION')
export const CREATE_INVOICE = defineAction('CREATE_INVOICE')
export const GET_INVOICE = defineAction('GET_INVOICE')

export const getPlans = () => (dispatch, getState) =>
  dispatch({
    type: GET_PLANS.ACTION,
    payload: subscriptionService.getPlans(getState().authentication.get('accessToken')),
  })

export const createSubscription = planId => (dispatch, getState) =>
  dispatch({
    type: CREATE_SUBSCRIPTION.ACTION,
    payload: subscriptionService.createSubscription(
      getState().authentication.get('accessToken'),
      planId
    ),
  })
export const createInvoice = subscriptionId => (dispatch, getState) =>
  dispatch({
    type: CREATE_INVOICE.ACTION,
    payload: subscriptionService.createInvoice(
      getState().authentication.get('accessToken'),
      subscriptionId
    ),
  })

export const getInvoice = invoiceId => (dispatch, getState) =>
  dispatch({
    type: GET_INVOICE.ACTION,
    payload: subscriptionService.getInvoice(
      getState().authentication.get('accessToken'),
      invoiceId
    ),
  })
