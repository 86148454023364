import { defineAction } from '_utils/redux'
import * as checkEligibleLoanDenyServices from '_services/blinc-advance'

export const GET_CHECK_ELIGIBLE_LOAN_DENY = defineAction('GET_CHECK_ELIGIBLE_LOAN_DENY')

export const getCheckEligibleLoanDeny = payload => (dispatch, getState) =>
  dispatch({
    type: GET_CHECK_ELIGIBLE_LOAN_DENY.ACTION,
    payload: checkEligibleLoanDenyServices.checkEligibleLoanDeny(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
