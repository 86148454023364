import ToolboxStore from '_models/toolbox'
import { createReducer } from '_utils/redux'

import { GET_TOOLBOX_SERVICES } from './actions'

const INITIAL_STATE = new ToolboxStore()

export default createReducer(INITIAL_STATE, {
  [GET_TOOLBOX_SERVICES.FULFILLED]: (state, { payload }) => {
    return state.set('toolboxServices', payload)
  },
})
