import { decamelizeKeys } from 'humps'

import { createFormData } from '_utils/helpers'

import { post, get } from './requests'

export const getPaymentRange = (key, params) =>
  get(['forbearance', 'range-pmt'], {
    key,
    transformPayload: true,
    params: decamelizeKeys(params),
  })

export const encryptCustomAllotment = (key, payload) =>
  post(['argyle', 'encrypt-custom-allotment'], { key, transformPayload: true }, payload)

export const startConnectionPayroll = (key, payload) =>
  post(['forbearance', 'start-connection-payroll'], { key, transformPayload: true }, payload)

export const connectionPayrollSuccess = (key, payload) =>
  post(['forbearance', 'connection-payroll-success'], { key, transformPayload: true }, payload)

export const uploadAllotmentConfirmation = (key, payload) => {
  return post(
    ['forbearance', 'upload-allotment-confirmation'],
    { key },
    createFormData(payload, false)
  )
}

export const autoPaySelected = (key, payload) =>
  post(['forbearance', 'auto-pay-selected'], { key, transformPayload: true }, payload)

export const autoPaySelectedConfirm = (key, payload) =>
  post(['forbearance', 'auto-pay-selected-confirm'], { key, transformPayload: true }, payload)
