import Model from './model'

class CashbackStore extends Model({
  cashbackStores: undefined,
  leadResult: undefined,
  leadToken: undefined,
  cashbackUser: undefined,
  enterWaitlist: undefined,
  redeemCashback: undefined,
  leadCardFormUrl: undefined,
  leadUserCards: undefined,
}) {}

export default CashbackStore
