import { get, post } from './requests'

export const getPlans = key => get(['subscriptions', 'membership'], { key, transformPayload: true })

export const createSubscription = (key, planId) =>
  post(['subscriptions', 'subscription', 'create-new-subscription'], {
    key,
    params: { plan: planId },
    transformPayload: true,
  })
export const createInvoice = (key, subscriptionId) =>
  post(['subscriptions', 'invoice', 'create-payment-link'], {
    key,
    params: { id: subscriptionId },
    transformPayload: true,
  })

export const getInvoice = (key, invoiceId) =>
  get(['subscriptions', 'invoice'], {
    key,
    params: { id: invoiceId },
    transformPayload: true,
  })
