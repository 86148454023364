import { LOGOUT } from '_modules/user/actions'
import { getOpenReplayTracker } from '_utils/open-replay'

const INVALID_TOKEN_ERRORS = [
  'Token is invalid or expired',
  'Given token not valid for any token type',
]

export default function errorMiddleware() {
  return next => action => {
    const result = next(action)

    if (INVALID_TOKEN_ERRORS.includes(result?.payload?.detail) && result?.error) {
      return next({
        type: LOGOUT,
        error: false,
      })
    }

    if (!(result instanceof Promise)) {
      return action
    }

    return result.catch(error => {
      if (!error.nonFieldErrors) {
        const errorMessage = error.detail || JSON.stringify(error)
        getOpenReplayTracker().handleError(new Error(`ERROR: ${errorMessage}`))
      }
    })
  }
}
