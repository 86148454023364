import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const Error = ({ resetErrorBoundary }) => (
  <div role="alert" className={styles.container}>
    <h1 className={styles.text}>Sorry, something went wrong</h1>
    <p className={styles.customer}>
      Please try again later.
      <br />
      if the problem persists,
      <br />
      please contact us.
    </p>
    <button type="button" onClick={resetErrorBoundary} className={styles.button}>
      Try again
    </button>
  </div>
)
Error.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
}

export default Error
