import { defineAction } from '_utils/redux'
import * as toolboxStoresService from '_services/toolbox'
import { TOOLBOX_API_KEY } from '_config/environment'

export const GET_TOOLBOX_SERVICES = defineAction('GET_TOOLBOX_SERVICES')

export const getToolboxServices = () => dispatch =>
  dispatch({
    type: GET_TOOLBOX_SERVICES.ACTION,
    payload: toolboxStoresService.getToolboxServices(TOOLBOX_API_KEY),
  })
