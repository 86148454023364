import { Map, List, fromJS } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { bankAccountSchema, BankAccount } from '_models'

import {
  GET_BANK_ACCOUNTS,
  GET_LINK_TOKEN,
  MANUAL_BANK_ACCOUNTS,
  UPDATE_MANUAL_BANK_ACCOUNT,
  UPLOAD_ACCESS_TOKEN,
  GET_BANK_ACCOUNTS_NEEDING_RECONNECTION,
  CLEAR_LINK_TOKEN,
  CHECK_REQUIRED_ACCOUNT_INFO,
  CREATE_LINK_TOKEN,
  CHECK_SALARY_ACCOUNT_STATUS,
  CLEAR_SALARY_ACCOUNT_STATUS,
} from './actions'

const savePlaidInfoForOAuthRedirect = linkToken => {
  localStorage.setItem('linkToken', linkToken)
  localStorage.setItem('plaidRedirectUrl', window.location.href)
}

const INITIAL_STATE = Map({
  accounts: Map({
    results: Map(),
    order: List(),
  }),
  accountsNeedingReconnection: Map({
    results: Map(),
    order: List(),
  }),
  linkToken: null,
  requiredAccountInfo: false,
  salaryAccountStatus: Map({
    hasSalaryAccount: false,
    hasProcessedTransactions: false,
    timeout: false,
  }),
})

export default createReducer(INITIAL_STATE, {
  [GET_BANK_ACCOUNTS.FULFILLED]: (state, { payload }) => {
    const normalizedBankAccounts = normalize(payload.results, bankAccountSchema)
    return state.mergeIn(
      ['accounts'],
      Map({
        results: Map(normalizedBankAccounts.entities.result),
        order: List(normalizedBankAccounts.result),
      })
    )
  },
  [GET_BANK_ACCOUNTS_NEEDING_RECONNECTION.FULFILLED]: (state, { payload }) => {
    const normalizedBankAccounts = normalize(payload, bankAccountSchema)
    return state.mergeIn(
      ['accountsNeedingReconnection'],
      Map({
        results: Map(normalizedBankAccounts.entities.result),
        order: List(normalizedBankAccounts.result),
      })
    )
  },
  [MANUAL_BANK_ACCOUNTS.FULFILLED]: (state, { payload }) => {
    const newOrder = state.getIn(['accounts', 'order']).unshift(payload.id)
    if (state.getIn(['accounts', 'results', String(payload.id)])) {
      return state.setIn(['accounts', 'results', String(payload.id)], new BankAccount(payload))
    }
    const newBankAccount = Map({ [payload.id]: new BankAccount(payload) })
    return state
      .mergeIn(['accounts', 'results'], newBankAccount)
      .setIn(['accounts', 'order'], newOrder)
  },
  [UPDATE_MANUAL_BANK_ACCOUNT.FULFILLED]: (state, { payload }) => {
    const newBankAccount = Map({ [payload.id]: new BankAccount(payload) })
    return state.mergeIn(['accounts', 'results'], newBankAccount)
  },
  [UPLOAD_ACCESS_TOKEN.ERROR]: (state, { payload }) => {
    return state.set('linkToken', payload.linkToken)
  },
  [UPLOAD_ACCESS_TOKEN.FULFILLED]: state => {
    return state.set('linkToken', null)
  },
  [CHECK_REQUIRED_ACCOUNT_INFO.FULFILLED]: (state, { payload }) => {
    return state.set('requiredAccountInfo', payload.requiredAccountInfo)
  },
  [GET_LINK_TOKEN.FULFILLED]: (state, { payload }) => {
    savePlaidInfoForOAuthRedirect(payload.linkToken)
    return state.set('linkToken', payload.linkToken)
  },
  [CREATE_LINK_TOKEN.FULFILLED]: (state, { payload }) => {
    savePlaidInfoForOAuthRedirect(payload.linkToken)
    return state.set('linkToken', payload.linkToken)
  },
  [CLEAR_LINK_TOKEN]: state => {
    return state.set('linkToken', null)
  },
  [CHECK_SALARY_ACCOUNT_STATUS.FULFILLED]: (state, { payload }) => {
    return state.set('salaryAccountStatus', fromJS(payload))
  },
  [CLEAR_SALARY_ACCOUNT_STATUS]: state => {
    return state.set('salaryAccountStatus', INITIAL_STATE.get('salaryAccountStatus'))
  },
})
