import { schema } from 'normalizr'

import Model from './model'

class Interchange extends Model({
  id: undefined,
  cardHolderName: undefined,
  cardNumber: undefined,
  expDate: undefined,
  amount: undefined,
  bookmarkedCards: undefined,
  nodeId: undefined,
  cardLast4: undefined,
  type: undefined,
}) {}

export default Interchange

const interchangeNewSchema = new schema.Entity(
  'results',
  {},
  {
    idAttribute: entity => entity.id,
  }
)

export const interchangeSchema = [interchangeNewSchema]
