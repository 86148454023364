import { defineAction } from '_utils/redux'
import * as bannerService from '_services/banner'

export const BANNER = defineAction('BANNER')

export const banner = payload => (dispatch, getState) =>
  dispatch({
    type: BANNER.ACTION,
    payload: bannerService.banner(getState().user.authToken, payload),
  })
