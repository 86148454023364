import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from '@reach/router'
import { Cookies } from 'react-cookie'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { config, useSpring, animated } from '@react-spring/web'
import { useWindowSize } from '@reach/window-size'
import OneBlincSVG from '_assets/svgs/one-blinc-logo.svg?react'

import { HEADER_ROUTES, USER_CASHBACK_ROUTES, LEAD_CASHBACK_ROUTES } from '_utils/routes'
import { getUserSelector, isUserLoggedInSelector } from '_modules/user/selectors'
import { LOGOUT } from '_modules/authentication/actions'
import { getCashbackUserSelector } from '_modules/cashback/selectors'
import { isTabletOrMobile } from '_utils/window'

import styles from './styles.module.css'

const cookies = new Cookies()

const CashbackNavBar = ({ className, onCloseMenu }) => {
  const { width } = useWindowSize()

  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const user = useSelector(getUserSelector)
  const cashbackUser = useSelector(getCashbackUserSelector)
  const isUserLoggedIn = useSelector(isUserLoggedInSelector)

  const [customerName, setCustomerName] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')

  const handleLogoutClick = useCallback(() => {
    if (isUserLoggedIn) {
      dispatch({ type: LOGOUT })
    } else {
      cookies.remove('cashbackLeadToken')
    }
    window.location.reload()
  }, [dispatch, isUserLoggedIn])

  const dashboardRoutes = useMemo(() => {
    if (isUserLoggedIn) {
      return USER_CASHBACK_ROUTES
    }

    return LEAD_CASHBACK_ROUTES
  }, [isUserLoggedIn])

  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -20rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  })
  const opacityAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.wobbly,
    delay: 180,
  })
  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    config: config.wobbly,
  })

  useEffect(() => {
    if (cashbackUser) {
      if (isUserLoggedIn) {
        setCustomerName(
          user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : null
        )
        setCustomerEmail(user?.email || null)
      } else {
        setCustomerName(cashbackUser?.lead[0].name)
        setCustomerEmail(cashbackUser?.lead[0]?.email)
      }
    }
  }, [cashbackUser, isUserLoggedIn, user])

  return (
    <div className={classnames(className, styles.container)}>
      <div className={styles.content}>
        <a href={HEADER_ROUTES.HOME.path} className={styles.logo} aria-label="Home">
          <OneBlincSVG className={styles['logo-icon']} />
        </a>
        <animated.div style={opacityAnimation} className={styles['user-actions']}>
          {customerName ? <p className={styles.text}>Hi, {customerName}</p> : null}
          {customerEmail ? <p className={styles.email}>{customerEmail}</p> : null}
        </animated.div>
        <animated.nav style={isTabletOrMobile(width) ? barAnimation : null} className={styles.nav}>
          <animated.ul
            style={isTabletOrMobile(width) ? linkAnimation : null}
            className={styles.list}
          >
            {Object.values(dashboardRoutes).map(({ text, path, external }) => (
              <li className={styles.item} key={path}>
                {external ? (
                  <a
                    href={path}
                    className={classnames(styles.link, { [styles.active]: pathname === path })}
                  >
                    {text}
                  </a>
                ) : (
                  <Link
                    to={path}
                    className={classnames(styles.link, { [styles.active]: pathname === path })}
                    onClick={onCloseMenu}
                  >
                    {text}
                  </Link>
                )}
              </li>
            ))}
          </animated.ul>
          <button onClick={handleLogoutClick} type="button" className={styles.button}>
            Logout
          </button>
        </animated.nav>
      </div>
    </div>
  )
}

CashbackNavBar.propTypes = {
  className: PropTypes.string,
  onCloseMenu: PropTypes.func,
}

CashbackNavBar.defaultProps = {
  className: '',
  onCloseMenu: () => {},
}

export default CashbackNavBar
