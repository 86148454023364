import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { refreshAccessToken } from '_modules/authentication/actions'
import { authenticationRefreshTokenSelector } from '_modules/authentication/selectors'

const INTERVAL_MINUTES = 10
const INTERVAL_MILLISECONDS = INTERVAL_MINUTES * 60000

export const useRefreshToken = () => {
  const dispatch = useDispatch()
  const refreshToken = useSelector(authenticationRefreshTokenSelector)

  const dispatchRefresh = useCallback(() => {
    if (refreshToken) {
      dispatch(refreshAccessToken())
    }
  }, [dispatch, refreshToken])

  useEffect(() => {
    let refreshInterval
    if (refreshToken) {
      dispatch(refreshAccessToken())
      refreshInterval = setInterval(dispatchRefresh, INTERVAL_MILLISECONDS)
    } else {
      clearInterval(refreshInterval)
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval)
      }
    }
  }, [dispatch, dispatchRefresh, refreshToken])
}
