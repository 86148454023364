import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CloseSVG from '_assets/svgs/close-icon.svg?react'

import AnimatedScale from '_styles/animations/AnimatedScale'
import Overlay from '_components/overlay'

import styles from './styles.module.css'

export const MODAL_SIZES = {
  LARGE: 'large',
  DEFAULT: 'default',
}

const ModalContent = ({
  title,
  children,
  onClose,
  size,
  nonClickableOverlay,
  disableCloseButton,
  noneMaxHeight,
}) => {
  return (
    <Overlay className={styles.modal} onClick={onClose} nonClickable={nonClickableOverlay}>
      <AnimatedScale elastic>
        <div className={classnames(styles['modal-content'], styles[size])}>
          {!disableCloseButton && (
            <button className={styles['close-button']} type="button" onClick={onClose}>
              Close
              <CloseSVG className={styles['close-icon']} />
            </button>
          )}
          {title && (
            <div className={styles['modal-header']}>
              <h2 className={styles.title}>{title}</h2>
            </div>
          )}
          <div
            className={classnames(styles['modal-body'], {
              [styles['has-no-title']]: !title,
              [styles['none-mh']]: noneMaxHeight,
            })}
          >
            {children}
          </div>
        </div>
      </AnimatedScale>
    </Overlay>
  )
}

const Modal = props => {
  return createPortal(<ModalContent {...props} />, document.getElementById('modal-root'))
}

ModalContent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  nonClickableOverlay: PropTypes.bool,
  disableCloseButton: PropTypes.bool,
  noneMaxHeight: PropTypes.bool,
}

ModalContent.defaultProps = {
  title: '',
  size: MODAL_SIZES.DEFAULT,
  nonClickableOverlay: false,
  disableCloseButton: false,
  noneMaxHeight: false,
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  nonClickableOverlay: PropTypes.bool,
  disableCloseButton: PropTypes.bool,
  noneMaxHeight: PropTypes.bool,
}

Modal.defaultProps = {
  title: '',
  size: MODAL_SIZES.DEFAULT,
  nonClickableOverlay: false,
  disableCloseButton: false,
  noneMaxHeight: false,
}

export default Modal
