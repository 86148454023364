import { Map } from 'immutable'

import {
  AGREE_BETA,
  AGREE_TERMS,
  CREATE_CARD_PIN,
  CREATE_CHECKING_ACCOUNT,
  CREATE_SHIPMENT,
  GET_ACCOUNT_LIMITS,
  GET_CHECKING_ACCOUNT,
  GET_KYC_ADDRESS,
  GET_KYC_STATUS,
  START_KYC,
  UPDATE_KYC_ADDRESS,
  UPDATE_KYC_DRIVERS_LICENCE,
  GET_VIRTUAL_CARD,
  UPDATE_CARD_STATUS,
  UPDATE_CARD_PIN,
  SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS,
  GET_SHIPMENT_STATUS,
  VALIDATE_MAILING_ADDRESS,
} from './actions'

export const isGetCheckingAccountLoadingSelector = state => {
  return state.loading.get(GET_CHECKING_ACCOUNT.ACTION)
}
export const getCheckingAccountErrorSelector = state =>
  state.error.get(GET_CHECKING_ACCOUNT.ACTION, Map())

export const getCheckingAccountSelector = state => state.checkingAccount
export const userHasCheckingAccountSelector = state =>
  state.checkingAccount.get('status') === 'active'
export const isCreateCheckingAccountLoadingSelector = state =>
  state.loading.get(CREATE_CHECKING_ACCOUNT.ACTION)
export const createCheckingAccountErrorSelector = state =>
  state.error.get(CREATE_CHECKING_ACCOUNT.ACTION, Map())

export const getCheckingAccountBankSelector = state => state.checkingAccount.get('bank')
export const getCheckingAccountBalanceSelector = state => state.checkingAccount.get('balance')
export const isCheckingAccountLockedSelector = state => state.checkingAccount.get('isLocked')

export const getCheckingAccountCurrentActivationStepSelector = state =>
  state.checkingAccount.get('currentActivationStep')
export const getCheckingAccountCardSubnetSelector = state => state.checkingAccount.get('cardSubnet')

export const getCheckingAccountPinSelector = state => state.checkingAccount.get('pin')
export const isCreateCardPinLoadingSelector = state => state.loading.get(CREATE_CARD_PIN.ACTION)
export const createCardPinErrorSelector = state => state.error.get(CREATE_CARD_PIN.ACTION, Map())

export const isAgreeTermsLoadingSelector = state => state.loading.get(AGREE_TERMS.ACTION)
export const agreeTermsErrorSelector = state => state.error.get(AGREE_TERMS.ACTION, Map())

export const isAgreeBetaLoadingSelector = state => state.loading.get(AGREE_BETA.ACTION)
export const agreeBetaErrorSelector = state => state.error.get(AGREE_BETA.ACTION, Map())

export const isCreateShipmentLoadingSelector = state => state.loading.get(CREATE_SHIPMENT.ACTION)
export const createShipmentErrorSelector = state => state.error.get(CREATE_SHIPMENT.ACTION, Map())

export const getAccountLimitsSelector = state => state.checkingAccount.get('card')
export const isGetAccountLimitsLoadingSelector = state =>
  state.loading.get(GET_ACCOUNT_LIMITS.ACTION)
export const getAccountLimitsErrorSelector = state =>
  state.error.get(GET_ACCOUNT_LIMITS.ACTION, Map())

export const getKYCStatusSelector = state => state.checkingAccount.get('kycStatus')
export const isGetKYCStatusLoadingSelector = state => state.loading.get(GET_KYC_STATUS.ACTION)
export const getKYCStatusErrorSelector = state => state.error.get(GET_KYC_STATUS.ACTION, Map())

export const isStartKYCLoadingSelector = state => state.loading.get(START_KYC.ACTION)
export const startKYCErrorSelector = state => state.error.get(START_KYC.ACTION, Map())

export const isUpdateKYCDriversLicenseLoadingSelector = state =>
  state.loading.get(UPDATE_KYC_DRIVERS_LICENCE.ACTION)
export const updateKYCDriversLicenseErrorSelector = state =>
  state.error.get(UPDATE_KYC_DRIVERS_LICENCE.ACTION, Map())

export const isUpdateKYCAddressLoadingSelector = state =>
  state.loading.get(UPDATE_KYC_ADDRESS.ACTION)
export const updateKYCAddressErrorSelector = state =>
  state.error.get(UPDATE_KYC_ADDRESS.ACTION, Map())

export const isGetKYCAddressLoadingSelector = state => state.loading.get(GET_KYC_ADDRESS.ACTION)
export const getKYCAddressErrorSelector = state => state.error.get(GET_KYC_ADDRESS.ACTION, Map())

export const isGetVirtualCardLoadingSelector = state => state.loading.get(GET_VIRTUAL_CARD.ACTION)
export const getVirtualCardErrorSelector = state => state.error.get(GET_VIRTUAL_CARD.ACTION, Map())

export const isUpdateCardStatusLoadingSelector = state =>
  state.loading.get(UPDATE_CARD_STATUS.ACTION)
export const updateCardStatusErrorSelector = state =>
  state.error.get(UPDATE_CARD_STATUS.ACTION, Map())

export const isUpdateCardPinLoadingSelector = state => state.loading.get(UPDATE_CARD_PIN.ACTION)
export const updateCardPinErrorSelector = state => state.error.get(UPDATE_CARD_PIN.ACTION, Map())

export const isSetPrimaryAddressAsMailingAddressLoadingSelector = state =>
  state.loading.get(SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS.ACTION)
export const setPrimaryAddressAsMailingAddressErrorSelector = state =>
  state.error.get(SET_PRIMARY_ADDRESS_AS_MAILING_ADDRESS.ACTION, Map())

export const getShipmentStatusSelector = state => state.checkingAccount.get('shipmentStatus')
export const isGetShipmentStatusLoadingSelector = state =>
  state.loading.get(GET_SHIPMENT_STATUS.ACTION)

export const isValidateMailingAddressLoadingSelector = state =>
  state.loading.get(VALIDATE_MAILING_ADDRESS.ACTION)
export const validateMailingAddressErrorSelector = state =>
  state.error.get(VALIDATE_MAILING_ADDRESS.ACTION, Map())
