import { fromJS, Map } from 'immutable'
import { Cookies } from 'react-cookie'

import { createReducer } from '_utils/redux'

import {
  STORE_ADS_INFO,
  TOGGLE_EVENTS_BANNER,
  POSSIBLE_LOAN_ID,
  SEEN_NEW_NAV_BAR_HIGHLIGHT,
} from './actions'

const cookies = new Cookies()

const ONE_DAY = 60 * 60 * 24
const ONE_WEEK = ONE_DAY * 7

const INITIAL_STATE = Map({
  hasSeenNewNavBarHighlight: cookies.get('hasSeenNewNavBarHighlight'),
  isEventBannerHidden: cookies.get('bannerHidden') === 'true',
  adsTrackingInfo: new Map({
    gclic: localStorage.getItem('gclic'),
    fbc: cookies.get('_fbc') || null,
    fbp: cookies.get('_fbp') || null,
  }),
  possibleLoanId: cookies.get('possibleLoanId') || null,
})

export default createReducer(INITIAL_STATE, {
  [TOGGLE_EVENTS_BANNER]: state => {
    state.isEventBannerHidden
      ? cookies.remove('bannerHidden', { path: '/' })
      : cookies.set('bannerHidden', true, { path: '/', maxAge: ONE_WEEK })

    return state.merge({ isEventBannerHidden: !state.isEventBannerHidden })
  },

  [STORE_ADS_INFO]: (state, { payload }) => {
    const { gclic, fbc, fbp } = payload

    const data = {
      gclic: state.get('adsTrackingInfo').get('gclic'),
      fbc: state.get('adsTrackingInfo').get('fbc'),
      fbp: state.get('adsTrackingInfo').get('fbp'),
    }

    if (state.get('adsTrackingInfo').get('gclic') === null && !!gclic) {
      data.gclic = gclic
    }
    if (state.get('adsTrackingInfo').get('fbc') === null && !!fbc) {
      data.fbc = fbc
    }
    if (state.get('adsTrackingInfo').get('fbp') === null && !!fbp) {
      data.fbp = fbp
    }

    return state.mergeIn(['adsTrackingInfo'], fromJS(data))
  },

  [POSSIBLE_LOAN_ID]: (state, { payload }) => {
    if (payload.remove) {
      cookies.remove('possibleLoanId')
      return state.merge({ possibleLoanId: null })
    }
    cookies.set('possibleLoanId', payload.possibleLoanId, {
      path: '/',
    })
    return state.merge({ possibleLoanId: payload.possibleLoanId })
  },

  [SEEN_NEW_NAV_BAR_HIGHLIGHT]: state => {
    cookies.set('hasSeenNewNavBarHighlight', true)
    return state.merge({ hasSeenNewNavBarHighlight: true })
  },
})
