import Renegotiation from '_models/renegotiation'
import { createReducer } from '_utils/redux'

import {
  GET_RENEG_AMOUNTS,
  RECALCULATE_RENEG,
  CREATE_RENEG,
  ACCEPT_RENEG,
  HANDLE_SHOULD_GO_TO_NEXT_STEP,
  GET_RENEGOTIATION_AMOUNT,
} from './actions'

const INITIAL_STATE = new Renegotiation()

export default createReducer(INITIAL_STATE, {
  [GET_RENEG_AMOUNTS.FULFILLED]: (state, { payload }) => {
    return state.set('renegAmounts', payload)
  },
  [RECALCULATE_RENEG.FULFILLED]: (state, { payload }) => {
    return state.set('recalculateReneg', payload)
  },
  [CREATE_RENEG.FULFILLED]: (state, { payload }) => {
    return state.set('createReneg', payload)
  },
  [ACCEPT_RENEG.FULFILLED]: (state, { payload }) => {
    return state.set('acceptReneg', payload)
  },
  [HANDLE_SHOULD_GO_TO_NEXT_STEP.ACTION]: (state, { payload }) => {
    return state.set('goToNextStep', payload)
  },
  [GET_RENEGOTIATION_AMOUNT.ACTION]: (state, { payload }) => {
    return state.set('renegAmount', payload)
  },
})
