import { get, post } from './requests'

export const getQuiz = key => get(['growth', 'quiz'], { key, transformPayload: true })

export const sendQuestionResponse = (key, payload) =>
  post(['growth', 'quiz-response'], { key, transformPayload: true }, payload)

export const finishQuiz = (key, payload) =>
  post(['growth', 'quiz'], { key, transformPayload: true }, payload)

export const trackingUser = (key, payload) =>
  post(['cashback', 'tracking-cashback'], { key, transformPayload: true }, payload)
