import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import MailCheck, { mailCheckSchema } from '_models/mail-check'
import { createReducer } from '_utils/redux'

import {
  CLEAR_MAIL_CHECK,
  GET_BOOKMARKED_RECIPIENTS,
  UPDATE_MAIL_CHECK_INFO,
  MAIL_CHECK,
} from './actions'

const INITIAL_STATE = Map({
  mailCheck: new MailCheck(),
  isNewRecipient: true,
  transferredRecipientId: null,
  isTransferredRecipientBookmarked: false,
  bookmarkedRecipients: Map({ results: Map(), order: List() }),
})

export default createReducer(INITIAL_STATE, {
  [UPDATE_MAIL_CHECK_INFO.ACTION]: (state, { payload }) => {
    return state.mergeIn(['mailCheck'], payload)
  },
  [GET_BOOKMARKED_RECIPIENTS.FULFILLED]: (state, { payload }) => {
    const normalizedState = normalize(payload, mailCheckSchema)
    return state.set(
      'bookmarkedRecipients',
      Map({
        results: Map(normalizedState.entities.results),
        order: List(normalizedState.result),
      })
    )
  },
  [MAIL_CHECK.FULFILLED]: (state, { payload }) => {
    return state
      .set('transferredRecipientId', payload.node.id)
      .set('isTransferredRecipientBookmarked', payload.node.bookmarked)
  },
  [CLEAR_MAIL_CHECK]: () => {
    return INITIAL_STATE
  },
})
