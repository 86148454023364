import { Map } from 'immutable'
import { createSelector } from 'reselect'

import {
  LOGIN,
  SOCIAL_LOGIN,
  ONBOARDING,
  GET_USER,
  UPDATE_USER,
  VERIFY_PHONE,
  VERIFY_CODE,
  SUBSCRIBE_NEWSLETTER_INTEREST,
  UPLOAD_DRIVER_LICENSE,
  ADDITIONAL_INFORMATION,
  CONFIRM_INFORMATION,
  ACCEPT_BIWEEKLY_ALLOTMENTS,
  CONFIRM_MAILING_ADDRESS,
  GET_MAILING_ADDRESS,
  EDIT_MAILING_ADDRESS,
  CONFIRM_PASSWORD,
} from './actions'

export const isLoginLoadingSelector = state => !!state.loading.get(LOGIN.ACTION)
export const loginErrorSelector = state => state.error.get(LOGIN.ACTION, Map())

export const nonClientSocialLoginResultSelector = state =>
  state.user.get('nonClientSocialLoginResult')
export const isSocialLoginLoadingSelector = state => !!state.loading.get(SOCIAL_LOGIN.ACTION)
export const socialLoginErrorSelector = state => state.error.get(SOCIAL_LOGIN.ACTION, Map())

export const isUserLoggedInSelector = state => !!state.authentication.get('accessToken')

export const isOnboardingLoadingSelector = state => !!state.loading.get(ONBOARDING.ACTION)
export const getOnboardingErrorSelector = state => state.error.get(ONBOARDING.ACTION, Map())

export const isGetUserLoadingSelector = state => state.loading.get(GET_USER.ACTION)
export const getUserErrorSelector = state => state.error.get(GET_USER.ACTION, Map())
export const getUserSelector = state => state.user
export const getUserStateSelector = state => state.user.get('state')
export const getUserMobileNumberSelector = createSelector(
  getUserSelector,
  user =>
    user.get('mobilePhoneNumber') &&
    `(${user.get('mobilePhoneNumber').substring(0, 3)}) ${user
      .get('mobilePhoneNumber')
      .substr(3, 3)}-${user.get('mobilePhoneNumber').substr(6)}`
)
export const getUserSocialSecurityNumberSelector = state => state.user.get('ssn')
export const getAccountNumberAllotmentSelector = state => state.user.get('accountNumberAllotment')
export const getRoutingNumberAllotmentSelector = state => state.user.get('routingNumberAllotment')
export const getUserLoanBlockSelector = state => state.user.get('loanBlock')
export const getUserVerifiedPhoneSelector = state => state.user.get('verifiedPhone')
export const getUserIsUsingSynapseFlow = state => state.user.get('isUsingSynapseFlow')
export const getUserHasConfirmedDataSelector = state => state.user.get('hasConfirmedData')
export const getUserHasDriverLicenseSelector = state => state.user.get('hasDriverLicense')
export const getUserHasConnectedEmployerSelector = state => state.user.get('linkItemId')
export const getUserHasSalaryAccount = state => state.user.get('hasSalaryAccount')
export const getUserHadSalaryOnLast14Days = state => state.user.get('hadSalaryOnLast14Days')
export const getUserIdSelector = state => state.user.get('id')
export const getUserIsOnNewOnboardingSelector = state => state.user.get('isOnboardingFlow')
export const getPointedSalaryStatusSelector = state => state.user.get('pointedSalaryStatus')
export const getHasPossibleSalarySelector = state => state.user.get('hasPossibleSalary')

export const isUpdateUserLoadingSelector = state => state.loading.get(UPDATE_USER.ACTION)
export const updateUserErrorSelector = state => state.error.get(UPDATE_USER.ACTION, Map())

export const isVerifyPhoneLoadingSelector = state => state.loading.get(VERIFY_PHONE.ACTION)
export const verifyPhoneErrorSelector = state => state.error.get(VERIFY_PHONE.ACTION, Map())
export const getCodeSelector = state => state.user.get('pinCode')
export const getCodeRequestTimestampSelector = state => state.user.get('pinCodeRequestTimestamp')

export const isVerifyCodeLoadingSelector = state => state.loading.get(VERIFY_CODE.ACTION)
export const verifyCodeErrorSelector = state => state.error.get(VERIFY_CODE.ACTION, Map())

export const isSubscribingSelector = state =>
  state.loading.get(SUBSCRIBE_NEWSLETTER_INTEREST.ACTION)
export const verifySubscribeErrorSelector = state =>
  state.error.get(SUBSCRIBE_NEWSLETTER_INTEREST.ACTION, Map())

export const isUploadDriverLicenseLoadingSelector = state =>
  !!state.loading.get(UPLOAD_DRIVER_LICENSE.ACTION)
export const uploadDriverLicenseErrorSelector = state =>
  state.error.get(UPLOAD_DRIVER_LICENSE.ACTION, Map())

export const isAdditionalInformationLoadingSelector = state =>
  !!state.loading.get(ADDITIONAL_INFORMATION.ACTION)
export const additionalInformationErrorSelector = state =>
  state.error.get(ADDITIONAL_INFORMATION.ACTION, Map())

export const isConfirmInformationLoadingSelector = state =>
  !!state.loading.get(CONFIRM_INFORMATION.ACTION)
export const confirmInformationErrorSelector = state =>
  state.error.get(CONFIRM_INFORMATION.ACTION, Map())

export const visibleArgile = state => state.user.get('visibleArgyleEmployer')

export const needArgyle = state => state.user.get('needArgyleScratch')

export const isAcceptBiweeklyLoadingSelector = state =>
  !!state.loading.get(ACCEPT_BIWEEKLY_ALLOTMENTS.ACTION)
export const getAcceptBiweeklyErrorSelector = state =>
  state.error.get(ACCEPT_BIWEEKLY_ALLOTMENTS.ACTION, Map())
export const getAcceptanceDateSelector = state => state.user.get('acceptanceDate')

export const getMailingAddressSelector = state => state.user.get('mailingAddress')
export const isGetMailingAddressLoadingSelector = state =>
  !!state.loading.get(GET_MAILING_ADDRESS.ACTION)
export const getMailingAddressErrorSelector = state =>
  state.error.get(CONFIRM_MAILING_ADDRESS.ACTION, Map())

export const confirmMailingAddressErrorSelector = state =>
  state.error.get(CONFIRM_MAILING_ADDRESS.ACTION, Map())
export const isConfirmMailingAddressLoadingSelector = state =>
  !!state.loading.get(CONFIRM_MAILING_ADDRESS.ACTION)

export const editMailingAddressErrorSelector = state =>
  state.error.get(EDIT_MAILING_ADDRESS.ACTION, Map())
export const isEditMailingAddressLoadingSelector = state =>
  !!state.loading.get(EDIT_MAILING_ADDRESS.ACTION)

export const getUserNeedArgyleConnectionSelector = state => state.user.get('needArgyleConnection')

export const isConfirmPasswordLoadingSelector = state =>
  !!state.loading.get(CONFIRM_PASSWORD.ACTION)
export const confirmPasswordErrorSelector = state => state.error.get(CONFIRM_PASSWORD.ACTION, Map())

export const getIsBlockedUserSelector = state => state.user.get('blockedUser')

export const isOneClubMemberSelector = state => state.user.get('oneclub')

export const isOneclubBithdayGiftCardEnabledSelector = state =>
  state.user.get('oneclubBithdayGiftCardEnabled')
export const getContactInfoSelector = state => state.user.get('contactInfo')
export const getContactReferencesRelationListSelector = state =>
  state.user.get('contactReferencesRelationList')
export const getContactReferencesSelector = state => {
  const contactReferences = state.user.get('contactReferences')
  const relationList = state.user.get('contactReferencesRelationList')?.toJS()
  if (contactReferences?.size > 0 && relationList) {
    return {
      name1: contactReferences?.get(0)?.get('name') ?? '',
      phone1: contactReferences?.get(0)?.get('mobilePhoneNumber') ?? '',
      relation1:
        relationList.find(value => value.id === contactReferences?.get(0)?.get('relation'))
          ?.description ?? '',
      name2: contactReferences?.get(1)?.get('name') ?? '',
      phone2: contactReferences?.get(1)?.get('mobilePhoneNumber') ?? '',
      relation2:
        relationList.find(value => value.id === contactReferences?.get(1)?.get('relation'))
          ?.description ?? '',
    }
  }
  return {
    name1: '',
    phone1: '',
    relation1: '',
    name2: '',
    phone2: '',
    relation2: '',
  }
}
export const isUserSubscribedSelector = state => state.user.get('hasOneblincSubscription')
export const getArgyleLinkItemIdSelector = state => state.user.get('linkItemId', null)

export const isAllotmentInfoHiddenSelector = state => state.user.get('isAllotmentInfoHidden', false)
export const needVerifyPhoneSelector = state =>
  state.user.getIn(['userConfig', 'needToVerifyPhone'], false)
export const getUserConfigGroupsSelector = state =>
  state.user.getIn(['userConfig', 'groups'], false)
