import { Map } from 'immutable'

import { GET_PRESIGNED_DOCUMENTS } from './actions'

export const isGetPresignedDocumentsLoadingSelector = state =>
  state.loading.get(GET_PRESIGNED_DOCUMENTS.ACTION)
export const getPresignedDocumentsErrorSelector = state =>
  state.error.get(GET_PRESIGNED_DOCUMENTS.ACTION, Map())

export const getPresignedDocumentsSelector = state => state.presignedDocuments
