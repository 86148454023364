import { schema } from 'normalizr'

import Model from './model'

class Version extends Model({
  clearCache: undefined,
  clientVersion: undefined,
  currentVersion: undefined,
  project: undefined,
  userId: undefined,
}) {}

export default Version

const versionNewSchema = new schema.Entity(
  'result',
  {},
  {
    processStrategy: entity => new Version(entity),
  }
)

export const versionSchema = [versionNewSchema]
