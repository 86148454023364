import * as employer from '_services/employer'
import { defineAction } from '_utils/redux'

export const GET_EMPLOYERS = defineAction('GET_EMPLOYERS')

export const getEmployers = () => (dispatch, getState) =>
  dispatch({
    type: GET_EMPLOYERS.ACTION,
    payload: employer.getEmployers(getState().authentication.get('accessToken')),
  })
