import { List, Map } from 'immutable'
import { normalize } from 'normalizr'

import { transactionSchema } from '_models/transaction'
import { createReducer } from '_utils/redux'

import {
  GET_TRANSACTIONS,
  GET_TRANSACTION_DETAILS,
  CANCEL_TRANSACTION,
  LOAD_MORE_TRANSACTIONS,
} from './actions'

const INITIAL_STATE = Map({ results: Map(), order: List(), hasLoadedAll: false })

export default createReducer(INITIAL_STATE, {
  [GET_TRANSACTIONS.FULFILLED]: (_state, { payload }) => {
    const normalizedState = normalize(payload.results, transactionSchema)
    return Map({
      results: Map(normalizedState.entities.results),
      order: List(normalizedState.result),
      hasLoadedAll: payload.next === null,
    })
  },
  [GET_TRANSACTION_DETAILS.FULFILLED]: (state, { payload }) => {
    // TODO: uncomment when date to process is available on transactions details endpoint
    // const data = {
    //   ...payload,
    //   status:
    //     payload.dateToProcess.split('T')[0] !== payload.date.split('T')[0] &&
    //     payload.status === 'CREATED'
    //       ? 'SCHEDULED'
    //       : payload.status,
    // }
    return state.set('transactionDetails', payload)
  },
  [CANCEL_TRANSACTION.FULFILLED]: (state, { payload }) => {
    return state.set('cancelTransactionResult', payload)
  },
  [LOAD_MORE_TRANSACTIONS.FULFILLED]: (state, { payload }) => {
    const normalizedState = normalize(payload.results, transactionSchema)
    const newOrders = List(normalizedState.result)
    const newResults = Map(normalizedState.entities.results)
    return state
      .mergeIn(['results'], newResults)
      .setIn(['order'], newOrders)
      .setIn(['hasLoadedAll'], payload.next === null)
  },
})
