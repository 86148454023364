import { fromJS } from 'immutable'

import { createReducer } from '_utils/redux'
import Forbearance from '_models/forbearance'

import {
  GET_PAYMENT_RANGE,
  ENCRYPT_CUSTOM_ALLOTMENT,
  START_CONNECTION_PAYROLL,
  CONNECTION_PAYROLL_SUCCESS,
  UPDATE_FORBEARANCE,
  AUTO_PAY_SELECTED,
} from './actions'

const INITIAL_STATE = new Forbearance()

export default createReducer(INITIAL_STATE, {
  [GET_PAYMENT_RANGE.FULFILLED]: (state, { payload }) => {
    return state.mergeIn(['flow'], fromJS(payload))
  },

  [ENCRYPT_CUSTOM_ALLOTMENT.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS(payload))
  },
  [UPDATE_FORBEARANCE]: (state, { payload }) => {
    return state.mergeIn(['forbearance'], fromJS(payload))
  },
  [START_CONNECTION_PAYROLL.FULFILLED]: (state, { payload }) => {
    return state.mergeIn(['forbearance'], fromJS(payload))
  },
  [CONNECTION_PAYROLL_SUCCESS.FULFILLED]: (state, { payload }) => {
    return state.mergeIn(['forbearance'], fromJS(payload))
  },
  [AUTO_PAY_SELECTED.FULFILLED]: (state, { payload }) => {
    return state.mergeIn(['forbearance'], fromJS(payload))
  },
})
