import { createReducer } from '_utils/redux'
import { StatementDocument } from '_models'

import { GET_STATEMENT_DOCUMENTS } from './actions'

const INITIAL_STATE = new StatementDocument()

const statementDocuments = createReducer(INITIAL_STATE, {
  [GET_STATEMENT_DOCUMENTS.PENDING]: () => {
    return INITIAL_STATE
  },
  [GET_STATEMENT_DOCUMENTS.FULFILLED]: (state, { payload }) => {
    return state.set('documents', payload)
  },
})

export default statementDocuments
