import React from 'react'

import { API_URL } from '_config/environment'

export const US_STATES_LONG_TO_SHORT = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  'Armed Forces Americas': 'AA',
  'Armed Forces Europe': 'AE',
  'Armed Forces Pacific': 'AP',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'NP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  'US Virgin Islands': 'VI',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
}

export const US_STATES_SHORT_TO_LONG = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  AA: 'Armed Forces Americas',
  AE: 'Armed Forces Europe',
  AP: 'Armed Forces Pacific',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  VI: 'US Virgin Islands',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const TRUSTPILOT_PROPS = {
  className: 'trustpilot-widget',
  'data-locale': 'en-US',
  'data-businessunit-id': '5c924b2c6357cf000139bb86',
  'data-style-width': '100%',
  'data-theme': 'light',
  'data-stars': '5',
  'data-review-languages': 'en',
}

export const TRUSTPILOT_TEMPLATE_ID_HOME = '54ad5defc6454f065c28af8b'
export const TRUSTPILOT_TEMPLATE_ID_ONE = '53aa8807dec7e10d38f59f32'
export const TRUSTPILOT_TEMPLATE_ID_TWO = '539adbd6dec7e10e686debee'

export const LOAN_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  EMPTY: 'empty',
  DENIED: 'denied',
  EXPIRED: 'expired',
  PENDING_ALLOTMENT: 'pending_allotment',
  PENDING_DOCUMENTS: 'pending_documents',
  STARTED: 'started',
  MANUAL_APPROVAL: 'manual_approval',
  INAPT: 'inapt',
  RELEASED: 'released',
  SETTLED: 'settled',
  REFINANCE_DOCUMENT_PENDING: 'refinance_doc_pending',
  REFINANCE_DISBURSEMENT_PENDING: 'refinance_dis_pending',
  SOLD: 'sold',
}

export const DOCUMENT_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  EMPTY: 'empty',
  DENIED: 'denied',
  FAILED_VERIFICATION: 'failed-verification',
}

export const DOCUMENT_STATUS_TO_LABEL = {
  [DOCUMENT_STATUS.APPROVED]: 'Approved',
  [DOCUMENT_STATUS.DENIED]: 'Denied',
  [DOCUMENT_STATUS.PENDING]: 'In review',
  [DOCUMENT_STATUS.FAILED_VERIFICATION]: 'Failed verification',
}

export const DENY_REASONS = { OTHER: 'other' }

export const DOCUMENTS_CATEGORIES = {
  SSN: 'ssn',
  ALLOTMENT: 'allotment',
  PAY_STUB: 'payStub',
  DRIVERS_LICENSE: 'driversLicense',
  DRIVER_LICENSE_BACK: 'driversLicenseBack',
  BANK_STATEMENT: 'bankStatement',
  OTHERS: 'others',
  PAYROLL_CONNECTION: 'payrollConnection',
  SALARY_CARD: 'salaryCard',
}

export const DOCUMENT_CATEGORIES_LABEL = {
  [DOCUMENTS_CATEGORIES.SSN]: 'SSN Card',
  [DOCUMENTS_CATEGORIES.PAY_STUB]: 'Pay Stub (most recent)',
  [DOCUMENTS_CATEGORIES.DRIVERS_LICENSE]: "Driver's license",
  [DOCUMENTS_CATEGORIES.ALLOTMENT]: 'Allotment / Split Deposit confirmation',
  [DOCUMENTS_CATEGORIES.PAYROLL_CONNECTION]: 'Payroll Connection',
  [DOCUMENTS_CATEGORIES.BANK_STATEMENT]: 'Bank Statement (Last 60 days)',
  [DOCUMENTS_CATEGORIES.SALARY_CARD]: 'Salary Account Card',
  [DOCUMENTS_CATEGORIES.OTHERS]: 'Other documents',
}

export const LOAN_DOCUMENTS_ID = 'loan-terms-id'

export const INSTANT_TYPE_OF_DEPOSIT = 'instant'

export const TAB_KEY_CODE = 9

export const TRUST_PILOT_LINK = (
  <a
    href="https://www.trustpilot.com/review/oneblinc.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    Trustpilot
  </a>
)

export const TYPE_OF_DEPOSIT = {
  INSTANT: 'instant',
  STANDARD: 'standard',
}

export const ALLOTMENT_LABELS = {
  accountNumber: 'Account Number',
  accountType: 'Type of Account',
  allotmentAmount: 'Allotment Amount',
  bankInformation: 'Bank Information',
  bankRoutingNumber: 'Banking Routing Number',
}

export const DOCUMENT_BE_REASONS = {
  OTHER: 'other',
  WRONG_BANK_NAME: 'wrong_bank_name',
  ROUTING_NUMBER_MATCHES: 'routing_number_matches',
  ACCOUNT_NUMBER_MATCHES: 'account_number_matches',
  WRONG_ACCOUNT_TYPE: 'wrong_account_type',
  ALLOTMENT_AMOUNT: 'allotment_amount',
  CONFIRMATION_NUMBER_USPS: 'confirmation_number_usps',
  ALLOTMENT_NO_EMPLOYER: 'allotment_no_employer',
  SSN_MATCHES: 'ssn_matches',
  FORMAT_NOT_VALID: 'format_not_valid',
  WRONG_DOCUMENT: 'wrong_document',
  MASKED_ALLOTMENT: 'masked_allotment',
}

export const DOCUMENT_REASON_LABEL = {
  [DOCUMENT_BE_REASONS.OTHER]: 'Other',
  [DOCUMENT_BE_REASONS.WRONG_BANK_NAME]: 'Wrong bank name',
  [DOCUMENT_BE_REASONS.ROUTING_NUMBER_MATCHES]: 'Routing number matches',
  [DOCUMENT_BE_REASONS.ACCOUNT_NUMBER_MATCHES]: 'Account number matches',
  [DOCUMENT_BE_REASONS.WRONG_ACCOUNT_TYPE]: 'Wrong account type',
  [DOCUMENT_BE_REASONS.ALLOTMENT_AMOUNT]: 'Allotment amount',
  [DOCUMENT_BE_REASONS.CONFIRMATION_NUMBER_USPS]: 'Confirmation number (USPS)',
  [DOCUMENT_BE_REASONS.ALLOTMENT_NO_EMPLOYER]: 'Allotment does not belong to employer',
  [DOCUMENT_BE_REASONS.SSN_MATCHES]: 'SSN matches',
  [DOCUMENT_BE_REASONS.FORMAT_NOT_VALID]: 'Document format is not valid',
  [DOCUMENT_BE_REASONS.WRONG_DOCUMENT]: 'Wrong document',
  [DOCUMENT_BE_REASONS.MASKED_ALLOTMENT]: 'Masked allotment',
}

export const TRUST_PILOT_URL = 'https://www.trustpilot.com/review/oneblinc.com'

export const BIWEEKLY_AGREEMENT_STATE = 'VA'

export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  ALPHA: 'alpha',
  PRODUCTION: 'production',
}

export const USER_BANK_ACCOUNT_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
}

export const INPUT_REFS = fieldNames =>
  Object.values(fieldNames).reduce((acc, curr) => ({ ...acc, [curr]: React.createRef() }), {})

export const DRIVERS_LICENSE_TYPES = {
  FRONT: 'front',
  BACK: 'back',
}

export const STREET_LINE_ERROR = 'Supplied address is invalid / Unable to verify address'

export const SALARY_ADVANCE_BE_STATUS = {
  RELEASED: 'released',
  PENDING: 'pending',
  SETTLED: 'settled',
  OVERDUE: 'overdue',
  PROBLEM_ON_CARD: 'problem_on_card',
  COLLECT_BALANCE: 'collect_balance',
}

export const CALENDAR_TYPES_BE = {
  MONTHLY: 'monthly',
  BIWEEKLY: 'biweekly',
}

export const CALENDAR_TYPES_LABEL = {
  [CALENDAR_TYPES_BE.MONTHLY]: 'Monthly',
  [CALENDAR_TYPES_BE.BIWEEKLY]: 'Bi-Weekly',
}
export const INVALID_ARGYLE_ERROR = 'Invalid Argyle Link'

export const CHUNK_TIMEOUT_INTERVAL = 2 * 60 * 1000

export const POINTED_SALARY_STATUS = {
  DENIED: 'denied',
  PENDING: 'pending',
  APPROVED: 'approved',
  SIGNED: 'signed',
}

export const INSUFFICIENT_FUNDS_ERROR =
  'Cardholder needs to call the number on the back of the card and verify funds are available - Insufficient funds - ResultCode: 12 - AuthCode: 51'

export const INCLUDE_BREADCRUMBS = [
  '/transfer-funds-to-yourself',
  '/transfer-from-other-bank',
  '/move-money',
  '/find-atm',
  '/bank-account-settings',
  '/transactions',
  '/my-products',
  '/personal-information',
  '/credit-score',
  '/pay-bills',
  '/direct-deposit',
]

export const MONTHS_LIST_ORDER = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
}

export const SOCIAL_LOGIN_URLS = {
  facebook: `${API_URL}/social-auth/login/facebook/`,
  google: `${API_URL}/social-auth/login/google-oauth2/`,
  apple: `${API_URL}/social-auth/login/apple-id/`,
}

export const PAY_WITH_VENMO_URL = 'https://account.venmo.com/pay?recipients=OneBlinc-PMT'

export const CURRENT_ACTIVATION_STEP = {
  NOT_STARTED: 'not_started',
  AGREEMENTS: 'agreements',
  BETA_AGREEMENTS: 'beta_agreements',
  CONFIRM_ADDRESS: 'confirm_address',
  DRIVERS_LICENSE: 'drivers_license',
  CREATE_PIN: 'create_pin',
  PRIMARY_ADDRESS: 'primary_address',
  SHIPMENT: 'shipment',
  SHIPPED: 'shipped',
  WAITING_APPROVAL: 'waiting_approval',
}

export const ELIGIBILITY_CARD_BUTTON_TEXT = {
  not_started: 'Activate Now',
  started: 'Activate Now',
  agreements: 'Continue Activation process',
  beta_agreements: 'Continue Activation process',
  confirm_address: 'Continue Activation process',
  drivers_license: 'Continue Activation process',
  create_pin: 'Continue Activation process',
  shipment: 'Continue Activation process',
  primary_address: 'Continue Activation process',
  shipped: 'Your card is on it&apos;s way',
}

export const TRANSACTION_METHODS = {
  SEND: 'send',
  RECEIVE: 'receive',
  CHECK: 'check',
}

export const TRANSACTION_RESULT_THEMES = {
  SUCCESS: 'success',
  ERROR: 'error',
  ERROR_INVALID_ACCOUNT: 'error-invalid-account',
  ERROR_OTHER_ACCOUNT: 'error-other-account',
  ERROR_INSUFFICIENT_FUNDS: 'insufficient-funds',
}

export const EVENT_TYPE_CARDS = {
  CASHBACK: 'CASHBACK',
  NETFLIX_REDEEM: 'NETFLIX_REDEEM',
  NPS_SURVEY: 'NPS_SURVEY',
  RENEG: 'RENEG',
  PAYMENT_SHIELD: 'PAYMENT_SHIELD',
}

export const TRANSACTION_STATUS_TYPES = {
  RETURNED: 'RETURNED',
  CANCELED: 'CANCELED',
  SETTLED: 'SETTLED',
  SCHEDULED: 'SCHEDULED',
}

export const DONT_UPDATE_LOAN = 'dontUpdateLoan'

export const PAYMENT_TYPES = {
  ONE_TIME: 'ACH',
  RECURRING: 'AutoPay',
  FN_ALLOTMENT: 'Allotment',
  SYNAPSE_ALLOTMENT: 'Allotment',
  BILLING: 'Billing',
  REPAY: 'Debit Card',
  CARD: 'Debit Card',
  CHECK: 'Check',
  REFIN_NON_CASH: 'Refinance',
  CREDIT: 'Credit',
}

export const PLAID_OAUTH_REDIRECT_URL = `${window.location.origin}/plaid-oauth`

export const AUTO_PAY_STATUS = {
  ACTIVE: 'active',
  DISABLED: 'Disabled',
}

export const RELATIONSHIP_MANAGER_CONTACT_INFO = {
  NAME: 'Ashley Anderson',
  PHONE: '+1 (844) 449-0122',
  MAIL: 'oneclub@oneblinc.com',
  SCHEDULER: 'https://calendly.com/oneclubappointment/30min',
}

export const CASHBACK_PROGRAM_NAMES = {
  ONE_CLUB_BIRTHDAY: 'OneClub - Happy Birthday',
}
