import * as salaryAdvanceService from '_services/salary-advance'
import { defineAction } from '_utils/redux'

export const CREATE_SALARY_ADVANCE = defineAction('CREATE_SALARY_ADVANCE')
export const ESIGN_SALARY_ADVANCE = defineAction('ESIGN_SALARY_ADVANCE')
export const GET_SALARY_ADVANCE = defineAction('GET_SALARY_ADVANCE')
export const PAY_NOW_SALARY_ADVANCE = defineAction('PAY_NOW_SALARY_ADVANCE')
export const GET_SALARY_ADVANCE_TRANSACTIONS = defineAction('GET_SALARY_ADVANCE_TRANSACTIONS')
export const POINT_SALARY = defineAction('POINT_SALARY')
export const UPDATE_CARD_SALARY_ADVANCE = defineAction('UPDATE_CARD_SALARY_ADVANCE')
export const GET_PROMISSORY_NOTE_URL_SA = defineAction('GET_PROMISSORY_NOTE_URL_SA')
export const GET_TERMS_URL_SA = defineAction('GET_TERMS_URL_SA')
export const GET_PRIVACY_POLICY_URL_SA = defineAction('GET_PRIVACY_POLICY_URL_SA')
export const RESET_HAS_POSSIBLE_SALARY = 'RESET_HAS_POSSIBLE_SALARY'

export const createSalaryAdvance = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_SALARY_ADVANCE.ACTION,
    payload: salaryAdvanceService.createSalaryAdvance(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const esignSalaryAdvance = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: ESIGN_SALARY_ADVANCE.ACTION,
    payload: salaryAdvanceService.esignSalaryAdvance(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const getSalaryAdvance = () => (dispatch, getState) =>
  dispatch({
    type: GET_SALARY_ADVANCE.ACTION,
    payload: salaryAdvanceService.getSalaryAdvance(getState().authentication.get('accessToken')),
  })

export const payNowSalaryAdvance = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: PAY_NOW_SALARY_ADVANCE.ACTION,
    payload: salaryAdvanceService.payNowSalaryAdvance(
      getState().authentication.get('accessToken'),
      id,
      payload || null
    ),
    meta: { cardId: String(getState().salaryAdvance.getIn(['card', 'id'])) },
  })

export const updateCardSalaryAdvance =
  (id, payload = {}) =>
  (dispatch, getState) =>
    dispatch({
      type: UPDATE_CARD_SALARY_ADVANCE.ACTION,
      payload: salaryAdvanceService.updateCardSalaryAdvance(
        getState().authentication.get('accessToken'),
        id,
        payload
      ),
      meta: getState().card.getIn(['results', payload.card]).set('valid', false),
    })

export const getTransactions = () => (dispatch, getState) =>
  dispatch({
    type: GET_SALARY_ADVANCE_TRANSACTIONS.ACTION,
    payload: salaryAdvanceService.getTransactions(getState().authentication.get('accessToken')),
  })

export const pointSalary = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: POINT_SALARY.ACTION,
    payload: salaryAdvanceService.pointSalary(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const getTermsUrlSA = id => (dispatch, getState) =>
  dispatch({
    type: GET_TERMS_URL_SA.ACTION,
    payload: salaryAdvanceService.getUrlTermsDocuments(
      getState().authentication.get('accessToken'),
      id
    ),
  })

export const getPrivacyPolicyUrlSA = id => (dispatch, getState) =>
  dispatch({
    type: GET_PRIVACY_POLICY_URL_SA.ACTION,
    payload: salaryAdvanceService.getUrlPrivacyPolicyDocuments(
      getState().authentication.get('accessToken'),
      id
    ),
  })

export const getPromissoryNoteUrlSA = id => (dispatch, getState) =>
  dispatch({
    type: GET_PROMISSORY_NOTE_URL_SA.ACTION,
    payload: salaryAdvanceService.getUrlPromissoryNoteSalaryAdvance(
      getState().authentication.get('accessToken'),
      id
    ),
  })

export const resetHasPossibleSalary = dispatch =>
  dispatch({
    type: RESET_HAS_POSSIBLE_SALARY,
  })
