import { fromJS } from 'immutable'

import { createReducer } from '_utils/redux'
import Subscription from '_models/subscription'

import { CREATE_INVOICE, CREATE_SUBSCRIPTION, GET_INVOICE, GET_PLANS } from './actions'

const INITIAL_STATE = new Subscription()

export default createReducer(INITIAL_STATE, {
  [GET_PLANS.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS(payload))
  },
  [CREATE_SUBSCRIPTION.FULFILLED]: (state, { payload }) => {
    return state.setIn(['selectedSubscription'], fromJS(payload))
  },
  [CREATE_INVOICE.FULFILLED]: (state, { payload }) => {
    if (Object.keys(payload).length > 0) {
      return state.setIn(['selectedSubscription'], fromJS(payload))
    }
    return state
  },
  [GET_INVOICE.FULFILLED]: (state, { payload }) => {
    return state.setIn(['invoice'], fromJS(payload.invoices[0]))
  },
})
