import Model from './model'

class Renegotiation extends Model({
  renegAmounts: undefined,
  recalculateReneg: undefined,
  createReneg: undefined,
  acceptReneg: undefined,
  goToNextStep: false,
  renegAmount: undefined,
}) {}

export default Renegotiation
