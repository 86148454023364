import { normalize } from 'normalizr'
import { fromJS, List, Map } from 'immutable'

import { createReducer } from '_utils/redux'
import Interchange, { interchangeSchema } from '_models/interchange'

import {
  UPDATE_INTERCHANGE_INFO,
  GET_BOOKMARKED_CARDS,
  INTERCHANGE_PULL,
  INTERCHANGE_PUSH,
  CLEAR_INTERCHANGE,
} from './actions'

const INITIAL_STATE = Map({
  transaction: new Interchange(),
  isNewCard: true,
  transferredCardId: null,
  isTransferredCardBookmarked: false,
  bookmarkedCards: Map({ results: Map(), order: List() }),
})

export default createReducer(INITIAL_STATE, {
  [UPDATE_INTERCHANGE_INFO.ACTION]: (state, { payload }) => {
    return state.mergeIn(['transaction'], fromJS(payload))
  },

  [GET_BOOKMARKED_CARDS.FULFILLED]: (state, { payload }) => {
    const normalizedState = normalize(payload, interchangeSchema)
    return state.set(
      'bookmarkedCards',
      Map({
        results: Map(normalizedState.entities.results),
        order: List(normalizedState.result),
      })
    )
  },
  [INTERCHANGE_PULL.FULFILLED]: (state, { payload }) => {
    return state
      .set('transferredCardId', payload.node.id)
      .set('isTransferredCardBookmarked', payload.node.bookmarked)
  },
  [INTERCHANGE_PUSH.FULFILLED]: (state, { payload }) => {
    return state
      .set('transferredCardId', payload.node.id)
      .set('isTransferredCardBookmarked', payload.node.bookmarked)
  },
  [CLEAR_INTERCHANGE]: () => {
    return INITIAL_STATE
  },
})
