import { defineAction } from '_utils/redux'
import * as renegotiationService from '_services/renegotiation'

export const GET_RENEG_AMOUNTS = defineAction('GET_RENEG_AMOUNTS')
export const RECALCULATE_RENEG = defineAction('RECALCULATE_RENEG')
export const CREATE_RENEG = defineAction('CREATE_RENEG')
export const ACCEPT_RENEG = defineAction('ACCEPT_RENEG')
export const HANDLE_SHOULD_GO_TO_NEXT_STEP = defineAction('HANDLE_SHOULD_GO_TO_NEXT_STEP')
export const GET_RENEGOTIATION_AMOUNT = defineAction('GET_RENEGOTIATION_AMOUNT')

export const getRenegAmounts = () => (dispatch, getState) =>
  dispatch({
    type: GET_RENEG_AMOUNTS.ACTION,
    payload: renegotiationService.getRenegAmounts(getState().authentication.get('accessToken')),
  })

export const recalculateReneg = payload => (dispatch, getState) =>
  dispatch({
    type: RECALCULATE_RENEG.ACTION,
    payload: renegotiationService.recalculateReneg(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const createReneg = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_RENEG.ACTION,
    payload: renegotiationService.createReneg(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const acceptReneg = loanId => (dispatch, getState) =>
  dispatch({
    type: ACCEPT_RENEG.ACTION,
    payload: renegotiationService.acceptReneg(getState().authentication.get('accessToken'), loanId),
  })

export const handleShouldGoToNextStep = payload => dispatch =>
  dispatch({
    type: HANDLE_SHOULD_GO_TO_NEXT_STEP.ACTION,
    payload,
  })

export const getRenegotiationAmount = payload => dispatch =>
  dispatch({
    type: GET_RENEGOTIATION_AMOUNT.ACTION,
    payload,
  })
