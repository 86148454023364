export const ignoreErrors = [
  'SecurityError: Blocked a frame with origin',
  "Unable to get property 'toUpperCase' of undefined or null reference",
  'Not enough arguments',
  "Unable to get property 'config' of undefined or null reference",
  'this.getType',
  '`initialFocus` refers to no known node',
  'null is not an object',
  'Error: WebWorker: writer not initialised',
]
