import { defineAction } from '_utils/redux'
import * as creditScoreService from '_services/credit-score'

export const GET_PREAUTH_TOKEN = defineAction('GET_PREAUTH_TOKEN')
export const RESET_PREAUTH_TOKEN = defineAction('RESET_PREAUTH_TOKEN')
export const SET_CREDIT_SCORE_HISTORY = defineAction('GET_CREDIT_SCORE_HISTORY')

export const getPreAuthToken = () => (dispatch, getState) =>
  dispatch({
    type: GET_PREAUTH_TOKEN.ACTION,
    payload: creditScoreService.verify(getState().authentication.get('accessToken')),
  })

export const resetPreAuthToken = () => dispatch =>
  dispatch({
    type: RESET_PREAUTH_TOKEN,
  })

export const setScoreHistory = () => (dispatch, getState) =>
  dispatch({
    type: SET_CREDIT_SCORE_HISTORY.ACTION,
    payload: creditScoreService.history(getState().authentication.get('accessToken')),
  })
