import { createFormData } from '_utils/helpers'

import { get, post, patch } from './requests'

export const getLoans = key => get(['loans'], { key, transformPayload: true })

export const createLoan = (key, payload) =>
  post(['loans'], { key, transformPayload: true }, payload)

export const getLoanDocuments = (key, id) =>
  get(['loans', id, 'documents'], { key, transformPayload: true })

export const acceptLoan = (key, id, payload) =>
  post(['loans', id, 'accept'], { key, transformPayload: true }, payload)

export const uploadDocument =
  key =>
  ({ loanId, documentId, ...payload }) =>
    patch(['loans', loanId, 'documents', documentId], { key }, createFormData(payload, false))

export const getCardForm = key => get(['card-form'], { key, transformPayload: true })

export const getAllotment = key => get(['allotments'], { key, transformPayload: true })

export const encryptAllotment = key =>
  get(['argyle', 'encrypted-allotment'], { key, transformPayload: true })

export const encryptReconnectionAllotment = key =>
  get(['argyle', 'encrypt-reconnection-allotment'], { key, transformPayload: true })

export const reapplyPreview = (key, id, payload) =>
  post(['loans', id, 'reapply_preview'], { key, transformPayload: true }, payload)

export const reapplyLoan = (key, id, payload) =>
  post(['loans', id, 'reapply'], { key, transformPayload: true }, payload)

export const refinancePreview = (key, id, payload) =>
  post(['loans', id, 'refinance-preview'], { key, transformPayload: true }, payload)

export const esignRefinance = (key, id, payload) =>
  post(['loans', id, 'refinance'], { key, transformPayload: true }, payload)

export const getRenegotiation = (key, id, payload) =>
  get(['loans', id, 'renegotiate-payoff'], { key, transformPayload: true }, payload)

export const postRenegotiation = (key, id, payload) =>
  post(['loans', id, 'renegotiate-payoff'], { key, transformPayload: true }, payload)

export const paymentHistory = (key, id) =>
  get(['loans', id, 'payments-history'], { key, transformPayload: true })

export const customerHistory = key => get(['customer-history'], { key, transformPayload: true })

export const getLoanOnePayment = (key, id) =>
  get(['loans', id, 'payment'], { key, transformPayload: true })

export const getRenegLoanOnePayment = (key, id) =>
  get(['loans', id, 'reneg-autopay-info'], { key, transformPayload: true })

export const getLoanPaymentsDue = (key, id) =>
  get(['loans', id, 'payments-due'], { key, transformPayload: true })

export const achPayment = (key, id) =>
  get(['loans', id, 'ach-payments'], { key, transformPayload: true })

export const getAutoPayInfo = (key, id) =>
  get(['loans', id, 'autopay-info'], { key, transformPayload: true })

export const getRenegAutoPayInfo = (key, id) =>
  get(['loans', id, 'autopay-info'], { key, transformPayload: true })

export const esignNewAutopayTerms = (key, id) =>
  post(['loans', id, 'rennew-document'], { key, transformPayload: true })

export const generateNewAutopayTerms = (key, id) =>
  post(
    ['loans', id, 'rennew-document'],
    { key, transformPayload: true },
    {
      previewDocument: true,
    }
  )

export const setProtectShield = (key, id, payload, isFormData) =>
  post(
    ['loans', id, 'protection-shield'],
    { key, transformPayload: !isFormData },
    !isFormData ? payload : createFormData(payload, false)
  )

export const updateInstantCard = (key, id, payload) =>
  post(['loans', id, 'change-card-loan'], { key, transformPayload: true }, payload)

export const needUpdateContactInfo = (key, id) =>
  get(['loans', id, 'need-contact-update'], { key, transformPayload: true })

export const startPhoneVerification = (key, params) =>
  post(['phone-verification'], { key, transformPayload: true, params })

export const getUserPhones = (key, params) =>
  get(['phone-verification', 'list-phones'], { key, transformPayload: true, params })

export const confirmPhoneVerification = (key, payload, params) =>
  post(['phone-verification', 'confirm'], { key, transformPayload: true, params }, payload)

export const updatePhoneNumber = (key, payload, params) =>
  post(['phone-verification', 'update-phone'], { key, transformPayload: true, params }, payload)

export const needPhoneVerification = (key, params) =>
  get(['phone-verification', 'need-verification'], { key, transformPayload: true, params })
