import * as loanService from '_services/loan'
import { defineAction } from '_utils/redux'

export const REFINANCE_PREVIEW = defineAction('REFINANCE_PREVIEW')
export const ESIGN_REFINANCE = defineAction('ESIGN_REFINANCE')
export const START_PHONE_VERIFICATION = defineAction('START_PHONE_VERIFICATION')
export const GET_USER_PHONES = defineAction('GET_USER_PHONES')
export const CONFIRM_PHONE_VERIFICATION = defineAction('CONFIRM_PHONE_VERIFICATION')
export const UPDATE_PHONE_NUMBER = defineAction('UPDATE_PHONE_NUMBER')
export const NEED_PHONE_VERIFICATION = defineAction('NEED_PHONE_VERIFICATION')

export const refinancePreview = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: REFINANCE_PREVIEW.ACTION,
    payload: loanService.refinancePreview(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const esignRefinance = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: ESIGN_REFINANCE.ACTION,
    payload: loanService.esignRefinance(getState().authentication.get('accessToken'), id, payload),
  })

export const startPhoneVerification = params => (dispatch, getState) =>
  dispatch({
    type: START_PHONE_VERIFICATION.ACTION,
    payload: loanService.startPhoneVerification(
      getState().authentication.get('accessToken'),
      params
    ),
  })

export const getUserPhones = params => (dispatch, getState) =>
  dispatch({
    type: GET_USER_PHONES.ACTION,
    payload: loanService.getUserPhones(getState().authentication.get('accessToken'), params),
  })

export const confirmPhoneVerification = (code, params) => (dispatch, getState) =>
  dispatch({
    type: CONFIRM_PHONE_VERIFICATION.ACTION,
    payload: loanService.confirmPhoneVerification(
      getState().authentication.get('accessToken'),
      code,
      params
    ),
  })

export const updatePhoneNumber = (phone, params) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_PHONE_NUMBER.ACTION,
    payload: loanService.updatePhoneNumber(
      getState().authentication.get('accessToken'),
      phone,
      params
    ),
  })

export const needPhoneVerification = () => (dispatch, getState) =>
  dispatch({
    type: NEED_PHONE_VERIFICATION.ACTION,
    payload: loanService.needPhoneVerification(getState().authentication.get('accessToken')),
  })
