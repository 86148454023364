import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import CashbackDashboardNavHeader from '_components/header/cashback-dashboard-nav-header'
import AnimatedOpacity from '_styles/animations/AnimatedOpacity'
import { isUserLoggedInSelector } from '_modules/user/selectors'

import styles from './styles.module.css'

const CashbackApp = ({ children }) => {
  const isUserLoggedIn = useSelector(isUserLoggedInSelector)

  if (!isUserLoggedIn) {
    return null
  }

  return (
    <div className={styles.dashboard}>
      <CashbackDashboardNavHeader />
      <main className={styles.content}>
        <AnimatedOpacity>{children}</AnimatedOpacity>
      </main>
    </div>
  )
}

CashbackApp.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CashbackApp
