import React from 'react'
import PropTypes from 'prop-types'

import Modal from '_components/modal'

import styles from './styles.module.css'

const MaintenanceModal = ({ onCloseModal }) => {
  return (
    <Modal title="Server maintenance" onClose={onCloseModal}>
      <div className={styles.modal}>
        <p className={styles['modal-description']}>
          Dear Visitor,
          <br />
          <br />
          Thank you for visiting the OneBlinc website. Due to our server maintenance, there will be
          downtime expected from 8:00 pm ET June 16, 2021, which may cause some difficulties viewing
          the website or certain services going offline.
          <br />
          <br />
          We will, of course, strive to minimize the length of downtime and return to full
          functionality as quickly as possible
          <br />
          <br />
          We appreciate your patience and understanding.
        </p>
      </div>
    </Modal>
  )
}

MaintenanceModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
}
export default MaintenanceModal
