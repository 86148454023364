import { ROOT_PROJECT_URL } from '_config/environment'

export const FOOTER_ROUTES = {
  HOME: { text: 'home', path: ROOT_PROJECT_URL, external: true },
  ABOUT: { text: 'about', path: `${ROOT_PROJECT_URL}/about`, external: true },
  FAQ: { text: 'faq', path: 'https://faq.oneblinc.com/', external: true },
  REVIEWS: { text: 'reviews', path: `${ROOT_PROJECT_URL}/reviews` },
  BLOG: { text: 'blog', path: 'https://blog.oneblinc.com/', external: true },
  TOOLBOX: { text: 'toolbox', path: `${ROOT_PROJECT_URL}/toolbox`, external: true },
  CASHBACK: { text: 'cashback', path: `${ROOT_PROJECT_URL}/cashback`, external: true },
}

export const SUB_FOOTER_ROUTES = {
  CREDIT_EDUCATION: { text: 'credit education', path: `${ROOT_PROJECT_URL}/credit-education` },
  COMPLIANCE: { text: 'compliance', path: `${ROOT_PROJECT_URL}/compliance` },
}

export const USER_CASHBACK_ROUTES = {
  CASHBACK_DASHBOARD: { text: 'cashback dashboard', path: '/cashback-dashboard' },
  DASHBOARD: { text: 'oneBlinc dashboard', path: '/' },
}

export const LEAD_CASHBACK_ROUTES = {
  CASHBACK_DASHBOARD: { text: 'cashback dashboard', path: '/cashback-dashboard' },
  TOOLBOX: { text: 'toolbox', path: '/cashback-dashboard/toolbox' },
}

export const DASHBOARD_ROUTES = {
  DASHBOARD: { text: 'dashboard', path: '/' },
  FAQ: { text: 'FAQ', path: 'https://faq.oneblinc.com/', external: true, newTab: true },
  ...LEAD_CASHBACK_ROUTES,
  REFERRALS: { text: 'referrals', path: '/referrals' },
}

export const HEADER_ROUTES = {
  ...FOOTER_ROUTES,
}

export const SALARY_ADVANCE_ROUTE = {
  DASHBOARD: DASHBOARD_ROUTES.DASHBOARD,
  SALARY_ADVANCE: { text: 'salary advance', path: '/salary-advance' },
}

export const MANAGE_PAYMENTS_ROUTE = {
  DASHBOARD: DASHBOARD_ROUTES.DASHBOARD,
  MANAGE_PAYMENTS: { text: 'manage payments', path: '/manage-payments' },
}

export const DASHBOARD_APPROVED_ROUTES = {
  DASHBOARD: DASHBOARD_ROUTES.DASHBOARD,
  MY_PRODUCTS: { text: 'my products', path: '/my-products' },
}

export const BANKING_ROUTES = {
  TRANSACTIONS: { text: 'transactions', path: '/transactions' },
  FIND_ATM: { text: 'find ATM', path: '/find-atm' },
}

export const CHECKING_ACCOUNT_ROUTES = {
  DASHBOARD: DASHBOARD_ROUTES.DASHBOARD,
  BANKING: { text: 'Banking', path: null, subRoutes: BANKING_ROUTES },
}

export const APPROVED_REFIN_DIS_PENDING_ROUTES = {
  DASHBOARD: DASHBOARD_ROUTES.DASHBOARD,
  FUN_REWARDS: { text: 'fun & rewards', path: '/rewards', shouldTrack: true },
}

export const BLINKY_BOT_ROUTE = {
  BLINKY_BOT: { text: 'OneBlinc Assistant', path: '/assistant' },
}

export const SUBSCRIBED_ROUTES = {
  TOOLBOX: { text: 'toolbox', path: '/toolbox' },
  CASHBACK: { text: 'cashback', path: `${ROOT_PROJECT_URL}/cashback`, external: true },
  CREDIT_SCORE: { text: 'credit score', path: '/credit-score' },
}

export const CREDIT_SCORE_ROUTE = {
  CREDIT_SCORE: { text: 'credit score', path: '/credit-score' },
}
