import { defineAction } from '_utils/redux'
import * as checkService from '_services/mail-check'

export const UPDATE_MAIL_CHECK_INFO = defineAction('UPDATE_MAIL_CHECK_INFO')
export const SET_MAILING_RECIPIENT = defineAction('SET_MAILING_RECIPIENT')
export const SET_CHECK_AMOUNT = defineAction('SET_CHECK_AMOUNT')
export const CLEAR_MAIL_CHECK = defineAction('CLEAR_MAIL_CHECK')
export const MAIL_CHECK = defineAction('MAIL_CHECK')
export const GET_BOOKMARKED_RECIPIENTS = defineAction('GET_BOOKMARKED_RECIPIENTS')
export const REMOVE_BOOKMARKED_RECIPIENT = defineAction('REMOVE_BOOKMARKED_RECIPIENT')
export const BOOKMARK_RECIPIENT = defineAction('BOOKMARK_RECIPIENT')

export const updateMailCheckInfo = payload => dispatch =>
  dispatch({
    type: UPDATE_MAIL_CHECK_INFO,
    payload,
  })

export const setMailingRecipient = payload => dispatch =>
  dispatch({
    type: SET_MAILING_RECIPIENT,
    payload,
  })

export const setCheckAmount = payload => dispatch =>
  dispatch({
    type: SET_CHECK_AMOUNT,
    payload,
  })

export const mailCheck = payload => (dispatch, getState) =>
  dispatch({
    type: MAIL_CHECK,
    payload: checkService.mailCheck(getState().authentication.get('accessToken'), payload),
  })

export const clearMailCheck = payload => dispatch =>
  dispatch({
    type: CLEAR_MAIL_CHECK,
    payload,
  })

export const getBookmarkedRecipients = payload => (dispatch, getState) =>
  dispatch({
    type: GET_BOOKMARKED_RECIPIENTS,
    payload: checkService.getBookmarkedRecipients(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const removeBookmarkedRecipient = payload => (dispatch, getState) =>
  dispatch({
    type: REMOVE_BOOKMARKED_RECIPIENT,
    payload: checkService.removeBookmarkedRecipient(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const bookmarkRecipient = payload => (dispatch, getState) =>
  dispatch({
    type: BOOKMARK_RECIPIENT,
    payload: checkService.bookmarkRecipient(getState().authentication.get('accessToken'), payload),
  })
