import { defineAction } from '_utils/redux'
import * as growthService from '_services/growth'

export const GET_QUIZ = defineAction('GET_QUIZ')
export const SEND_QUESTION_RESPONSE = defineAction('SEND_QUESTION_RESPONSE')
export const FINISH_QUIZ = defineAction('FINISH_QUIZ')
export const TRACK_USER = defineAction('TRACK_USER')

export const getQuiz = () => (dispatch, getState) =>
  dispatch({
    type: GET_QUIZ.ACTION,
    payload: growthService.getQuiz(getState().authentication.get('accessToken')),
  })

export const sendQuestionResponse = payload => (dispatch, getState) =>
  dispatch({
    type: SEND_QUESTION_RESPONSE.ACTION,
    payload: growthService.sendQuestionResponse(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const finishQuiz = payload => (dispatch, getState) =>
  dispatch({
    type: FINISH_QUIZ.ACTION,
    payload: growthService.finishQuiz(getState().authentication.get('accessToken'), payload),
  })

export const trackUser = payload => (dispatch, getState) =>
  dispatch({
    type: TRACK_USER.ACTION,
    payload: growthService.trackingUser(getState().authentication.get('accessToken'), payload),
  })
