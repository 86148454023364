export const API_URL =
  process.env.REACT_APP_API_URL || 'https://staging-api.triton.oneblinc.com/api/v1'
export const ROOT_PROJECT_URL =
  process.env.REACT_APP_ROOT_PROJECT_URL || 'https://staging.triton.oneblinc.com'
export const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN || 'localhost'
export const REACT_PUBLIC_URL =
  process.env.REACT_PUBLIC_URL || 'https://staging-dashboard.triton.oneblinc.com'
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || 'development'
export const REACT_APP_SENTRY_AUTH_TOKEN = process.env.REACT_APP_SENTRY_AUTH_TOKEN || null
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
export const ARGYLE_PLUGIN_KEY =
  process.env.REACT_APP_ARGYLE_PLUGIN_KEY || 'b54ae6f0-12e0-4cbb-b5ce-679738122111'
export const ARGYLE_API_HOST =
  process.env.REACT_APP_ARGYLE_API_HOST || 'https://api-sandbox.argyle.com/v1'
export const STITCH_IFRAME_URL =
  process.env.REACT_APP_STITCH_IFRAME_URL || 'https://efx-dev.stitchcredit.com/login-aio'
export const OPEN_REPLAY_PROJECT_KEY = process.env.REACT_APP_OPEN_REPLAY_PROJECT_KEY
export const ENVIRONMENT = SENTRY_ENV
export const CDN_DOMAIN =
  process.env.REACT_APP_ROOT_CDN_DOMAIN ||
  'https://staging-statics.triton.oneblinc.com/card-stack/cards-images'
export const FORCE_CACHE_BUSTER = process.env.FORCE_CACHE_BUSTER || 'false'
export const FRONTEND_VERSION_API_KEY = process.env.REACT_APP_FRONTEND_VERSION
  ? `Api-Key ${process.env.REACT_APP_FRONTEND_VERSION}`
  : 'Api-Key hMPVQaCt.nfbrjGA7S74vWl3mLCpUkbdCLioUw5pX'
export const TOOLBOX_API_KEY = process.env.REACT_APP_TOOLBOX
  ? `Api-Key ${process.env.REACT_APP_TOOLBOX}`
  : 'Api-Key VeN9dn0h.BITywuZ81tfTInOEmLfLxASuTHf3ylKn'
export const COOKIEBOT_DOMAIN_GROUP_ID =
  process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID ?? '24f4206f-42d2-4267-8aad-e71be4ba5f5c'
