import { defineAction } from '_utils/redux'
import * as nlsService from '_services/nls'

export const GET_NLS_UPDATE_STATUS = defineAction('GET_NLS_UPDATE_STATUS')
export const UPDATE_NLS_STATUS = defineAction('UPDATE_NLS_STATUS')

export const getNLSUpdateStatus = () => (dispatch, getState) =>
  dispatch({
    type: GET_NLS_UPDATE_STATUS.ACTION,
    payload: nlsService.getNLSUpdateStatus(getState().authentication.get('accessToken')),
  })
export const updateNLSStatus = () => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_NLS_STATUS.ACTION,
    payload: nlsService.updateNLSStatus(getState().authentication.get('accessToken')),
  })
}
