import { defineAction } from '_utils/redux'
import * as chatService from '_services/chat'

export const GET_QUESTIONS = defineAction('GET_QUESTIONS')
export const GET_ANSWER = defineAction('GET_ANSWER')
export const ASK_QUESTION = defineAction('ASK_QUESTION')
export const RATE_ANSWER = defineAction('RATE_ANSWER')
export const CLEAR_CHAT = defineAction('CLEAR_CHAT')

export const getQuestions = () => (dispatch, getState) =>
  dispatch({
    type: GET_QUESTIONS.ACTION,
    payload: chatService.getQuestions(getState().authentication.get('accessToken')),
  })
export const getAnswer = payload => (dispatch, getState) =>
  dispatch({
    type: GET_ANSWER.ACTION,
    payload: chatService.getAnswer(getState().authentication.get('accessToken'), payload),
  })
export const rateAnswer = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: RATE_ANSWER.ACTION,
    payload: chatService.rate(getState().authentication.get('accessToken'), id, payload),
  })

export const askQuestion = payload => dispatch =>
  dispatch({
    type: ASK_QUESTION,
    payload,
  })

export const clearChat = () => dispatch =>
  dispatch({
    type: CLEAR_CHAT,
  })
