import Humps from 'humps'

import * as loanService from '_services/loan'
import { defineAction } from '_utils/redux'

export const GET_LOANS = defineAction('GET_LOANS')
export const CREATE_LOAN = defineAction('CREATE_LOAN')
export const GET_LOAN_DOCUMENTS = defineAction('GET_LOAN_DOCUMENTS')
export const GET_REFINANCE_DOCUMENTS = defineAction('GET_REFINANCE_DOCUMENTS')
export const ACCEPT_LOAN = defineAction('ACCEPT_LOAN')
export const UPLOAD_DOCUMENT = defineAction('UPLOAD_DOCUMENT')
export const GET_CARD_FORM = defineAction('GET_CARD_FORM')
export const GET_ALLOTMENT = defineAction('GET_ALLOTMENT')
export const REAPPLY_PREVIEW = defineAction('REAPPLY_PREVIEW')
export const REAPPLY_LOAN = defineAction('REAPPLY_LOAN')
export const PAYMENT_HISTORY = defineAction('PAYMENT_HISTORY')
export const GET_LOAN_ONE_PAYMENT = defineAction('GET_LOAN_ONE_PAYMENT')
export const GET_RENEG_LOAN_ONE_PAYMENT = defineAction('GET_LOAN_ONE_PAYMENT')
export const CUSTOMER_HISTORY = defineAction('CUSTOMER_HISTORY')
export const ENCRYPT_ALLOTMENT = defineAction('ENCRYPT_ALLOTMENT')
export const GET_LOAN_PAYMENTS_DUE = defineAction('GET_LOAN_PAYMENTS_DUE')
export const GET_ACH_PAYMENTS = defineAction('GET_ACH_PAYMENTS')
export const GET_AUTO_PAY_INFO = defineAction('GET_AUTO_PAY_INFO')
export const ESIGN_NEW_AUTOPAY_TERMS = defineAction('ESIGN_NEW_AUTOPAY_TERMS')
export const GENERATE_NEW_AUTOPAY_TERMS = defineAction('GENERATE_NEW_AUTOPAY_TERMS')
export const SET_PROTECT_SHIELD = defineAction('SET_PROTECT_SHIELD')
export const UPDATE_INSTANT_CARD = defineAction('UPDATE_INSTANT_CARD')
export const ENCRYPT_RECONNECTION_ALLOTMENT = defineAction('ENCRYPT_RECONNECTION_ALLOTMENT')
export const NEED_UPDATE_CONTACT_INFO = defineAction('NEED_UPDATE_CONTACT_INFO')
export const CLEAR_LOAN_DRAFT = defineAction('CLEAR_LOAN_DRAFT')

export const getLoans = () => (dispatch, getState) =>
  dispatch({
    type: GET_LOANS.ACTION,
    payload: loanService.getLoans(getState().authentication.get('accessToken')),
    meta: {
      loadingState: getState().loading,
    },
  })

export const createLoan = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_LOAN.ACTION,
    payload: loanService.createLoan(getState().authentication.get('accessToken'), payload),
  })

export const getLoanDocuments = id => (dispatch, getState) =>
  dispatch({
    type: GET_LOAN_DOCUMENTS.ACTION,
    payload: loanService.getLoanDocuments(getState().authentication.get('accessToken'), id),
  })

export const getRefinanceDocuments = id => (dispatch, getState) =>
  dispatch({
    type: GET_REFINANCE_DOCUMENTS.ACTION,
    payload: loanService.getLoanDocuments(getState().authentication.get('accessToken'), id),
  })

export const acceptLoan = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: ACCEPT_LOAN.ACTION,
    payload: loanService.acceptLoan(getState().authentication.get('accessToken'), id, payload),
  })

export const uploadDocument =
  ({ category, field = 'documents', ...payload }) =>
  (dispatch, getState) =>
    dispatch({
      type: UPLOAD_DOCUMENT.ACTION,
      payload: loanService.uploadDocument(getState().authentication.get('accessToken'))(payload),
      meta: { category: Humps.camelize(category), field },
    })

export const getCardForm = (dispatch, getState) =>
  dispatch({
    type: GET_CARD_FORM.ACTION,
    payload: loanService.getCardForm(getState().authentication.get('accessToken')),
  })

export const getAllotment = (dispatch, getState) =>
  dispatch({
    type: GET_ALLOTMENT.ACTION,
    payload: loanService.getAllotment(getState().authentication.get('accessToken')),
  })

export const encryptAllotment = (dispatch, getState) =>
  dispatch({
    type: ENCRYPT_ALLOTMENT.ACTION,
    payload: loanService.encryptAllotment(getState().authentication.get('accessToken')),
  })

export const encryptReconnectionAllotment = (dispatch, getState) =>
  dispatch({
    type: ENCRYPT_RECONNECTION_ALLOTMENT.ACTION,
    payload: loanService.encryptReconnectionAllotment(getState().authentication.get('accessToken')),
  })
export const reapplyPreview = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: REAPPLY_PREVIEW.ACTION,
    payload: loanService.reapplyPreview(getState().authentication.get('accessToken'), id, payload),
  })

export const reapplyLoan = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: REAPPLY_LOAN.ACTION,
    payload: loanService.reapplyLoan(getState().authentication.get('accessToken'), id, payload),
  })

export const paymentHistory = id => (dispatch, getState) =>
  dispatch({
    type: PAYMENT_HISTORY.ACTION,
    payload: loanService.paymentHistory(getState().authentication.get('accessToken'), id),
  })

export const customerHistory = (dispatch, getState) =>
  dispatch({
    type: CUSTOMER_HISTORY.ACTION,
    payload: loanService.customerHistory(getState().authentication.get('accessToken')),
  })

export const getLoanOnePayment = id => (dispatch, getState) =>
  dispatch({
    type: GET_LOAN_ONE_PAYMENT.ACTION,
    payload: loanService.getLoanOnePayment(getState().authentication.get('accessToken'), id),
  })

export const getRenegLoanOnePayment = id => (dispatch, getState) =>
  dispatch({
    type: GET_RENEG_LOAN_ONE_PAYMENT.ACTION,
    payload: loanService.getRenegLoanOnePayment(getState().authentication.get('accessToken'), id),
  })

export const getLoanPaymentsDue = id => (dispatch, getState) =>
  dispatch({
    type: GET_LOAN_PAYMENTS_DUE.ACTION,
    payload: loanService.getLoanPaymentsDue(getState().authentication.get('accessToken'), id),
  })

export const getAchPayments = id => (dispatch, getState) =>
  dispatch({
    type: GET_ACH_PAYMENTS.ACTION,
    payload: loanService.achPayment(getState().authentication.get('accessToken'), id),
  })

export const getAutoPayInfo = id => (dispatch, getState) =>
  dispatch({
    type: GET_AUTO_PAY_INFO.ACTION,
    payload: loanService.getAutoPayInfo(getState().authentication.get('accessToken'), id),
  })

export const esignNewAutopayTerms = id => (dispatch, getState) =>
  dispatch({
    type: ESIGN_NEW_AUTOPAY_TERMS.ACTION,
    payload: loanService.esignNewAutopayTerms(getState().authentication.get('accessToken'), id),
  })

export const generateNewAutopayTerms = id => (dispatch, getState) =>
  dispatch({
    type: GENERATE_NEW_AUTOPAY_TERMS.ACTION,
    payload: loanService.generateNewAutopayTerms(getState().authentication.get('accessToken'), id),
  })

export const setProtectShield = (id, payload, hasFile) => (dispatch, getState) =>
  dispatch({
    type: SET_PROTECT_SHIELD.ACTION,
    payload: loanService.setProtectShield(
      getState().authentication.get('accessToken'),
      id,
      payload,
      hasFile
    ),
  })

export const updateInstantCard = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_INSTANT_CARD.ACTION,
    payload: loanService.updateInstantCard(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })
export const needUpdateContactInfo = id => (dispatch, getState) =>
  dispatch({
    type: NEED_UPDATE_CONTACT_INFO.ACTION,
    payload: loanService.needUpdateContactInfo(getState().authentication.get('accessToken'), id),
  })
export const clearLoanDraft = () => dispatch =>
  dispatch({
    type: CLEAR_LOAN_DRAFT.ACTION,
  })
