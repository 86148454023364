import { Map } from 'immutable'

import { createReducer } from '_utils/redux'

import { GET_PREAUTH_TOKEN, RESET_PREAUTH_TOKEN } from './actions'

const INITIAL_STATE = Map({
  preAuthToken: undefined,
})

export default createReducer(INITIAL_STATE, {
  [GET_PREAUTH_TOKEN.FULFILLED]: (state, { payload }) => {
    return state.set('preAuthToken', payload.paToken)
  },
  [RESET_PREAUTH_TOKEN]: state => {
    return state.set('preAuthToken', undefined)
  },
})
