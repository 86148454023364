import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getUserSelector } from '_modules/user/selectors'

export const useZendeskIdentity = () => {
  const user = useSelector(getUserSelector)

  useEffect(() => {
    if (window.zE && user.size) {
      const phone = user.mobilePhoneNumber
        ? `${user.mobileCountryCode} ${user.mobilePhoneNumber}`
        : null

      const userData = {
        name: {
          value: `${user.firstName} ${user.lastName}`,
        },
        email: {
          value: user.email,
        },
        phone: {
          value: phone,
        },
      }

      window.zE('webWidget', 'prefill', userData)
    }
  }, [user])
}
