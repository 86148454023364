import OpenReplay from '@openreplay/tracker'
import trackerAxios from '@openreplay/tracker-axios/cjs'
import trackerRedux from '@openreplay/tracker-redux'
import trackerAssist from '@openreplay/tracker-assist'
import * as Sentry from '@sentry/browser'

import axiosInstance from '_services/requests'
import { ENVIRONMENT, OPEN_REPLAY_PROJECT_KEY } from '_config/environment'

import packageJson from '../../package.json'

const maskFieldInJsonBody = (json, fieldName) => {
  const body = JSON.parse(json)
  body[fieldName] = '******'
  return JSON.stringify(body)
}

const openReplayTracker = new OpenReplay({
  projectKey: OPEN_REPLAY_PROJECT_KEY,
  captureIFrames: true,
  obscureTextEmails: false,
  obscureInputEmails: false,
  onStart: ({ sessionToken }) => {
    Sentry.setTag('openReplaySessionToken', sessionToken)
  },
})
openReplayTracker.use(
  trackerAxios({
    instance: axiosInstance,
    sanitiser: data => {
      try {
        const newData = { ...data }
        let { body } = newData.request
        if (data.url.includes('/login')) {
          body = maskFieldInJsonBody(body, 'password')
        }
        if (data.url.includes('/onboarding')) {
          body = maskFieldInJsonBody(body, 'password1')
          body = maskFieldInJsonBody(body, 'password2')
        }
        newData.request.body = body
        return newData
      } catch (e) {
        return data
      }
    },
  })
)
openReplayTracker.use(trackerAssist())
export const openReplayMiddleware = openReplayTracker.use(trackerRedux())

export const initOpenReplayTracker = () => {
  if (!openReplayTracker.isActive()) {
    openReplayTracker.start()
    openReplayTracker.setMetadata('environment', ENVIRONMENT)
    openReplayTracker.setMetadata('currentVersion', packageJson.version)
  }
}

export const getOpenReplayTracker = () => openReplayTracker
