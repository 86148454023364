import Model from './model'

class CardPayment extends Model({
  loan: undefined,
  paymentDate: undefined,
  amount: undefined,
  card: undefined,
}) {}

export default CardPayment
