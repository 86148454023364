// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'
// import 'core-js/stable'

import 'regenerator-runtime/runtime'

import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { Location } from '@reach/router'
import { CookiesProvider, Cookies } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { SWRConfig } from 'swr'

import { TAB_KEY_CODE } from '_utils/constants'
import { CacheBuster } from '_components/cache-buster/cache-buster'
import {
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_RELEASE,
  GOOGLE_TAG_MANAGER_ID,
  ROOT_DOMAIN,
  REACT_PUBLIC_URL,
  COOKIEBOT_DOMAIN_GROUP_ID,
} from '_config/environment'
import { ignoreErrors } from '_utils/sentry-ignore'
import { initOpenReplayTracker } from '_utils/open-replay'
import { Authentication, User } from '_models/'
import { useCookieBot } from '_hooks/use-cookiebot'

import './bootstrap'

import configureStore from './store/configure-store'
import Router from './router'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    ignoreErrors,
  })
}

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
}

TagManager.initialize(tagManagerArgs)

const addEventListeners = () => {
  // Let the document know when the mouse is being used
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse')
  })

  // Re-enable focus styling when Tab is pressed
  document.body.addEventListener('keydown', event => {
    if (event.keyCode === TAB_KEY_CODE) {
      document.body.classList.remove('using-mouse')
    }
  })

  // Capture CSP errors
  // TODO: Rethink the way of logging CSP errors
  // document.addEventListener('securitypolicyviolation', event => {
  //   const { blockedURI, violatedDirective } = event
  //   const cspError = `Connection to "${blockedURI}" has been refused because it violated the "${violatedDirective}" directive.`
  //   Sentry.captureMessage(cspError)
  // })
}

const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${REACT_PUBLIC_URL}/serviceWorker.js`
      navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
          console.info('ServiceWorker registration successful with scope: ', registration.scope)
        })
        .catch(error => {
          console.error('ServiceWorker registration failed: ', error)
        })
    })
  }
}

const cookies = new Cookies()

const authentication = new Authentication(cookies.get('authentication', { domain: ROOT_DOMAIN }))
const user = new User({ originUrl: cookies.get('originUrl', { path: '/' }) })
const initialState = { authentication, user }

export const store = configureStore(initialState)

const container = document.getElementById('root')
const root = createRoot(container)
const App = () => {
  useEffect(() => {
    addEventListeners()
    initOpenReplayTracker()
    registerServiceWorker()
  }, [])
  useCookieBot({ domainGroupId: COOKIEBOT_DOMAIN_GROUP_ID })

  return (
    <SWRConfig
      value={{
        // keepPreviousData: true,
        loadingTimeout: 100000,
        errorRetryCount: 1,
      }}
    >
      <CacheBuster>
        <Location>
          {({ location }) => {
            const previousLocation = location.state?.oldLocation || null

            return (
              <Provider store={store}>
                <CookiesProvider>
                  <Router
                    primary={false}
                    location={previousLocation != null ? previousLocation : location}
                  />
                </CookiesProvider>
                {previousLocation != null && <Router location={location} />}
              </Provider>
            )
          }}
        </Location>
      </CacheBuster>
    </SWRConfig>
  )
}

root.render(<App />)
