const APP_ID = 'urvwe5kp'
const INTERCOM_SCRIPT_ID = 'intercom-script-injection'
const ZENDESK_KEY = '59efaab7-3ffe-487e-8139-27a0ae458ce1'
const ZENDESK_SCRIPT_ID = 'ze-snippet'

export const intercomSetup = user => {
  if (user?.get('id')) {
    window.intercomSettings = {
      app_id: APP_ID,
      name: `${user.get('firstName')} ${user.get('lastName')}`,
      email: user.get('email'),
      user_id: user.get('id'),
    }
  } else {
    window.intercomSettings = {
      app_id: APP_ID,
      name: null,
      email: null,
      user_id: null,
    }
  }
  const w = window
  const ic = w.Intercom

  if (document.getElementById(INTERCOM_SCRIPT_ID)) {
    ic('update', w.intercomSettings)
  } else {
    const d = document
    const i = args => {
      i.c(args)
    }
    i.q = []
    i.c = args => {
      i.q.push(args)
    }
    w.Intercom = i
    const l = () => {
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = `https://widget.intercom.io/widget/${APP_ID}`
      s.id = INTERCOM_SCRIPT_ID
      const x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
    } else if (w.attachEvent) {
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
}

export const zendeskSetup = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`
  script.id = ZENDESK_SCRIPT_ID
  const x = document.getElementsByTagName('script')[0]
  x.parentNode.insertBefore(script, x)
}
