import React from 'react'
import { Link } from '@reach/router'

import styles from './styles.module.css'

export const ELETRONIC_HTML = (
  <section className={styles['html-section']}>
    <h1 className={styles.paragraph}>
      Electronic Disclosure, Electronic Signature and Electronic Statements Agreement
    </h1>
    <h2 className={styles.paragraph}>Terms and Conditions</h2>
    <p className={styles.paragraph}>
      PLEASE READ THE FOLLOWING ELECTRONIC RECORDS DISCLOSURE TERMS AND CONDITIONS (THIS
      “AGREEMENT”) CAREFULLY.
    </p>
    <p className={styles.paragraph}>
      In this Consent for Use of Electronic Records and Signatures (“Consent’), the words “you” and
      “your” mean any person giving consent to use of electronic records and signatures as described
      below. The words “we,” “us” and “our” mean BlincLoans INC, its successors and assigns, and
      their designated service providers. You are requesting or have obtained loans or other
      financial products or services from us. With your consent, we may choose to provide certain
      Records to you in electronic form and obtain your electronic signature for those Records. You
      are not required to receive Records in electronic form and sign them electronically. If you do
      not want to consent to our use of electronic Records and signatures at this time, please close
      your web browser and do not electronically sign this Consent as described below.
    </p>
    <p className={styles.paragraph}>
      Please read this Consent carefully and, after reading, print or save a copy of it to verify
      you can keep this type of electronic Record.
    </p>
    <p className={styles.paragraph}>
      Description of Electronic Records: The “Records” covered by this Consent include all credit
      applications, loan agreements, other transaction documents, servicing communications allowed
      or required by law, as well as any consumer notices, disclosures and information relating to
      the origination and servicing of the loans and other financial products you request or obtain
      from us, now and in the future, including those that must be signed or provided in writing.
      The “Records” covered by this Consent include, but are not limited to, the communications you
      and we exchange about your credit applications and our credit decision notices; your consent
      for our communications to you; all agreements, contracts, terms of use, promissory notes,
      transaction and servicing documents, consumer notices and agreements relating to loans you
      request or obtain from us; your payments and payment authorizations; our privacy policies and
      notices; your authorizations and consents for us to obtain and disclose information about you
      and your employment, income, and assets; billing notices and account statements; and all other
      communications or information relating to any financial products or services you request or
      obtain from us. We reserve the right to decide which Records to provide electronically and
      when to request your electronic signature for those
    </p>
    <p className={styles.paragraph}>
      System and Access Device Requirements: To view and print or save electronic Records, you must
      use a computer, cellular telephone or other type of mobile device that permits Internet access
      (each, an “Access Device,” and together, your “Access Devices”). You must have a data plan,
      products and services provided by a wireless carrier that allow you to use the Access Device
      for Internet access. Your Access Device must have a printer or long-term storage that allows
      you to print or save electronic Records for future reference. Your Access Device must allow
      you to use an up-to-date Internet browser (such as Chrome, Safari, Internet Explorer, Firefox)
      that is supported by and compatible with your Access Device’s operating system. You must have
      software that allows you to view and print or save PDF documents, such as Adobe Reader or
      similar. You must have a working electronic mail (“email”) account that allows you to view and
      print or save any electronic Records we send to your designated email address, including any
      electronic Records displayed in the email message itself, attached to the email message, or
      displayed when you select links included in the email message. When you use any Access Device
      to give this Consent for use of electronic Records and signatures, your Consent will apply to
      that Access Device and all other Access Devices you may elect to use after giving your initial
      Consent. If you change your Access Device after giving your initial Consent, you are
      responsible for verifying that any new or different Access Device you may use meets these
      system requirements and you are still able to access, receive, view, and keep the electronic
      Records by printing or saving them. If we change the hardware or software requirements in a
      way that creates a material risk that you will be unable to access or keep Records we
      previously provided to you in electronic form, we will display the revised requirements on our
      website or otherwise send notice to you of the revised requirements.
    </p>
    <p className={styles.paragraph}>
      Obtaining Paper Copies of Records: If you want to receive paper copies of Records you
      previously authorized us to provide in electronic form, you can request paper copies of those
      Records by calling us at 855-400-0449; using the contact us page of our website at
      <Link to="/">www.oneblinc.com</Link>; writing to us at 151, Crandon Blvd. #107, Miami FL,{' '}
      33149. Your request for a paper copy of any Record will not, by itself, mean you have
      withdrawn any prior Consent to receive Records electronically. If you withdraw your prior
      Consent for use of electronic Records and signatures, your withdrawal of consent will not
      apply to any electronic Records or signatures provided before the date your withdrawal of
      consent takes effect. We may require you to pay reasonable charges for preparing and mailing
      paper copies of your Records, unless applicable law prohibits us from imposing those charges.
      You may ask us to send additional copies of a Record to your designated email address at no
      additional cost to you, which will also allow you to print paper copies of the Record using
      your own Access Device and printer.
    </p>
    <p className={styles.paragraph}>
      How To Update Your Contact Information and Designated Email Address: If you have a working
      email address, we will require you to include your designated email address with your personal
      contact information. You agree to provide us with your correct and complete personal contact
      information and promptly notify us of any changes, including changes in your designated email
      address. You can update your contact information, including your email address, by calling us
      at 855-400-0449; using the contact us page of our website at{' '}
      <Link to="/">www.oneblinc.com</Link>; writing to us at 151, Crandon Blvd. #107, Miami FL,
      33149.
    </p>
    <p className={styles.paragraph}>
      Withdrawing Prior Consent for Electronic Records and Signatures: If, at any time after your
      initial consent for electronic Records and signatures, you want to withdraw your prior
      consent, you may do so by calling us at 855-400-0449; using the contact us page of our website
      at <Link to="/">www.oneblinc.com</Link>; writing to us at 151, Crandon Blvd. #107, Miami FL,
      33149. We will not impose any fee if you decide to withdraw your prior consent for our use of
      electronic Records and signatures. If you withdraw your prior consent for our use of
      electronic Records and signatures, any electronic Records and signatures provided and obtained
      before the effective date of your withdrawal will remain fully effective, valid and
      enforceable.
    </p>
    <p className={styles.paragraph}>
      Your Consent For Our Use of Electronic Records and Signatures: By electronically signing this
      Consent as described below, you agree that you reviewed this Consent and verified you can use
      your Access Device to print or save a copy of this Consent with your records. You give your
      express Consent to receive, view and electronically sign the Records we display and provide to
      you in electronic form using your Access Device, including the electronic Records we may
      display on our website or mobile application and those we may send to your designated email
      address. You agree that the electronic Records we send to your designated email address may
      include Records displayed in the email message, attached to it or displayed when you select
      links included in the message. When a Record is electronically displayed on your Access
      Device, you agree that your electronic signature for any such Record may include clicking on
      the displayed buttons, selecting the displayed boxes, typing your name in a designated field
      or otherwise selecting an electronic facsimile signature for the field, sending an email reply
      to a message transmitted to your designated email address, or taking other affirmative actions
      described when you view an electronic Record displayed on your Access Device. Your consent for
      our use of electronic Records and signatures will be effective unless you withdraw it in the
      manner described above.
    </p>
    <p className={styles.paragraph}>
      By checking the Consent box, you agree that you received, read and agree to the terms of this
      Consent for Use of Electronic Records and Signatures, and that you consent to your and our use
      of electronic Records and signatures when made through our websites, mobile applications, the
      email address you provided when you requested or obtained financial products and services from
      us and any updated email address you may provide in the future using the procedures described
      above.
    </p>
  </section>
)
