import { schema } from 'normalizr'

import Model from './model'

class Bank extends Model({
  id: undefined,
  bankName: '',
  account: '',
  manual: undefined,
  routing: '',
  type: '',
  user: '',
  wireRouting: '',
  mask: '',
  requireManualInfo: undefined,
  subtype: undefined,
  valid: undefined,
}) {}

export default Bank

const bankAccountNewSchema = new schema.Entity(
  'result',
  {},
  {
    processStrategy: entity => new Bank(entity),
  }
)

export const bankAccountSchema = [bankAccountNewSchema]
