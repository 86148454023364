import { defineAction } from '_utils/redux'
import * as statementDocuments from '_services/statement-document'

export const GET_STATEMENT_DOCUMENTS = defineAction('GET_STATEMENT_DOCUMENTS')

export const getStatementDocuments = id => (dispatch, getState) =>
  dispatch({
    type: GET_STATEMENT_DOCUMENTS.ACTION,
    payload: statementDocuments.getStatementDocuments(getState().authentication.get('accessToken'))(
      id
    ),
  })
