import { schema } from 'normalizr'
import Humps from 'humps'

import { capitalizeWords } from '_utils/helpers'

import Model from './model'

class AchTransfer extends Model({
  id: undefined,
  routingNumber: undefined,
  accountNumber: undefined,
  accountClass: undefined,
  amount: undefined,
  bankName: undefined,
  date: null,
}) {}

export default AchTransfer

const achTransferNewSchema = new schema.Entity(
  'results',
  {},
  {
    processStrategy: entity =>
      new AchTransfer({
        ...entity,
        accountClass: capitalizeWords(
          Humps.decamelize(entity.accountClass, { separator: ' ', split: '_' }).toLowerCase()
        ),
      }),
  }
)

export const achTransferSchema = [achTransferNewSchema]
