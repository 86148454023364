import { combineReducers } from 'redux'

import microdeposits from '_modules/microdeposits/reducer'
import presignedDocuments from '_modules/presigned-documents/reducer'
import referral from '_modules/referral/reducer'
import bank from '_modules/bank/reducer'
import authentication from '_modules/authentication/reducer'
import error from '_modules/error/reducer'
import loading from '_modules/loading/reducer'
import user from '_modules/user/reducer'
import employer from '_modules/employer/reducer'
import loan from '_modules/loan/reducer'
import card from '_modules/card/reducer'
import state from '_modules/state/reducer'
import banner from '_modules/banner/reducer'
import refinance from '_modules/refinance/reducer'
import salaryAdvance from '_modules/salary-advance/reducer'
import creditScore from '_modules/credit-score/reducer'
import checkingAccount from '_modules/checking-account/reducer'
import activateCard from '_modules/activate-card/reducer'
import transaction from '_modules/transaction/reducer'
import atm from '_modules/atm/reducer'
import pin from '_modules/pin/reducer'
import ach from '_modules/ach/reducer'
import cashback from '_modules/cashback/reducer'
import appSettings from '_modules/app-settings/reducer'
import achPull from '_modules/ach-pull/reducer'
import breadcrumbs from '_modules/breadcrumbs/reducer'
import statements from '_modules/statements/reducer'
import toolbox from '_modules/toolbox/reducer'
import check from '_modules/mail-check/reducer'
import cookieHandler from '_modules/cookie-handler/reducer'
import renegotiation from '_modules/renegotiation/reducer'
import interchange from '_modules/interchange/reducer'
import growth from '_modules/growth/reducer'
import onePayment from '_modules/one-payment/reducer'
import openPayroll from '_modules/open-payroll/reducer'
import cardPayment from '_modules/card-payment/reducer'
import statementDocument from '_modules/statement-document/reducer'
import blincAdvance from '_modules/blinc-advance/reducer'
import checkStateEligible from '_modules/check-state-eligible/reducer'
import dashboard from '_modules/card-stack/reducer'
import nls from '_modules/nls/reducer'
import chat from '_modules/chat/reducer'
import subscription from '_modules/subscription/reducer'
import forbearance from '_modules/forbearance/reducer'
import version from '_modules/version/reducer'
import autopay from '_modules/autopay/reducer'

const rootReducer = combineReducers({
  bank,
  employer,
  authentication,
  error,
  loading,
  user,
  loan,
  referral,
  presignedDocuments,
  card,
  state,
  banner,
  refinance,
  salaryAdvance,
  creditScore,
  checkingAccount,
  activateCard,
  transaction,
  atm,
  pin,
  ach,
  cashback,
  achPull,
  appSettings,
  breadcrumbs,
  statements,
  toolbox,
  check,
  cookieHandler,
  renegotiation,
  interchange,
  growth,
  onePayment,
  openPayroll,
  cardPayment,
  statementDocument,
  blincAdvance,
  checkStateEligible,
  dashboard,
  nls,
  chat,
  subscription,
  forbearance,
  version,
  autopay,
  microdeposits,
})

export default rootReducer
