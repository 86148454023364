import { Map } from 'immutable'

import Model from './model'

class CheckingAccount extends Model({
  user: undefined,
  id: undefined,
  agreements: undefined,
  cardSubnet: undefined,
  virtualCardSubnet: undefined,
  cardSubnetAgreement: undefined,
  acrtSubnet: undefined,
  currentActivationStep: null,
  bank: undefined,
  bankAddress: undefined,
  balance: 0,
  isBalanceHidden: true,
  isAccountDetailsHidden: true,
  atm: undefined,
  card: undefined,
  pin: undefined,
  kycStatus: undefined,
  driversLicenseRejectionReason: null,
  status: null,
  isFromCustomersKyc: false,
  activatedPhysicalCard: undefined,
  isLocked: undefined,
  lockDescription: undefined,
  isBannerHidden: false,
  shipmentStatus: new Map(),
}) {}

export default CheckingAccount
