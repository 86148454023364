import Model from './model'

class Authentication extends Model({
  accessToken: undefined,
  refreshToken: undefined,
  maskedEmail: undefined,
  fullEmail: undefined,
}) {}

export default Authentication
