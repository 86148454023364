import { Cookies } from 'react-cookie'

import { ROOT_DOMAIN } from '_config/environment'
import { createReducer } from '_utils/redux'
import { LOGIN, SOCIAL_LOGIN } from '_modules/user/actions'
import { Authentication } from '_models/'

import { REGISTER, REFRESH_ACCESS_TOKEN, GET_MASKED_EMAIL, SEND_EMAIL_TO_SMS } from './actions'

const INITIAL_STATE = new Authentication()
const cookies = new Cookies()

const updateAuthenticationTokens = authenticationTokens => {
  cookies.set('authentication', authenticationTokens, { domain: ROOT_DOMAIN })
}

export default createReducer(INITIAL_STATE, {
  [REGISTER.FULFILLED]: (state, { payload: { access, refresh } }) => {
    const authenticationTokens = { accessToken: access, refreshToken: refresh }
    updateAuthenticationTokens(authenticationTokens)
    cookies.remove('originUrl', { path: '/' })
    return new Authentication(authenticationTokens)
  },

  [LOGIN.FULFILLED]: (_, { payload: { access, refresh } }) => {
    const authenticationTokens = { accessToken: access, refreshToken: refresh }
    updateAuthenticationTokens(authenticationTokens)
    return new Authentication(authenticationTokens)
  },
  [SOCIAL_LOGIN.FULFILLED]: (state, { payload }) => {
    if (payload.user) {
      const { access, refresh } = payload
      const authenticationTokens = { accessToken: access, refreshToken: refresh }
      updateAuthenticationTokens(authenticationTokens)
      return new Authentication(authenticationTokens)
    }
    return state
  },

  [REFRESH_ACCESS_TOKEN.FULFILLED]: (state, { payload: { access } }) => {
    const authenticationTokens = { accessToken: access, refreshToken: state.get('refreshToken') }
    updateAuthenticationTokens(authenticationTokens)
    return state.merge(authenticationTokens)
  },
  [GET_MASKED_EMAIL.FULFILLED]: (state, { payload }) => {
    return state.set('maskedEmail', payload)
  },
  [SEND_EMAIL_TO_SMS.FULFILLED]: (state, { payload }) => {
    return state.set('fullEmail', payload)
  },
})
