import { format } from 'date-fns'

import { defineAction } from '_utils/redux'
import * as checkingAccountService from '_services/checking-account'

export const GET_TRANSACTIONS = defineAction('GET_TRANSACTIONS')
export const GET_TRANSACTION_DETAILS = defineAction('GET_TRANSACTION_DETAILS')
export const CANCEL_TRANSACTION = defineAction('CANCEL_TRANSACTION')
export const GET_LAST_TRANSACTIONS = defineAction('GET_LAST_TRANSACTIONS')
export const LOAD_MORE_TRANSACTIONS = defineAction('LOAD_MORE_TRANSACTIONS')

export const getTransactions =
  (startDate = null, endDate = null) =>
  (dispatch, getState) =>
    dispatch({
      type: GET_TRANSACTIONS.ACTION,
      payload: checkingAccountService.getTransactions(
        getState().authentication.get('accessToken'),
        {
          startDate: format(startDate, 'yyyy-MM-dd', new Date()),
          endDate: format(endDate, 'yyyy-MM-dd', new Date()),
        }
      ),
    })

export const getTransactionDetails = transactionId => (dispatch, getState) =>
  dispatch({
    type: GET_TRANSACTION_DETAILS.ACTION,
    payload: checkingAccountService.getTransactionDetails(
      getState().authentication.get('accessToken'),
      { transactionId }
    ),
  })

export const cancelTransaction = transactionId => (dispatch, getState) =>
  dispatch({
    type: CANCEL_TRANSACTION.ACTION,
    payload: checkingAccountService.cancelTransaction(
      getState().authentication.get('accessToken'),
      { transactionId }
    ),
  })

export const getLastTransactions = () => (dispatch, getState) =>
  dispatch({
    type: GET_TRANSACTIONS.ACTION,
    payload: checkingAccountService.getTransactions(getState().authentication.get('accessToken'), {
      limit: 3,
    }),
  })

export const loadMoreTransactions = (startDate, endDate, page) => (dispatch, getState) =>
  dispatch({
    type: LOAD_MORE_TRANSACTIONS.ACTION,
    payload: checkingAccountService.getTransactions(getState().authentication.get('accessToken'), {
      startDate: format(startDate, 'yyyy-MM-dd', new Date()),
      endDate: format(endDate, 'yyyy-MM-dd', new Date()),
      page,
    }),
  })
