import * as state from '_services/state'
import { defineAction } from '_utils/redux'

export const GET_STATES = defineAction('GET_STATES')
export const GET_STATES_ALL = defineAction('GET_STATES_ALL')

export const getStates = () => (dispatch, getState) =>
  dispatch({
    type: GET_STATES.ACTION,
    payload: state.getStates(getState().authentication.get('accessToken')),
  })

export const getStatesAll = () => (dispatch, getState) =>
  dispatch({
    type: GET_STATES_ALL.ACTION,
    payload: state.getStatesAll(getState().authentication.get('accessToken')),
  })
