import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import { createReducer } from '_utils/redux'
import { employerSchema } from '_models'

import { GET_EMPLOYERS } from './actions'

const INITIAL_STATE = Map({ results: Map(), order: List() })

export default createReducer(INITIAL_STATE, {
  [GET_EMPLOYERS.FULFILLED]: (state, { payload }) => {
    const normalizedEmployers = normalize(payload, employerSchema)
    return Map({
      results: Map(normalizedEmployers.entities.result),
      order: List(normalizedEmployers.result),
    })
  },
})
