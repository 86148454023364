import * as bankService from '_services/bank'
import { defineAction } from '_utils/redux'

export const UPLOAD_ACCESS_TOKEN = defineAction('UPLOAD_ACCESS_TOKEN')
export const MANUAL_BANK_ACCOUNTS = defineAction('MANUAL_BANK_ACCOUNTS')
export const GET_BANK_ACCOUNTS = defineAction('GET_BANK_ACCOUNTS')
export const VALIDATE_TOKEN = defineAction('VALIDATE_TOKEN')
export const UPDATE_MANUAL_BANK_ACCOUNT = defineAction('UPDATE_MANUAL_BANK_ACCOUNT')
export const CALL_IDENTITY = defineAction('CALL_IDENTITY')
export const GET_LINK_TOKEN = defineAction('GET_LINK_TOKEN')
export const CREATE_LINK_TOKEN = defineAction('CREATE_LINK_TOKEN')
export const CLEAR_LINK_TOKEN = defineAction('CLEAR_LINK_TOKEN')
export const CHECK_REQUIRED_ACCOUNT_INFO = defineAction('CHECK_REQUIRED_ACCOUNT_INFO')
export const GET_BANK_ACCOUNTS_NEEDING_RECONNECTION = defineAction(
  'GET_BANK_ACCOUNTS_NEEDING_RECONNECTION'
)
export const SET_FALLBACK_ACCOUNT = defineAction('SET_FALLBACK_ACCOUNT')
export const CHECK_SALARY_ACCOUNT_STATUS = defineAction('CHECK_SALARY_ACCOUNT_STATUS')
export const CLEAR_SALARY_ACCOUNT_STATUS = defineAction('CLEAR_SALARY_ACCOUNT_STATUS')

export const uploadAccessToken = payload => (dispatch, getState) =>
  dispatch({
    type: UPLOAD_ACCESS_TOKEN.ACTION,
    payload: bankService.uploadAccessToken(getState().authentication.get('accessToken'), payload),
  })

export const callIdentity = payload => (dispatch, getState) =>
  dispatch({
    type: CALL_IDENTITY.ACTION,
    payload: bankService.callIdentity(getState().authentication.get('accessToken'), payload),
  })

export const manualBankAccounts = payload => (dispatch, getState) =>
  dispatch({
    type: MANUAL_BANK_ACCOUNTS.ACTION,
    payload: bankService.manualBankAccounts(getState().authentication.get('accessToken'), payload),
  })

export const getBankAccounts =
  (params = '') =>
  (dispatch, getState) =>
    dispatch({
      type: GET_BANK_ACCOUNTS.ACTION,
      payload: bankService.getBankAccounts({
        key: getState().authentication.get('accessToken'),
        params,
      }),
    })

export const getBankAccountsNeedingReconnection = params => (dispatch, getState) =>
  dispatch({
    type: GET_BANK_ACCOUNTS_NEEDING_RECONNECTION.ACTION,
    payload: bankService.getBankAccountsNeedingReconnection({
      key: getState().authentication.get('accessToken'),
      params,
    }),
  })

export const validateToken = payload => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_TOKEN.ACTION,
    payload: bankService.validateToken(getState().authentication.get('accessToken'), payload),
  })

export const checkRequiredAccountInfo = params => (dispatch, getState) =>
  dispatch({
    type: CHECK_REQUIRED_ACCOUNT_INFO.ACTION,
    payload: bankService.checkRequiredAccountInfo(
      getState().authentication.get('accessToken'),
      params
    ),
  })

export const getLinkToken = params => (dispatch, getState) =>
  dispatch({
    type: GET_LINK_TOKEN.ACTION,
    payload: bankService.getLinkToken(getState().authentication.get('accessToken'), params),
  })

export const createLinkToken = params => (dispatch, getState) =>
  dispatch({
    type: CREATE_LINK_TOKEN.ACTION,
    payload: bankService.createLinkToken(getState().authentication.get('accessToken'), params),
  })

export const clearLinkToken = () => dispatch =>
  dispatch({
    type: CLEAR_LINK_TOKEN,
  })

export const updateManualBankAccount = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: UPDATE_MANUAL_BANK_ACCOUNT.ACTION,
    payload: bankService.updateManualBankAccount(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const setFallbackAccount = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: SET_FALLBACK_ACCOUNT.ACTION,
    payload: bankService.setFallbackAccount(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const checkSalaryAccountStatus = () => (dispatch, getState) =>
  dispatch({
    type: CHECK_SALARY_ACCOUNT_STATUS.ACTION,
    payload: bankService.checkSalaryAccountStatus(getState().authentication.get('accessToken')),
  })

export const clearSalaryAccountStatus = () => dispatch =>
  dispatch({
    type: CLEAR_SALARY_ACCOUNT_STATUS,
  })
