import { defineAction } from '_utils/redux'
import * as getCheckStateEligibleService from '_services/check-state-eligible'

export const GET_CHECK_STATE_ELIGIBLE = defineAction('GET_CHECK_STATE_ELIGIBLE')

export const getCheckStateEligible = () => (dispatch, getState) =>
  dispatch({
    type: GET_CHECK_STATE_ELIGIBLE.ACTION,
    payload: getCheckStateEligibleService.getCheckStateEligible(
      getState().authentication.get('accessToken')
    ),
  })
