export const getMicrodepositsStatusSelector = state => state.microdeposits?.get('microdeposit')
export const shouldValidateMicrodepositsStatusSelector = state => {
  return (
    (!state?.microdeposits?.get('microdeposit')?.get('sentAt') ||
      !state?.microdeposits?.get('microdeposit')?.get('validatedAt') ||
      !state?.microdeposits?.get('microdeposit')?.get('collectedAt') ||
      state?.microdeposits?.get('microdeposit')?.get('failureReason')) &&
    state.microdeposits?.get('microdepositsFlowAvailable')
  )
}

export const getSalaryAccountStatusSelector = state =>
  state.microdeposits.get('salaryAccountStatus')

export const isMicrodepositsFlowAvailableSelector = state =>
  state.microdeposits?.get('microdepositsFlowAvailable')

export const getMicrodepositsExpirationTimeInHoursSelector = state =>
  state.microdeposits?.get('microdepositsExpirationTimeInHours')

export const getMicrodepositCashbackAmountSelector = state =>
  state.microdeposits?.get('microdepositCashbackAmount')
