import { defineAction } from '_utils/redux'
import * as achService from '_services/ach'

export const UPDATE_ACH_TRANSFER_INFO = defineAction('UPDATE_ACH_TRANSFER_INFO')
export const SET_SELECTED_ACH_ACCOUNT = defineAction('SET_SELECTED_ACH_ACCOUNT')
export const SET_ACH_TRANSFER_AMOUNT = defineAction('SET_ACH_TRANSFER_AMOUNT')
export const VALIDATE_ROUTING_NUMBER = defineAction('VALIDATE_ROUTING_NUMBER')
export const CLEAR_ACH_TRANSFER = defineAction('CLEAR_ACH_TRANSFER')
export const TRANSFER_ACH_TO_MYSELF = defineAction('TRANSFER_ACH_TO_MYSELF')
export const GET_BOOKMARKED_ACCOUNTS = defineAction('GET_BOOKMARKED_ACCOUNTS')
export const REMOVE_BOOKMARKED_ACCOUNT = defineAction('REMOVE_BOOKMARKED_ACCOUNT')
export const BOOKMARK_ACCOUNT = defineAction('BOOKMARK_ACCOUNT')
export const SET_ACH_SCHEDULED_DATE = defineAction('SET_ACH_SCHEDULED_DATE')
export const CLEAR_ACH_SHCEDULED_DATE = defineAction('CLEAR_ACH_SHCEDULED_DATE')

export const updateAchTransferInfo = payload => dispatch =>
  dispatch({
    type: UPDATE_ACH_TRANSFER_INFO,
    payload,
  })

export const setSelectedAchAccount = payload => dispatch =>
  dispatch({
    type: SET_SELECTED_ACH_ACCOUNT,
    payload,
  })

export const setAchTransferAmount = payload => dispatch =>
  dispatch({
    type: SET_ACH_TRANSFER_AMOUNT,
    payload,
  })

export const validateRoutingNumber = payload => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_ROUTING_NUMBER.ACTION,
    payload: achService.validateRoutingNumber(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const transferAchToMyself = payload => (dispatch, getState) =>
  dispatch({
    type: TRANSFER_ACH_TO_MYSELF,
    payload: achService.transferAchToMyself(getState().authentication.get('accessToken'), payload),
  })

export const clearAchTransfer = payload => dispatch =>
  dispatch({
    type: CLEAR_ACH_TRANSFER,
    payload,
  })

export const getBookmarkedAccounts = payload => (dispatch, getState) =>
  dispatch({
    type: GET_BOOKMARKED_ACCOUNTS,
    payload: achService.getBookmarkedAccounts(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const removeBookmarkedAccount = payload => (dispatch, getState) =>
  dispatch({
    type: REMOVE_BOOKMARKED_ACCOUNT,
    payload: achService.removeBookmarkedAccount(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const bookmarkAccount = payload => (dispatch, getState) =>
  dispatch({
    type: BOOKMARK_ACCOUNT,
    payload: achService.bookmarkAccount(getState().authentication.get('accessToken'), payload),
  })

export const setAchScheduledDate = payload => dispatch =>
  dispatch({
    type: SET_ACH_SCHEDULED_DATE,
    payload,
  })

export const clearAchScheduledDate = () => dispatch =>
  dispatch({
    type: CLEAR_ACH_SHCEDULED_DATE,
  })
