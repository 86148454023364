import BlincAdvance from '_models/blinc-advance'
import { createReducer } from '_utils/redux'

import { GET_CHECK_ELIGIBLE_LOAN_DENY } from './actions'

const INITIAL_STATE = new BlincAdvance()

export default createReducer(INITIAL_STATE, {
  [GET_CHECK_ELIGIBLE_LOAN_DENY.FULFILLED]: (state, { payload }) => {
    return state.set('checkEligibleLoanDeny', payload)
  },
})
