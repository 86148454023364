import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'

import { getLoanSelector } from '_modules/loan/selectors'

const UpdateAutopayTermsProtectedRoutes = ({ children }) => {
  const currentLoan = useSelector(getLoanSelector)

  useEffect(() => {
    const rennewDocument = currentLoan.get('rennewDocument')

    if (rennewDocument) {
      navigate('/update-terms')
    }
  }, [currentLoan])

  return children
}

UpdateAutopayTermsProtectedRoutes.propTypes = {
  children: PropTypes.node.isRequired,
}
UpdateAutopayTermsProtectedRoutes.defaultProps = {}

export default UpdateAutopayTermsProtectedRoutes
