import { Map } from 'immutable'

import Model from './model'

class Forbearance extends Model({
  forbearance: Map({
    id: undefined,
    loan: undefined,
    pmtAmount: undefined,
    status: undefined,
    paymentMethod: undefined,
    argyleConnected: undefined,
    allotmentFile: undefined,
    bankAccount: undefined,
    completeDate: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    autoPayDocument: undefined,
  }),
  encryptedConfig: undefined,
  allotmentInfo: undefined,
  flow: Map({
    pmtAmount: undefined,
    minimumAmount: undefined,
    loanId: undefined,
    allotmentFile: undefined,
    bankAccountId: undefined,
  }),
}) {}

export default Forbearance
