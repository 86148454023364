import * as authenticationService from '_services/authentication'
import { defineAction } from '_utils/redux'

export const REGISTER = defineAction('REGISTER')
export const LOGOUT = 'LOGOUT'
export const REFRESH_ACCESS_TOKEN = defineAction('REFRESH_ACCESS_TOKEN')
export const RESET_PASSWORD = defineAction('RESET_PASSWORD')
export const CHANGE_PASSWORD = defineAction('CHANGE_PASSWORD')
export const GET_MASKED_EMAIL = defineAction('GET_MASKED_EMAIL')
export const SEND_EMAIL_TO_SMS = defineAction('SEND_EMAIL_TO_SMS')

export const register = payload => dispatch =>
  dispatch({
    type: REGISTER.ACTION,
    payload: authenticationService.register(payload),
  })

export const refreshAccessToken = () => (dispatch, getState) =>
  dispatch({
    type: REFRESH_ACCESS_TOKEN.ACTION,
    payload: authenticationService.refreshAccessToken({
      refresh: getState().authentication.get('refreshToken'),
    }),
  })

export const resetPassword = payload => dispatch =>
  dispatch({
    type: RESET_PASSWORD.ACTION,
    payload: authenticationService.resetPassword(payload),
  })

export const changePassword = payload => dispatch =>
  dispatch({
    type: CHANGE_PASSWORD.ACTION,
    payload: authenticationService.changePassword(payload),
  })

export const getMaskedEmail = payload => dispatch =>
  dispatch({
    type: GET_MASKED_EMAIL.ACTION,
    payload: authenticationService.getMaskedEmail(payload),
  })

export const sendEmailToSms = payload => dispatch =>
  dispatch({
    type: SEND_EMAIL_TO_SMS.ACTION,
    payload: authenticationService.sendEmailToSms(payload),
  })
