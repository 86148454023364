import { List } from 'immutable'

import { createReducer } from '_utils/redux'

import { GET_PRESIGNED_DOCUMENTS } from './actions'

const INITIAL_STATE = List()

const presignedDocument = createReducer(INITIAL_STATE, {
  [GET_PRESIGNED_DOCUMENTS.PENDING]: () => {
    return INITIAL_STATE
  },
  [GET_PRESIGNED_DOCUMENTS.FULFILLED]: (state, { payload }) => {
    return state.merge(payload.documents)
  },
})

export default presignedDocument
