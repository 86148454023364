import { schema } from 'normalizr'

import Model from './model'

class Employer extends Model({
  id: undefined,
  name: '',
  acronym: '',
  label: '',
}) {}

export default Employer

const employerNewSchema = new schema.Entity(
  'result',
  {},
  {
    processStrategy: entity =>
      new Employer({ ...entity, label: `${entity.acronym} - ${entity.name}` }),
    idAttribute: entity => `${entity.acronym} - ${entity.name}`,
  }
)

export const employerSchema = [employerNewSchema]
