import Model from './model'

class Banner extends Model({
  id: undefined,
  marketingAmount: undefined,
  marketingFee: undefined,
  marketingMaximumApr: undefined,
  marketingPayments: undefined,
  marketingPmt: undefined,
  calendarType: undefined,
}) {}

export default Banner
