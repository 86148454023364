import { Map } from 'immutable'

import { createReducer } from '_utils/redux'
import { Banner } from '_models'

import { BANNER } from './actions'

const INITIAL_STATE = Map()

export default createReducer(INITIAL_STATE, {
  [BANNER.FULFILLED]: (state, { payload }) => {
    return state.merge(new Banner(payload))
  },
})
