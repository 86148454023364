import React, { useMemo, useCallback, useEffect } from 'react'
import { Link } from '@reach/router'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'
import OneBlincSVG from '_assets/svgs/one-blinc-logo.svg?react'
import CloseSVG from '_assets/svgs/close-icon.svg?react'
import HamburgerSVG from '_assets/svgs/hamburger-icon.svg?react'
import AriaModal from 'react-aria-modal'

import { useModal } from '_hooks/use-modal'
import CashbackNavBar from '_components/nav-bar/cashback-nav-bar'
import { isDesktop } from '_utils/window'

import styles from './styles.module.css'

const UNDERLAY_STYLE = {
  textAlign: 'right',
  top: '8rem',
}

const DashboardNavHeader = ({ className }) => {
  const { isModalOpen, toggle, setIsModalOpen } = useModal(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (isDesktop(width)) {
      setIsModalOpen(false)
    }
  }, [setIsModalOpen, width])

  const onCloseMenu = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const onHamburgerClick = useCallback(() => {
    toggle()
  }, [toggle])

  const ActionIcon = useMemo(() => (isModalOpen ? CloseSVG : HamburgerSVG), [isModalOpen])

  return (
    <>
      <header className={styles['header-container']}>
        <div className={styles.header}>
          <Link to="/" className={styles.logo} aria-label="Home">
            <OneBlincSVG className={styles['logo-icon']} />
          </Link>
          <button
            type="button"
            className={classnames(styles.button, className)}
            onClick={onHamburgerClick}
            aria-label={isModalOpen ? 'Open menu' : 'Close menu'}
          >
            <ActionIcon className={styles.icon} />
          </button>
          {isModalOpen && (
            <AriaModal
              focusDialog
              titleId="Navigation menu"
              underlayStyle={UNDERLAY_STYLE}
              onExit={onCloseMenu}
              focusTrapPaused
            >
              <CashbackNavBar onCloseMenu={onCloseMenu} />
            </AriaModal>
          )}
        </div>
      </header>
      <CashbackNavBar className={styles.nav} />
    </>
  )
}

DashboardNavHeader.propTypes = {
  className: PropTypes.string,
}

DashboardNavHeader.defaultProps = {
  className: '',
}

export default DashboardNavHeader
