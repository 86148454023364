import { defineAction } from '_utils/redux'
import * as versionService from '_services/version'

export const GET_VERSION = defineAction('GET_VERSION')

export const getVersion = version => (dispatch, getState) =>
  dispatch({
    type: GET_VERSION.ACTION,
    payload: versionService.versionClient(getState().authentication.get('accessToken'), version),
    meta: {
      loadingState: getState().loading,
    },
  })
