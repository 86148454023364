import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import { API_URL, FORCE_CACHE_BUSTER, FRONTEND_VERSION_API_KEY } from '_config/environment'

import packageJson from '../../../package.json'

async function loadVersionVerify() {
  const { version } = packageJson
  const BASE_URL = API_URL

  const response = await fetch(
    `${BASE_URL}/frontend-version/check/?project=dashboard&client-version=${version}`,
    {
      headers: { Authorization: FRONTEND_VERSION_API_KEY },
    }
  )

  const jsonData = await response.json()

  if (jsonData.clear_cache) {
    Sentry.captureMessage(`User version ${version} mismatched the current version`, 'warning')
  }

  return jsonData
}

const clearCacheAndReload = async () => {
  console.info('Clearing cache and hard reloading...')
  if (window.caches) {
    caches.keys().then(names => {
      for (const name of names) caches.delete(name)
    })
  }
  window.location.reload(true)
}

export const CacheBuster = ({ children }) => {
  const externalClearCacheIfNecessary = async () => {
    try {
      const { clear_cache: clearCache } = await loadVersionVerify()

      if (clearCache && FORCE_CACHE_BUSTER === 'true') {
        clearCacheAndReload()
      }
    } catch (e) {
      console.error('Error checking cache version', e)
    }
  }

  useEffect(() => {
    externalClearCacheIfNecessary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}
CacheBuster.propTypes = {
  children: PropTypes.node.isRequired,
}
