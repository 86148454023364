import * as userService from '_services/user'
import { defineAction } from '_utils/redux'

export const LOGIN = defineAction('LOGIN')
export const SOCIAL_LOGIN = defineAction('SOCIAL_LOGIN')
export const LOGOUT = 'LOGOUT'
export const ONBOARDING = defineAction('ONBOARDING')
export const GET_USER = defineAction('GET_USER')
export const GET_MAILING_ADDRESS = defineAction('GET_MAILING_ADDRESS')
export const CONFIRM_MAILING_ADDRESS = defineAction('CONFIRM_MAILING_ADDRESS')
export const EDIT_MAILING_ADDRESS = defineAction('EDIT_MAILING_ADDRESS')
export const UPDATE_USER = defineAction('UPDATE_USER')
export const VERIFY_PHONE = defineAction('VERIFY_PHONE')
export const VERIFY_CODE = defineAction('VERIFY_CODE')
export const SUBSCRIBE_NEWSLETTER_INTEREST = defineAction('SUBSCRIBE_NEWSLETTER_INTEREST')
export const UPLOAD_DRIVER_LICENSE = defineAction('UPLOAD_DRIVER_LICENSE')
export const ADDITIONAL_INFORMATION = defineAction('ADDITIONAL_INFORMATION')
export const CONFIRM_INFORMATION = defineAction('CONFIRM_INFORMATION')
export const SAVE_ORIGIN_URL = 'SAVE_ORIGIN_URL'
export const ACCEPT_BIWEEKLY_ALLOTMENTS = defineAction('ACCEPT_BIWEEKLY_ALLOTMENTS')
export const CONFIRM_PASSWORD = defineAction('CONFIRM_PASSWORD')
export const UPDATE_CONTACT_INFO = defineAction('UPDATE_CONTACT_INFO')
export const GET_CONTACT_INFO = defineAction('GET_CONTACT_INFO')
export const GET_REFERENCES = defineAction('GET_REFERENCES')
export const UPDATE_REFERENCES = defineAction('UPDATE_REFERENCES')
export const GET_REFERENCES_RELATION_LIST = defineAction('GET_REFERENCES_RELATION_LIST')
export const SAVE_ALLOTMENT_HIDDEN = defineAction('SAVE_ALLOTMENT_HIDDEN')

export const login = payload => dispatch =>
  dispatch({
    type: LOGIN.ACTION,
    payload: userService.login(payload),
  })

export const socialLogin = payload => dispatch =>
  dispatch({
    type: SOCIAL_LOGIN.ACTION,
    payload: userService.socialLogin(payload),
  })

export const onboarding = payload => (dispatch, getState) =>
  dispatch({
    type: ONBOARDING.ACTION,
    payload: userService.onboarding(getState().authentication.get('accessToken'), payload),
  })

export const getUser = () => (dispatch, getState) =>
  dispatch({
    type: GET_USER.ACTION,
    payload: userService.getUser(getState().authentication.get('accessToken')),
  })

export const updateUser = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER.ACTION,
    payload: userService.updateUser(getState().authentication.get('accessToken'), payload),
  })

export const verifyPhone = () => (dispatch, getState) =>
  dispatch({
    type: VERIFY_PHONE.ACTION,
    payload: userService.verifyPhone(getState().authentication.get('accessToken')),
  })

export const verifyCode = payload => (dispatch, getState) =>
  dispatch({
    type: VERIFY_CODE.ACTION,
    payload: userService.verifyCode(getState().authentication.get('accessToken'), payload),
  })

export const subscribeNewsletterInterest = payload => dispatch =>
  dispatch({
    type: SUBSCRIBE_NEWSLETTER_INTEREST.ACTION,
    payload: userService.subscribeNewsletterInterest(payload),
  })

export const uploadDriverLicense = payload => (dispatch, getState) =>
  dispatch({
    type: UPLOAD_DRIVER_LICENSE.ACTION,
    payload: userService.uploadDriverLicense(getState().authentication.get('accessToken'), payload),
  })

export const additionalInformation = payload => (dispatch, getState) =>
  dispatch({
    type: ADDITIONAL_INFORMATION.ACTION,
    payload: userService.additionalInformation(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const confirmInformation = payload => (dispatch, getState) =>
  dispatch({
    type: CONFIRM_INFORMATION.ACTION,
    payload: userService.confirmInformation(getState().authentication.get('accessToken'), payload),
  })

export const saveOriginUrl = payload => dispatch =>
  dispatch({
    type: SAVE_ORIGIN_URL,
    payload,
  })

export const acceptBiweeklyAllotments = (dispatch, getState) =>
  dispatch({
    type: ACCEPT_BIWEEKLY_ALLOTMENTS,
    payload: userService.acceptBiweeklyAllotments(
      getState().authentication.get('accessToken'),
      getState().user.get('id')
    ),
  })

export const getMailingAddress = () => (dispatch, getState) =>
  dispatch({
    type: GET_MAILING_ADDRESS.ACTION,
    payload: userService.getMailingAddress(getState().authentication.get('accessToken')),
  })

export const confirmMailingAddress = payload => (dispatch, getState) =>
  dispatch({
    type: CONFIRM_MAILING_ADDRESS.ACTION,
    payload: userService.confirmMailingAddress(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const editMailingAddress = payload => (dispatch, getState) =>
  dispatch({
    type: EDIT_MAILING_ADDRESS.ACTION,
    payload: userService.editMailingAddress(getState().authentication.get('accessToken'), payload),
  })

export const confirmPassword = payload => (dispatch, getState) =>
  dispatch({
    type: CONFIRM_PASSWORD.ACTION,
    payload: userService.confirmPassword(getState().authentication.get('accessToken'), payload),
  })

export const getContactInfo = () => (dispatch, getState) =>
  dispatch({
    type: GET_CONTACT_INFO.ACTION,
    payload: userService.getContactInfo(getState().authentication.get('accessToken')),
  })

export const updateContactInfo = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_CONTACT_INFO.ACTION,
    payload: userService.updateContactInfo(getState().authentication.get('accessToken'), payload),
  })

export const getReferences = () => (dispatch, getState) =>
  dispatch({
    type: GET_REFERENCES.ACTION,
    payload: userService.getReferences(getState().authentication.get('accessToken')),
  })
export const getReferencesRelationList = () => (dispatch, getState) =>
  dispatch({
    type: GET_REFERENCES_RELATION_LIST.ACTION,
    payload: userService.getReferencesRelationList(getState().authentication.get('accessToken')),
  })
export const updateReferences = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_REFERENCES.ACTION,
    payload: userService.updateReferences(getState().authentication.get('accessToken'), payload),
  })

export const saveAllotmentHidden = payload => dispatch =>
  dispatch({
    type: SAVE_ALLOTMENT_HIDDEN,
    payload,
  })
