import { fromJS } from 'immutable'

import { createReducer } from '_utils/redux'
import Microdeposits from '_models/microdeposits'

import { CHECK_MICRODEPOSIT, SEND_MICRODEPOSIT } from './actions'

const INITIAL_STATE = new Microdeposits()

export default createReducer(INITIAL_STATE, {
  [CHECK_MICRODEPOSIT.FULFILLED]: (state, { payload }) => {
    return state.merge(fromJS(payload))
  },

  [SEND_MICRODEPOSIT.FULFILLED]: (state, { payload }) => {
    return state.set('microdeposit', fromJS(payload.microdeposit))
  },
})
