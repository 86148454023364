import { defineAction } from '_utils/redux'
import * as cashbackService from '_services/cashback'

export const GET_CASHBACK_STORES = defineAction('GET_CASHBACK_STORES')
export const SET_LEAD_TOKEN = defineAction('SET_LEAD_TOKEN')
export const GET_CASHBACK_USER = defineAction('GET_CASHBACK_USER')
export const ENTER_WAITLIST = defineAction('ENTER_WAITLIST')
export const REDEEM_CASHBACK = defineAction('REDEEM_CASHBACK')
export const GET_LEAD_CARD_FORM_URL = defineAction('GET_LEAD_CARD_FORM_URL')
export const GET_LEAD_USER_CARDS = defineAction('GET_LEAD_USER_CARDS')
export const AUTO_CREATE_CASHBACK = defineAction('AUTO_CREATE_CASHBACK')

export const getCashbackStores = () => dispatch =>
  dispatch({
    type: GET_CASHBACK_STORES.ACTION,
    payload: cashbackService.getCashbackStores(),
  })

export const setLeadToken = payload => dispatch =>
  dispatch({
    type: SET_LEAD_TOKEN.ACTION,
    payload,
  })

export const getCashbackUser = payload => dispatch =>
  dispatch({
    type: GET_CASHBACK_USER.ACTION,
    payload: cashbackService.getCashbackUser(payload),
  })

export const getCashbackUserByLogin = () => (dispatch, getState) =>
  dispatch({
    type: GET_CASHBACK_USER.ACTION,
    payload: cashbackService.getCashbackUserByLogin(getState().authentication.get('accessToken')),
  })

export const enterWaitlist = payload => dispatch =>
  dispatch({
    type: ENTER_WAITLIST.ACTION,
    payload: cashbackService.enterWaitlist(payload),
  })

export const enterWaitlistByLogin = () => (dispatch, getState) =>
  dispatch({
    type: ENTER_WAITLIST.ACTION,
    payload: cashbackService.enterWaitlistByLogin(getState().authentication.get('accessToken')),
  })

export const redeemCashback = payload => dispatch =>
  dispatch({
    type: REDEEM_CASHBACK.ACTION,
    payload: cashbackService.redeemCashback(payload),
  })

export const redeemCashbackByLogin = payload => (dispatch, getState) =>
  dispatch({
    type: REDEEM_CASHBACK.ACTION,
    payload: cashbackService.redeemCashbackByLogin(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const getLeadCardFormUrl = payload => dispatch =>
  dispatch({
    type: GET_LEAD_CARD_FORM_URL.ACTION,
    payload: cashbackService.getCardFormUrlByLead(payload),
  })

export const getUserCardsByLead = payload => dispatch =>
  dispatch({
    type: GET_LEAD_USER_CARDS.ACTION,
    payload: cashbackService.getUserCardsByLead(payload),
  })

export const autoCreateCashback = payload => (dispatch, getState) =>
  dispatch({
    type: AUTO_CREATE_CASHBACK.ACTION,
    payload: cashbackService.autoCreateCashback(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
