import { Map } from 'immutable'

import Cards from '_models/card'
import { createReducer } from '_utils/redux'

import { SET_SELECTED_CARD, SET_PULL_AMOUNT, CLEAR_ACH_PULL_TRANSFER } from './actions'

const INITIAL_STATE = Map({
  selectedCard: new Cards(),
  amount: null,
})

export default createReducer(INITIAL_STATE, {
  [SET_SELECTED_CARD.ACTION]: (state, { payload }) => {
    return state.set('selectedCard', new Cards(payload))
  },
  [SET_PULL_AMOUNT.ACTION]: (state, { payload }) => {
    return state.set('amount', payload)
  },
  [CLEAR_ACH_PULL_TRANSFER.ACTION]: () => {
    return INITIAL_STATE
  },
})
