import { decamelizeKeys } from 'humps'

import { get, patch, post } from './requests'

export const validateRoutingNumber = (key, payload) =>
  post(
    ['checking-account', 'validate-routing'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getBookmarkedAccounts = (key, payload) =>
  get(['checking-account', 'bookmarks', 'ach'], {
    key,
    transformPayload: true,
    params: decamelizeKeys(payload),
  })

export const transferAchToMyself = (key, payload) =>
  post(
    ['checking-account', 'transactions', 'ach', 'push'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const bookmarkAccount = (key, payload) =>
  patch(
    ['checking-account', 'bookmarks', 'ach', 'set'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const removeBookmarkedAccount = (key, payload) =>
  post(
    ['checking-account', 'bookmarks', 'ach', 'remove'],
    {
      key,
      transformPayload: true,
    },
    payload
  )
