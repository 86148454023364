/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Page, Document, pdfjs } from 'react-pdf'
import { useWindowSize } from '@reach/window-size'
import ArrowSVG from '_assets/svgs/arrow-down-icon.svg?react'
import DownloadSVG from '_assets/svgs/download-icon.svg?react'

import { onMouseDown } from '_utils/helpers'
import { LOAN_DOCUMENTS_ID } from '_utils/constants'

import styles from './styles.module.css'

import 'react-pdf/dist/cjs/Page/AnnotationLayer.css'
import 'react-pdf/dist/cjs/Page/TextLayer.css'

const PADDING_LEFT = 'padding-left'
const MAX_WIDTH = 'max-width'
const SCROLLBAR_SIZE = 2 * 15

const GET_ATTRIBUTE = attribute =>
  Number(
    window
      ?.getComputedStyle(document.getElementById(LOAN_DOCUMENTS_ID), null)
      ?.getPropertyValue(attribute)
      ?.split('px')[0]
  )

// react-pdf only works with this
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const DocumentPDF = ({ className, label, link, htmlDocument, externalLink }) => {
  const [PDFPages, setPDFPages] = useState(null)
  const { width } = useWindowSize()
  const [showPDF, setShowPDF] = useState(false)
  const [padding, setPadding] = useState(156)
  const [maxWidth, setMaxWidth] = useState(1440)

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setPDFPages(numPages)
  }, [])

  const onArrowClick = useCallback(() => {
    setShowPDF(prevState => !prevState)
  }, [])

  useEffect(() => {
    setPadding(GET_ATTRIBUTE(PADDING_LEFT))
    setMaxWidth(GET_ATTRIBUTE(MAX_WIDTH))
  }, [width])

  const pdfWidth = useMemo(
    () => Math.min(width, maxWidth) - 2 * padding - SCROLLBAR_SIZE,
    [width, maxWidth, padding]
  )

  return (
    <div className={classnames(className, styles.container)}>
      <div className={styles.card}>
        <p className={styles.label}>{label}</p>
        <div className={styles.right}>
          {link && (
            <a
              aria-label={`Download ${label} pdf document`}
              href={link}
              className={styles.download}
              onMouseDown={onMouseDown}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadSVG className={styles.icon} />
            </a>
          )}
          <button
            aria-label={`Show or hide ${label} pdf document`}
            type="button"
            onMouseDown={onMouseDown}
            onClick={onArrowClick}
          >
            <ArrowSVG
              className={classnames(styles.icon, styles.arrow, { [styles.open]: showPDF })}
            />
          </button>
        </div>
      </div>
      {showPDF && (
        <div className={styles['pdf-container']}>
          <div className={styles.page}>
            {link && (
              <Document height={400} file={link} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(Array(PDFPages), (el, index) => (
                  <Page
                    width={pdfWidth}
                    key={`page_${index + 1}`}
                    pagekey={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            )}
            {htmlDocument}
            {externalLink && <iframe title={label} src={externalLink} className={styles.iframe} />}
          </div>
        </div>
      )}
    </div>
  )
}

DocumentPDF.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  className: PropTypes.string,
  htmlDocument: PropTypes.node,
  externalLink: PropTypes.string,
}

DocumentPDF.defaultProps = {
  link: '',
  className: '',
  htmlDocument: '',
  externalLink: '',
}

export default DocumentPDF
