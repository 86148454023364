import { fromJS } from 'immutable'

import { NLS } from '_models'
import { createReducer } from '_utils/redux'

import { GET_NLS_UPDATE_STATUS } from './actions'

const INITIAL_STATE = new NLS()

export default createReducer(INITIAL_STATE, {
  [GET_NLS_UPDATE_STATUS.FULFILLED]: (state, { payload }) => {
    if (payload) {
      return state.merge(fromJS(payload))
    }
    return state
  },
})
