/* eslint-disable react/button-has-type */
import React, { forwardRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'
import PulseLoader from 'react-spinners/PulseLoader'

import { onMouseDown } from '_utils/helpers'

import styles from './styles.module.css'

const FILLED_THEMES = {
  DARK_ORANGE: 'dark-orange',
  DARK_GREEN: 'dark-green',
  LIGHT_BLUE: 'light-blue',
  LIGHT_ORANGE: 'light-orange',
  LIGHT_GREEN: 'light-green',
  RED: 'red',
  NO_BACKGROUND: 'no-background',
  NO_BACKGROUND_BLUE: 'no-background-blue',
  NO_BACKGROUND_GREEN: 'no-background-green',
  DARK_GRAY: 'dark-gray',
  LIGHT_GRAY: 'light-gray',
  GOLDEN: 'golden',
}

const OUTLINED_THEMES = {
  DARK_GREEN_OUTLINED: 'dark-green-outlined',
  LIGHT_BLUE_OUTLINED: 'light-blue-outlined',
  LIGHT_GREEN_OUTLINED: 'light-green-outlined',
  DARK_GRAY_OUTLINED: 'dark-gray-outlined',
  LIGHT_GRAY_OUTLINED: 'light-gray-outlined',
}

export const BUTTON_THEMES = {
  ...FILLED_THEMES,
  ...OUTLINED_THEMES,
}

const LOADER_PROPS = {
  size: 8,
  color: 'white',
}

const DISABLED_COLOR = 'var(--gray-88)'

const OUTLINE_THEMES_COLORS = {
  [BUTTON_THEMES.LIGHT_BLUE_OUTLINED]: 'var(--light-blue)',
  [BUTTON_THEMES.DARK_GREEN_OUTLINED]: 'var(--dark-green)',
  [BUTTON_THEMES.LIGHT_GREEN_OUTLINED]: 'var(--light-green)',
}

const Button = forwardRef(
  (
    { children, type, className, theme, to, loading, disabled, href, external, ...otherProps },
    ref
  ) => {
    const loaderProps = useMemo(() => {
      if (Object.values(OUTLINED_THEMES).find(outlinedTheme => outlinedTheme === theme)) {
        return {
          ...LOADER_PROPS,
          color: disabled ? DISABLED_COLOR : OUTLINE_THEMES_COLORS[theme],
        }
      }
      return LOADER_PROPS
    }, [disabled, theme])

    const onLinkClick = useCallback(
      event => {
        if (disabled) event.preventDefault()
      },
      [disabled]
    )
    if (href) {
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          href={href}
          className={classnames(className, styles[theme], styles.button, {
            [styles.disabled]: disabled,
          })}
          target={external ? '_blank' : undefined}
          rel={external ? 'noopener noreferrer' : undefined}
          onClick={onLinkClick}
          {...otherProps}
        >
          {!disabled && <div className={styles.curtain} />}
          <span className={styles.text}>{children}</span>
        </a>
      )
    }
    if (to) {
      return (
        <Link
          to={to}
          className={classnames(className, styles[theme], styles.button, {
            [styles.disabled]: disabled,
          })}
          onClick={onLinkClick}
          {...otherProps}
        >
          {!disabled && <div className={styles.curtain} />}
          <span className={styles.text}>{children}</span>
        </Link>
      )
    }
    return (
      <button
        className={classnames(className, styles[theme], styles.button, {
          [styles.disabled]: disabled,
        })}
        type={type}
        ref={ref}
        onMouseDown={onMouseDown}
        disabled={disabled}
        {...otherProps}
      >
        {!disabled && <div className={styles.curtain} />}
        {loading && (
          <div className={styles['loader-container']}>
            <PulseLoader loading={loading} {...loaderProps} />
          </div>
        )}
        <span className={classnames(styles.text, { [styles.hide]: loading })}>{children}</span>
      </button>
    )
  }
)
Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
  to: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  external: PropTypes.bool,
}

Button.defaultProps = {
  type: 'button',
  className: '',
  theme: '',
  to: '',
  loading: false,
  disabled: false,
  href: '',
  external: false,
}

export default Button
