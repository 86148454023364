import { Map } from 'immutable'

import { createReducer } from '_utils/redux'

import { ASK_QUESTION, CLEAR_CHAT, GET_ANSWER, GET_QUESTIONS } from './actions'

const INITIAL_STATE = Map({
  questions: [],
  chat: [
    {
      id: null,
      botProfile: 'bot',
      message: `Hello! I'm OneBlinc Assistant.\n\nYou can send me your questions, and I'll do my best to answer. I'm still experimental, so please excuse me for any mistakes.\n\nWhile you type, I'll show you questions I can already answer, click it and hit send. But don't worry, I'll email you the response if you ask me something I still need to learn.\n\nAlso, after every answer, please rate it before asking a new question. Oh! And click the send button so I can answer.`,
      answeredQuestion: '',
      isDefaultAnswer: true,
    },
  ],
})

export default createReducer(INITIAL_STATE, {
  [GET_QUESTIONS.FULFILLED]: (state, { payload }) => {
    return state.set('questions', payload.questions)
  },
  [ASK_QUESTION]: (state, { payload }) => {
    return state.set('chat', [
      ...state.get('chat'),
      {
        id: null,
        botProfile: 'you',
        message: payload.message,
        answeredQuestion: '',
        isDefaultAnswer: true,
      },
    ])
  },
  [GET_ANSWER.FULFILLED]: (state, { payload }) => {
    return state.set('chat', [
      ...state.get('chat'),
      {
        id: payload.id,
        botProfile: payload.botProfile,
        message: payload.answer,
        answeredQuestion: payload.question,
        isDefaultAnswer: payload.isDefaultAnswer,
      },
    ])
  },
  [CLEAR_CHAT]: state => {
    return state.set('chat', INITIAL_STATE.get('chat'))
  },
})
