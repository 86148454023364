import { camelizeKeys } from 'humps'
import { schema } from 'normalizr'

import Model from './model'

class Transaction extends Model({
  id: undefined,
  type: undefined,
  title: undefined,
  description: undefined,
  date: undefined,
  currency: undefined,
  amount: undefined,
  status: undefined,
  dateToProcess: undefined,
}) {}

export default Transaction

const transactionNewSchema = new schema.Entity(
  'results',
  {},
  {
    processStrategy: entity => {
      const camelizedEntity = camelizeKeys(entity)
      return new Transaction({
        ...camelizedEntity,
        status:
          camelizedEntity.dateToProcess.split('T')[0] !== camelizedEntity.date.split('T')[0] &&
          camelizedEntity.status === 'CREATED'
            ? 'SCHEDULED'
            : entity.status,
      })
    },
    idAttribute: entity => `${entity.date}-${entity.id}`,
  }
)

export const transactionSchema = [transactionNewSchema]
