import { post, get } from './requests'

export const getNLSUpdateStatus = key =>
  get(['nls-update-status'], {
    key,
    transformPayload: true,
  })

export const updateNLSStatus = key =>
  post(['nls-update-status'], {
    key,
    transformPayload: true,
  })
