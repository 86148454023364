import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { disableScroll, enableScroll } from '_utils/helpers'

import styles from './styles.module.css'

const Overlay = ({ className, children, onClick, nonClickable }) => {
  const handleOverlayClick = useCallback(
    event => {
      if (event.currentTarget !== event.target) {
        return
      }

      if (onClick && !nonClickable) {
        onClick()
      }
    },
    [nonClickable, onClick]
  )

  const kandleKeyPress = useCallback(
    event => {
      // if user has pressed esc key
      if (event.keyCode === 27) {
        onClick()
      }
    },
    [onClick]
  )

  useEffect(() => {
    disableScroll()

    return () => {
      enableScroll()
    }
  }, [])

  return (
    <div
      className={classnames(styles.overlay, className, {
        [styles.clickable]: !!onClick && !nonClickable,
      })}
      onClick={handleOverlayClick}
      onKeyDown={kandleKeyPress}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  )
}

Overlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  nonClickable: PropTypes.bool,
}

Overlay.defaultProps = {
  className: '',
  onClick: null,
  nonClickable: false,
}

export default Overlay
