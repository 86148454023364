import { defineAction } from '_utils/redux'
import * as checkingAccountService from '_services/checking-account'

export const VALIDATE_PIN = defineAction('VALIDATE_PIN')
export const RECOVER_PIN = defineAction('RECOVER_PIN')
export const CLEAR_PIN_VALIDATION = defineAction('CLEAR_PIN_VALIDATION')
export const CLEAR_PIN_RECOVER = defineAction('CLEAR_PIN_RECOVER')

export const validatePin = payload => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_PIN.ACTION,
    payload: checkingAccountService.authPin(getState().authentication.get('accessToken'), payload),
  })

export const recoverPin = payload => (dispatch, getState) =>
  dispatch({
    type: RECOVER_PIN.ACTION,
    payload: checkingAccountService.viewPin(getState().authentication.get('accessToken'), payload),
  })

export const clearPinRecover = () => dispatch =>
  dispatch({
    type: CLEAR_PIN_VALIDATION,
  })

export const clearPinValidation = () => dispatch =>
  dispatch({
    type: CLEAR_PIN_VALIDATION,
  })
