import { Map } from 'immutable'
import { camelize, camelizeKeys } from 'humps'

import { createReducer } from '_utils/redux'

import { GET_APP_SETTINGS, GET_PUBLIC_APP_SETTINGS } from './actions'

const INITIAL_STATE = Map({
  isAchPushEnabled: false,
  isAchPullEnabled: false,
  isCashbackEligibilityCardEnabled: false,
  isCashbackCardEnabled: false,
  isSalaryAdvanceEnabledForOneblincAccount: false,
  cashbackMinRedeemAmount: undefined,
  isMailCheckEnabled: false,
  activeEventCard: false,
  isRenegotiationActive: false,
  salaryAdvanceAmountOneClub: 0,
  oneClubCashbackAmount: null,
  isBlinkyBotEnabled: false,
  isCreditScoreActive: false,
  paymentBrokerProviders: null,
  // Public settings
  isNewSignupAvailable: false,
  protectionShieldResumeDate: null,
  isInsuranceCardEnabled: null,
  hasCashbackOnSalaryCardValidation: false,
  thirdPartyChatbot: null,
  availableDepositOptions: null,
})

export default createReducer(INITIAL_STATE, {
  [GET_APP_SETTINGS.FULFILLED]: (state, { payload }) => {
    const settings = {
      isAchPushEnabled: payload.general?.ACH_PUSH_ALLOWED || payload.user?.ACH_PUSH_ALLOWED,
      isAchPullEnabled:
        payload.general?.BANKING_CARD_PULL_ALLOWED || payload.user?.BANKING_CARD_PULL_ALLOWED,
      isCashbackEligibilityCardEnabled:
        payload.general?.CASHBACK_ELIGIBILITY_CARD_ENABLED ||
        payload.user?.CASHBACK_ELIGIBILITY_CARD_ENABLED,
      isCashbackCardEnabled:
        payload.general?.CASHBACK_CARD_ENABLED || payload.user?.CASHBACK_CARD_ENABLED,
      isSalaryAdvanceEnabledForOneblincAccount:
        payload.general?.SALARY_ADVANCE_ENABLED_FOR_ONEBLINC_ACCOUNT ||
        payload.user?.SALARY_ADVANCE_ENABLED_FOR_ONEBLINC_ACCOUNT,
      cashbackMinRedeemAmount:
        payload.general?.CASHBACK_MIN_REDEEM_AMOUNT || payload.user?.CASHBACK_MIN_REDEEM_AMOUNT,
      activeEventCard: payload.user?.EVENT_ENABLED && payload.general?.ACTIVE_EVENT,
      isMailCheckEnabled: payload.general?.MAIL_CHECK_ENABLED || payload.user?.MAIL_CHECK_ENABLED,
      renegDiscountDeadline: payload.general?.RENEG_DISCOUNT_DEADLINE,
      isUserBlocked: payload.user?.BLOCKED_USERS,
      isRenegotiationActive:
        payload.general?.IS_RENEGOTIATION_ACTIVE || payload.user?.IS_RENEGOTIATION_ACTIVE,
      isProtectShieldActive: payload.general?.PROTECTION_SHIELD || payload.user?.PROTECTION_SHIELD,
      salaryAdvanceAmountOneClub:
        payload.general?.SALARY_ADVANCE_AMOUNT_ONECLUB ||
        payload.user?.SALARY_ADVANCE_AMOUNT_ONECLUB,
      maintenanceAlert: payload.general?.MAINTENANCE_ALERT || payload.user?.MAINTENANCE_ALERT,
      isBlinkyBotEnabled:
        payload.general?.IS_BLINKY_BOT_ENABLED || payload.user?.IS_BLINKY_BOT_ENABLED,
      oneClubCashbackAmount:
        payload.general?.ONE_CLUB_BIRTHDAY_CASHBACK_AMOUNT ||
        payload.user?.ONE_CLUB_BIRTHDAY_CASHBACK_AMOUNT,
      isCreditScoreActive:
        payload.general?.KEEP_CREDIT_SCORE_ACTIVE || payload.user?.KEEP_CREDIT_SCORE_ACTIVE,
      protectionShieldResumeDate:
        payload.general?.PROTECTION_SHIELD_RESUME_DATE ||
        payload.user?.PROTECTION_SHIELD_RESUME_DATE,
      isInsuranceCardEnabled:
        payload.general?.IS_INSURANCE_CARD_ENABLED || payload.user?.IS_INSURANCE_CARD_ENABLED,

      hasCashbackOnSalaryCardValidation:
        payload.general?.HAS_CASHBACK_ON_SALARY_CARD_VALIDATION ||
        payload.user?.HAS_CASHBACK_ON_SALARY_CARD_VALIDATION,
      availableDepositOptions:
        payload.general?.AVAILABLE_DEPOSIT_OPTIONS || payload.user?.AVAILABLE_DEPOSIT_OPTIONS,
      paymentBrokerProviders:
        payload.general?.PAYMENT_BROKER_PROVIDERS || payload.user?.PAYMENT_BROKER_PROVIDERS,
    }
    return state.merge(settings)
  },
  [GET_PUBLIC_APP_SETTINGS.FULFILLED]: (state, { payload }) => {
    const transformedPayload = Object.keys(payload).reduce((acc, key) => {
      const camelCaseKey = camelize(key.toLowerCase())
      acc[camelCaseKey] = payload[key]
      return acc
    }, {})

    const immutableTransformedPayload = Map(transformedPayload)

    return state.merge(immutableTransformedPayload)
  },
})
