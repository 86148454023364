import { defineAction } from '_utils/redux'
import * as referralService from '_services/referral'

export const EMAIL_INVITE = defineAction('EMAIL_INVITE')
export const SAVE_REFERRAL_CODE = 'SAVE_REFERRAL_CODE'
export const MY_REFERRALS = defineAction('MY_REFERRALS')

export const emailInvite = payload => (dispatch, getState) =>
  dispatch({
    type: EMAIL_INVITE.ACTION,
    payload: referralService.emailInvite(getState().authentication.get('accessToken'), payload),
  })

export const saveReferralCode = payload => ({
  type: SAVE_REFERRAL_CODE,
  payload,
})

export const myReferrals = (dispatch, getState) =>
  dispatch({
    type: MY_REFERRALS.ACTION,
    payload: referralService.myReferrals(getState().authentication.get('accessToken')),
  })
