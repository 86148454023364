import Model from './model'

class OnePayment extends Model({
  achType: undefined,
  date: undefined,
  billingPeriod: undefined,
  billingExpireDate: undefined,
  abaNumber: undefined,
  acctNumber: undefined,
  amount: undefined,
  paymentMethod: undefined,
  acctName: undefined,
  acctType: undefined,
}) {}

export default OnePayment
