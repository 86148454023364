/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import OneBlincSVG from '_assets/svgs/one-blinc-logo-white.svg?react'

import { isGetUserLoadingSelector } from '_modules/user/selectors'
import { isGetLoansLoadingSelector } from '_modules/loan/selectors'

import styles from './styles.module.css'

function ProgressBarLoader({ onFinishLoading }) {
  const isUserLoading = useSelector(isGetUserLoadingSelector)
  const isLoansLoading = useSelector(isGetLoansLoadingSelector)
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    const loadableArray = [
      isUserLoading === undefined ? false : !isUserLoading,
      isLoansLoading === undefined ? false : !isLoansLoading,
    ]
    const toLoad = loadableArray.length
    const loaded = loadableArray.filter(value => value === true && value).length

    setPercentage((loaded / toLoad) * 100)
  }, [isLoansLoading, isUserLoading])

  useEffect(() => {
    if (percentage === 100) {
      setTimeout(() => {
        onFinishLoading()
      }, 200)
    }
  }, [onFinishLoading, percentage])

  return (
    <div className={styles.container}>
      <OneBlincSVG className={styles.logo} alt="logo" />
      <div className={styles['progress-bar-container']}>
        <div className={styles['progress-bar']} style={{ width: `${percentage}%` }} />
      </div>
      <h1 className={styles.subtitle}>We will finish loading your data in a blinc!</h1>
    </div>
  )
}

ProgressBarLoader.propTypes = {
  onFinishLoading: PropTypes.func,
}

ProgressBarLoader.defaultProps = {
  onFinishLoading: () => {},
}

export default ProgressBarLoader
