import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

export const useGetCron = (action, interval, isCronActive = true, payload = {}) => {
  const dispatch = useDispatch()

  const dispatchAction = useCallback(() => {
    dispatch(action(payload))
  }, [action, dispatch, payload])

  useEffect(() => {
    const timer = setInterval(dispatchAction, interval)

    if (!isCronActive) {
      clearInterval(timer)
    }

    return () => {
      clearInterval(timer)
    }
  }, [action, dispatch, dispatchAction, interval, isCronActive, payload])
}
