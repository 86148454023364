import { decamelizeKeys } from 'humps'

import { get, patch, post } from './requests'

export const interchangePush = (key, payload) =>
  post(
    ['checking-account', 'transactions', 'interchange', 'push'],
    { key, transformPayload: true },
    payload
  )

export const interchangePull = (key, payload) =>
  post(
    ['checking-account', 'transactions', 'interchange', 'pull'],
    { key, transformPayload: true },
    payload
  )

export const bookmarkCard = (key, payload) =>
  patch(
    ['checking-account', 'bookmarks', 'interchange', 'set'],
    { key, transformPayload: true },
    payload
  )

export const removeBookmarkedCard = (key, payload) =>
  post(
    ['checking-account', 'bookmarks', 'interchange', 'remove'],
    { key, transformPayload: true },
    payload
  )

export const getBookmarkedCards = (key, params) =>
  get(['checking-account', 'bookmarks', 'interchange'], {
    key,
    transformPayload: true,
    params: decamelizeKeys(params),
  })
