import { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { useSelector } from 'react-redux'

import { OriginalPathContext } from '_config/app-context'
import { authenticationRefreshTokenSelector } from '_modules/authentication/selectors'
import { ROOT_PROJECT_URL, ENVIRONMENT } from '_config/environment'

export const PUBLIC_ROUTES = [
  'sign-up',
  'terms-and-conditions',
  'payroll-terms-and-conditions',
  'terms-of-use',
  'privacy-policy',
  'terms',
]

export const BLOCKED_ROUTES = ['new-application', 'reapply', 'refinance']

if (ENVIRONMENT === 'development') {
  PUBLIC_ROUTES.push('login')
}

const ProtectedRoutes = ({ children }) => {
  const { setOriginalPathState } = useContext(OriginalPathContext)
  const { pathname } = useLocation()
  const isProtected = !PUBLIC_ROUTES.some(url => pathname.split('/').includes(url), false)
  const isBlocked = BLOCKED_ROUTES.some(url => pathname.split('/').includes(url), false)
  const isLogged = useSelector(authenticationRefreshTokenSelector)
  const [shouldRedirect, setShouldRedirect] = useState(false)
  useEffect(() => {
    if ((isProtected && !isLogged) || isBlocked) {
      setShouldRedirect(true)
      setOriginalPathState(pathname)
      return
    }
    setShouldRedirect(false)
  }, [isBlocked, isLogged, isProtected, pathname, setOriginalPathState])

  useEffect(() => {
    if (shouldRedirect) {
      if (ENVIRONMENT === 'development') {
        window.location.href = '/login'
        return
      }
      const redirectUrl =
        pathname && pathname !== '/'
          ? `${ROOT_PROJECT_URL}/login?redirect=${pathname}`
          : `${ROOT_PROJECT_URL}/login`
      window.location.href = redirectUrl
    }
  }, [shouldRedirect, pathname])

  return children
}

ProtectedRoutes.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtectedRoutes
