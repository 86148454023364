import { get, post } from './requests'

export const getCashbackStores = () => get(['cashback', 'offers'], { transformPayload: true })

export const getCashbackUser = payload =>
  post(['cashback', 'cashback-user'], { transformPayload: true }, payload)

export const getCashbackUserByLogin = key =>
  post(['cashback', 'cashback-user'], { key, transformPayload: true })

export const enterWaitlist = payload =>
  post(['cashback', 'wait-list'], { transformPayload: true }, payload)

export const enterWaitlistByLogin = key =>
  post(['cashback', 'wait-list'], { key, transformPayload: true })

export const redeemCashback = payload =>
  post(['cashback', 'redeem'], { transformPayload: true }, payload)

export const redeemCashbackByLogin = (key, payload) =>
  post(['cashback', 'redeem'], { key, transformPayload: true }, payload)

export const getCardFormUrlByLead = payload =>
  post(['card-lead-form'], { transformPayload: true }, payload)

export const getUserCardsByLead = payload =>
  post(['cards-lead'], { transformPayload: true }, payload)

export const autoCreateCashback = (key, payload) =>
  post(['cashback', 'create-cashback'], { key, transformPayload: true }, payload)
