import { Map } from 'immutable'

import Model from './model'

class Autopay extends Model({
  paymentOptions: Map({
    ach: undefined,
    repay: undefined,
    callForAch: undefined,
  }),
  paymentInfo: Map({
    loanId: undefined,
    payoff: undefined,
    oneTimeDate: undefined,
    oneTimeAmount: undefined,
    recurringDate: undefined,
    recurringAmount: undefined,
    hasActiveAutopay: undefined,
    recurrency: undefined,
    expirationDate: undefined,
  }),
  paymentProviderInfo: Map({
    ach: Map({
      bankAccount: Map({
        id: undefined,
        name: undefined,
        account: undefined,
        routing: undefined,
        subtype: undefined,
        type: undefined,
      }),
      processing: undefined,
    }),
    repay: undefined,
  }),
  currentAutopay: undefined,
}) {}

export default Autopay
