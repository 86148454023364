import { Map } from 'immutable'

import { createReducer } from '_utils/redux'
import { Version } from '_models/index'

import { GET_VERSION } from './actions'

const INITIAL_STATE = Map()

export default createReducer(INITIAL_STATE, {
  [GET_VERSION.FULFILLED]: (state, { payload }) => {
    return state.merge(new Version(payload))
  },
})
