import Model from './model'

class Referral extends Model({
  referralCode: undefined,
  approvedLoans: undefined,
  receivedApplications: undefined,
  referralLink: undefined,
  totalAwards: undefined,
  referrals: undefined,
}) {}

export default Referral
