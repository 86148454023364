import Model from './model'

// Structure
// microdeposit: {
//   id: undefined,
//   user: undefined,
//   card: undefined,
//   expectedPlaidBankAccount: undefined,
//   salaryAccountPlaidBankAccount: undefined,
//   status: undefined,
//   amount: undefined,
//   sentAt: undefined,
//   validatedAt: undefined,
//   collectedAt: undefined,
//   failureReason: undefined,
// },
// microdepositsExpirationTimeInHours: undefined
// microdepositsFlowAvailable: undefined
// salaryAccountStatus: {
//   hasSalaryOnLastDays: undefined,
//   hasProcessedTransactions: undefined,
//   salaryAccountDetails: {
//     transactionId: undefined,
//     name: undefined,
//     type: undefined,
//     subtype: undefined,
//     mask: undefined,
//   },
// },

class Microdeposits extends Model({
  microdeposit: undefined,
  salaryAccountStatus: undefined,
  microdepositsExpirationTimeInHours: undefined,
  microdepositsFlowAvailable: undefined,
  microdepositCashbackAmount: undefined,
}) {}

export default Microdeposits
