import { Map } from 'immutable'

import { createReducer } from '_utils/redux'

import { VALIDATED_PIN, UNLOCK_CARD } from './actions'

const INITIAL_STATE = Map({
  validatedPin: false,
  unlockedCard: false,
})

export default createReducer(INITIAL_STATE, {
  [VALIDATED_PIN]: (state, { payload }) => {
    return state.set('validatedPin', payload)
  },
  [UNLOCK_CARD.FULFILLED]: state => {
    return state.set('unlockedCard', true)
  },
})
