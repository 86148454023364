import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import PropTypes from 'prop-types'

import AnimatedOpacity from '_styles/animations/AnimatedOpacity'

import styles from './styles.module.css'

export const THEME = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  VERY_SMALL: 'very_small',
}

const LOADER_PROPS = {
  [THEME.LARGE]: {
    size: 80,
    color: '#00b2ac',
  },
  [THEME.MEDIUM]: {
    size: 50,
    color: '#00b2ac',
  },
  [THEME.SMALL]: {
    size: 20,
    color: '#00b2ac',
  },
  [THEME.VERY_SMALL]: {
    size: 15,
    color: '#00b2ac',
  },
}

const PageLoader = ({ theme }) => (
  <AnimatedOpacity>
    <div className={styles.container}>
      <MoonLoader loading {...LOADER_PROPS[theme]} />
    </div>
  </AnimatedOpacity>
)

PageLoader.propTypes = {
  theme: PropTypes.string,
}

PageLoader.defaultProps = {
  theme: THEME.LARGE,
}

export default PageLoader
