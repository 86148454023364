import * as openPayrollService from '_services/open-payroll'
import { defineAction } from '_utils/redux'

export const SAVE_ARGYLE_TOKEN = defineAction('SAVE_ARGYLE_TOKEN')
export const UPDATE_ARGYLE_TOKEN = defineAction('UPDATE_ARGYLE_TOKEN')
export const SAVE_ARGYLE_EVENTS = defineAction('SAVE_ARGYLE_EVENTS')
export const CREATE_PARTNER_ACCOUNT = defineAction('CREATE_PARTNER_ACCOUNT')
export const CHECK_ARGYLE_DATA = defineAction('CHECK_ARGYLE_DATA')
export const CHECK_ARGYLE_CONNECTION = defineAction('CHECK_ARGYLE_CONNECTION')
export const GET_ARGYLE_FLOW_ID = defineAction('GET_ARGYLE_FLOW_ID')

export const saveArgyleUser = payload => (dispatch, getState) =>
  dispatch({
    type: SAVE_ARGYLE_TOKEN,
    payload: openPayrollService.saveArgyleUser(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const updateArgyleToken = () => (dispatch, getState) =>
  dispatch({
    type: UPDATE_ARGYLE_TOKEN,
    payload: openPayrollService.updateArgyleToken(getState().authentication.get('accessToken')),
  })

export const saveArgyleEvents = payload => (dispatch, getState) => {
  dispatch({
    type: SAVE_ARGYLE_EVENTS,
    payload: openPayrollService.saveArgyleEvents(
      getState().authentication.get('accessToken'),
      payload
    ),
  })
}

export const createPartnerAccount = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: CREATE_PARTNER_ACCOUNT,
    payload: openPayrollService.createPartnerAccount(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const checkArgyleData = () => (dispatch, getState) =>
  dispatch({
    type: CHECK_ARGYLE_DATA,
    payload: openPayrollService.checkArgyleData(getState().authentication.get('accessToken')),
  })

export const checkArgyleConnection = () => (dispatch, getState) =>
  dispatch({
    type: CHECK_ARGYLE_CONNECTION,
    payload: openPayrollService.checkArgyleConnection(getState().authentication.get('accessToken')),
  })

export const getArgyleFlowId = flow => (dispatch, getState) =>
  dispatch({
    type: GET_ARGYLE_FLOW_ID,
    payload: openPayrollService.getArgyleFlowId(getState().authentication.get('accessToken'), flow),
  })
