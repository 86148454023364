import CheckStateEligible from '_models/check-state-eligible'
import { createReducer } from '_utils/redux'

import { GET_CHECK_STATE_ELIGIBLE } from './actions'

const INITIAL_STATE = new CheckStateEligible()

export default createReducer(INITIAL_STATE, {
  [GET_CHECK_STATE_ELIGIBLE.FULFILLED]: (state, { payload }) => {
    return state.set('checkStateEligibleData', payload)
  },
})
