import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getMicrodepositsStatusSelector,
  shouldValidateMicrodepositsStatusSelector,
} from '_modules/microdeposits/selectors'
import { useGetCron } from '_hooks/use-get-cron'
import { checkMicrodeposit } from '_modules/microdeposits/actions'

// Constants
const REFRESH_INTERVAL = 30000

const SalaryAccountCardValidation = ({ children }) => {
  const microdeposit = useSelector(getMicrodepositsStatusSelector)
  const dispatch = useDispatch()
  useGetCron(
    checkMicrodeposit,
    REFRESH_INTERVAL,
    !!microdeposit && microdeposit.get('status') === 'PROCESSING'
  )

  useEffect(() => {
    dispatch(checkMicrodeposit())
  }, [dispatch])

  return children
}

export default SalaryAccountCardValidation
