import * as onePaymentService from '_services/one-payment'
import { defineAction } from '_utils/redux'
import * as achService from '_services/ach'

export const SET_PAYMENT_METHOD = defineAction('SET_PAYMENT_METHOD')
export const GET_PAYMENT_METHOD = defineAction('GET_PAYMENT_METHOD')
export const SET_ACH_TYPE = defineAction('SET_ACH_TYPE')
export const GET_ACH_TYPE = defineAction('GET_ACH_TYPE')
export const SET_ONE_PAYMENT_AMOUNT = defineAction('SET_ONE_PAYMENT_AMOUNT')
export const SET_ONE_PAYMENT_BANKING = defineAction('SET_ONE_PAYMENT_BANKING')
export const GET_ACH_DATA = defineAction('GET_ACH_DATA')
export const SET_ONE_PAYMENT_ALL_DATE = defineAction('SET_ONE_PAYMENT_ALL_DATE')
export const SET_ONE_PAYMENT_DATA = defineAction('SET_ONE_PAYMENT_DATA')
export const CLEAR_ONE_PAYMENT_DATA = defineAction('CLEAR_ONE_PAYMENT_DATA')
export const VALIDATE_ONE_PAYMENT_ROUTING_NUMBER = defineAction(
  'VALIDATE_ONE_PAYMENT_ROUTING_NUMBER'
)
export const DISCONNECT_AUTOPAY = defineAction('DISCONNECT_AUTOPAY')

export const setPaymentMethod = payload => (dispatch, getState) =>
  dispatch({
    type: SET_PAYMENT_METHOD.ACTION,
    payload,
  })

export const setAchType = payload => (dispatch, getState) =>
  dispatch({
    type: SET_ACH_TYPE.ACTION,
    payload,
  })

export const setOnePaymentAmount = payload => (dispatch, getState) =>
  dispatch({
    type: SET_ONE_PAYMENT_AMOUNT.ACTION,
    payload,
  })

export const setOnePaymentBanking = payload => (dispatch, getState) =>
  dispatch({
    type: SET_ONE_PAYMENT_BANKING.ACTION,
    payload,
  })

export const setOnePaymentAllDate = payload => (dispatch, getState) =>
  dispatch({
    type: SET_ONE_PAYMENT_ALL_DATE.ACTION,
    payload,
  })

export const setOnePaymentData = (id, payload) => (dispatch, getState) =>
  dispatch({
    type: SET_ONE_PAYMENT_DATA.ACTION,
    payload: onePaymentService.sendOnePaymentData(
      getState().authentication.get('accessToken'),
      id,
      payload
    ),
  })

export const clearData = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_ONE_PAYMENT_DATA.ACTION,
  })

export const validateRoutingNumber = payload => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_ONE_PAYMENT_ROUTING_NUMBER.ACTION,
    payload: achService.validateRoutingNumber(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const disconnectAutoPay = id => (dispatch, getState) =>
  dispatch({
    type: DISCONNECT_AUTOPAY.ACTION,
    payload: onePaymentService.disconnectAutoPay(getState().authentication.get('accessToken'), id),
  })
