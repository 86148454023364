import ATM from '_models/atm'
import { createReducer } from '_utils/redux'

import { GET_ATM } from './actions'

const INITIAL_STATE = new ATM()

export default createReducer(INITIAL_STATE, {
  [GET_ATM.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },
})
