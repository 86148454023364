import { decamelizeKeys } from 'humps'

import { post, get, patch } from './requests'

export const getBookmarkedRecipients = (key, payload) =>
  get(['checking-account', 'bookmarks', 'check'], {
    key,
    transformPayload: true,
    params: decamelizeKeys(payload),
  })

export const bookmarkRecipient = (key, payload) =>
  patch(
    ['checking-account', 'bookmarks', 'check', 'set'],
    {
      key,
      transformPayload: true,
    },
    payload
  )
export const removeBookmarkedRecipient = (key, payload) =>
  post(
    ['checking-account', 'bookmarks', 'check', 'remove'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const mailCheck = (key, payload) =>
  post(
    ['checking-account', 'transactions', 'check', 'push'],
    {
      key,
      transformPayload: true,
    },
    payload
  )
