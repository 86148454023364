import { decamelizeKeys } from 'humps'

import { post, get, patch } from './requests'

export const uploadAccessToken = (key, payload) =>
  post(
    ['plaid-access', 'token-upload'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const callIdentity = (key, payload) =>
  post(
    ['plaid-products', 'call-identity'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const manualBankAccounts = (key, payload) =>
  post(
    ['bank-accounts'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getBankAccounts = ({ key, params }) =>
  get(['bank-accounts'], {
    key,
    params,
    transformPayload: true,
  })

export const getBankAccountsNeedingReconnection = ({ key, params }) =>
  get(['bank-accounts', 'check-accounts'], {
    key,
    params,
    transformPayload: true,
  })

export const validateToken = (key, payload) =>
  post(
    ['plaid-access', 'validate'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getLinkToken = (key, params) =>
  get(['plaid-access', 'link-token'], {
    key,
    params: decamelizeKeys(params),
    transformPayload: true,
  })

export const createLinkToken = (key, params) =>
  get(['plaid-access', 'create-link-token'], {
    key,
    params: decamelizeKeys(params),
    transformPayload: true,
  })

export const checkRequiredAccountInfo = (key, params) =>
  get(['plaid-products', 'check-required-account-info'], {
    key,
    params: decamelizeKeys(params),
    transformPayload: true,
  })

export const updateManualBankAccount = (key, id, payload) =>
  patch(
    ['bank-accounts', id],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const setFallbackAccount = (key, id, payload) =>
  post(
    ['loans', id, 'change-fallback-account'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const checkSalaryAccountStatus = key =>
  get(['check-salary-account'], {
    key,
    transformPayload: true,
  })
