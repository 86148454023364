import { createReducer } from '_utils/redux'
import { OnePayment } from '_models'

import {
  SET_PAYMENT_METHOD,
  SET_ACH_TYPE,
  SET_ONE_PAYMENT_AMOUNT,
  SET_ONE_PAYMENT_BANKING,
  SET_ONE_PAYMENT_ALL_DATE,
  CLEAR_ONE_PAYMENT_DATA,
  VALIDATE_ONE_PAYMENT_ROUTING_NUMBER,
} from './actions'

const INITIAL_STATE = new OnePayment()

export default createReducer(INITIAL_STATE, {
  [SET_PAYMENT_METHOD]: (state, { payload }) => {
    return state.merge(payload)
  },
  [SET_ACH_TYPE]: (state, { payload }) => {
    return state.merge(payload)
  },
  [SET_ONE_PAYMENT_AMOUNT]: (state, { payload }) => {
    return state.merge(payload)
  },
  [SET_ONE_PAYMENT_BANKING]: (state, { payload }) => {
    return state.merge(payload)
  },
  [SET_ONE_PAYMENT_ALL_DATE]: (state, { payload }) => {
    return state.merge(payload)
  },
  [CLEAR_ONE_PAYMENT_DATA]: () => {
    return INITIAL_STATE
  },
  [VALIDATE_ONE_PAYMENT_ROUTING_NUMBER.FULFILLED]: (state, { payload }) => {
    const { bankName } = payload

    return state.merge({ acctName: bankName })
  },
})
