import { schema } from 'normalizr'

import Model from './model'

class MailCheck extends Model({
  id: undefined,
  name: undefined,
  address: undefined,
  city: undefined,
  state: undefined,
  zipCode: undefined,
  country: 'US',
  amount: undefined,
}) {}

export default MailCheck

const mailCheckNewSchema = new schema.Entity(
  'results',
  {},
  {
    idAttribute: entity => `${entity.id}`,
    processStrategy: entity =>
      new MailCheck({
        id: entity.id,
        name: entity.payeeName,
        address: entity.payeeAddressStreet,
        city: entity.payeeAddressCity,
        state: entity.payeeAddressSubdivision,
        zipCode: entity.payeeAddressPostalCode,
        country: entity.payeeAddressCountryCode,
      }),
  }
)

export const mailCheckSchema = [mailCheckNewSchema]
