import { createReducer } from '_utils/redux'
import { Loan } from '_models'

import {
  GET_USER_PHONES,
  NEED_PHONE_VERIFICATION,
  REFINANCE_PREVIEW,
  START_PHONE_VERIFICATION,
  UPDATE_PHONE_NUMBER,
} from './actions'

const INITIAL_STATE = new Loan()

export default createReducer(INITIAL_STATE, {
  [REFINANCE_PREVIEW.FULFILLED]: (state, { payload }) => {
    return new Loan(payload)
  },
  [START_PHONE_VERIFICATION.FULFILLED]: (state, { payload }) => {
    return state.merge(payload)
  },
  [GET_USER_PHONES.FULFILLED]: (state, { payload }) => {
    return state.set('phones', payload)
  },
  [UPDATE_PHONE_NUMBER.FULFILLED]: (state, { payload }) => {
    return state.set('phones', payload)
  },
  [NEED_PHONE_VERIFICATION.FULFILLED]: (state, { payload }) => {
    return state.set('needPhoneVerification', payload.needVerification)
  },
})
