import { get, post } from './requests'

export const checkMicrodeposit = key =>
  get(['microdeposit', 'check'], {
    key,
    transformPayload: true,
  })

export const sendMicrodeposit = (key, payload) =>
  post(
    ['microdeposit', 'send'],
    {
      key,
      transformPayload: true,
    },
    payload
  )
