import { defineAction } from '_utils/redux'

export const TOGGLE_EVENTS_BANNER = defineAction('TOGGLE_EVENTS_BANNER')
export const STORE_ADS_INFO = defineAction('STORE_ADS_INFO')
export const POSSIBLE_LOAN_ID = defineAction('POSSIBLE_LOAN_ID')
export const SEEN_NEW_NAV_BAR_HIGHLIGHT = defineAction('SEEN_NEW_NAV_BAR_HIGHLIGHT')

export const toggleEventsBanner = payload => dispatch =>
  dispatch({
    type: TOGGLE_EVENTS_BANNER,
    payload,
  })

export const storeAdsInfo = payload => dispatch =>
  dispatch({
    type: STORE_ADS_INFO,
    payload,
  })

export const storePossibleLoanId = payload => dispatch =>
  dispatch({
    type: POSSIBLE_LOAN_ID,
    payload,
  })

export const setSeenNewNavBarHighlight = () => dispatch =>
  dispatch({
    type: SEEN_NEW_NAV_BAR_HIGHLIGHT,
  })
