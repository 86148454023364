import { defineAction } from '_utils/redux'
import * as appSettingsService from '_services/app-settings'

export const GET_APP_SETTINGS = defineAction('GET_APP_SETTINGS')
export const GET_PUBLIC_APP_SETTINGS = defineAction('GET_PUBLIC_APP_SETTINGS')

export const getAppSettings = payload => (dispatch, getState) =>
  dispatch({
    type: GET_APP_SETTINGS,
    payload: appSettingsService.getAppSettings(
      getState().authentication.get('accessToken'),
      payload
    ),
  })

export const getPublicAppSettings = settingsKey => dispatch =>
  dispatch({
    type: GET_PUBLIC_APP_SETTINGS.ACTION,
    payload: appSettingsService.getPublicSettings({ key: settingsKey }),
  })
